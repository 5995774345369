import React, {useState} from 'react'
import s from './Modals.module.sass'
import {appAPI} from '../../../API/API'
import InputMask from 'react-input-mask';

const EditVacancies = ({item, setState, close}) => {

    const [formData, setFormData] = useState({
        name: item.name,
        address: item.address,
        phone: item.phone,
        salaryFrom: item.salaryFrom,
        salaryTo: item.salaryTo,
    })

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if(formData.name !== item.name || formData.address !== item.address || formData.phone !== item.phone || formData.salaryFrom !== item.salaryFrom || formData.salaryTo !== item.salaryTo) {
            await appAPI.editVacancies({...formData, salaryFrom: parseInt(formData.salaryFrom), salaryTo: parseInt(formData.salaryTo)}, item.id)
                .then(() => {
                    setState(prev => prev.map(vacancie => vacancie.id === item.id ? {...vacancie, name: formData.name, phone: formData.phone, address: formData.address, salaryFrom: formData.salaryFrom, salaryTo: formData.salaryTo} : vacancie))
                    close();
                })
                .catch(err => console.log(err))
        } else alert('Все поля совпадают с предыдущим значением')
    }

    return(
        <div className={s.modalAdmin}>
            <form className={s.formAdmin} onSubmit={submitHandler}>
                <label className = {`${s.label}`}>
                    <p>Заголовок</p>
                    <input 
                        type="text"
                        name = 'name'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.name}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Адрес</p>
                    <input 
                        type="text"
                        name = 'address'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.address}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Телефон</p>
                    <InputMask
                        mask = '+7 999 999 99 99'
                        name = 'phone'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.phone}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>ЗП от</p>
                    <input 
                        type="number"
                        name = 'salaryFrom'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.salaryFrom}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>ЗП до</p>
                    <input 
                        type="number"
                        name = 'salaryTo'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.salaryTo}
                    />
                </label>
                <button type = 'submit' className={`button ${formData.name !== item.name || formData.address !== item.address || formData.phone !== item.phone || formData.salaryFrom !== item.salaryFrom || formData.salaryTo !== item.salaryTo ? s.btn_red : ''}`}>Сохранить</button>
            </form>
        </div>
    )
}

export default EditVacancies;
