import React, {useState, useEffect} from 'react'
import { apiURL } from '../../../API/API';

const OrderProduct = ({addPutProduct, product, changeOrder, addDelProduct, id, productFlag, amountOrdered}) => {

    const [numOrdered, setNumOrdered] = useState(product.amount)
    const [activeStatus, setActiveStatus] = useState('Недорасход')

    useEffect(() => {
        if(product.register) {
        if(amountOrdered < numOrdered )
            {
                setActiveStatus('Перерасход')
            } else if(amountOrdered === numOrdered) {
                    setActiveStatus('Ок')
                } else setActiveStatus('Недорасход')
        } 
    }
    , [amountOrdered, numOrdered, product.register])

    useEffect(() => {
        productFlag && setNumOrdered(product.amount)
    }, [product.amount, productFlag])
    
    return(
        <tr>
            <td>
                <img src={product.product.images && `${apiURL}${product.product.images[0].url}`} alt="img" className="product__img"/>
                <div className="product__desc">
                    <p className="desc_bold">{product.product.composition}</p>
                    <p className="desc_light">Артикул {product.product.identifier}</p>
                </div>
            </td>
            <td>
                <p>{amountOrdered}</p>
            </td>
            <td>
                <p>{product.register === true ? product.amount : '0'}</p>
                {/* {changeOrder
                    ? <input defaultValue={numOrdered} onChange={ (e) => {
                        setNumOrdered(e.currentTarget.value)
                        addPutProduct(id, e.currentTarget.value)
                    }}/>
                    : <p>{numOrdered}</p>
                } */}
            </td>
            <td>
                {activeStatus === 'Ок' && <button className = 'button btn_success'>Ок</button>}
                {activeStatus === 'Недорасход' && <button className = 'button btn_nedorashod'>Недорасход</button>}
                {activeStatus === 'Перерасход' && <button className = 'button btn_pererashod'>Перерасход</button>}
                {changeOrder && <img src = '/img/closeRed.svg' className = 'del-product' alt = 'img' onClick = { () => addDelProduct(id, product)}/>}
            </td>
        </tr>
    )
}

export default OrderProduct;