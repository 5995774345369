import React from 'react';
import Table from '../Users/Table/Table';
import {appAPI} from '../../../API/API'
import TableItemProduct from '../Users/Table/TableItems/TableItemProduct';

const Products = () => {

    const headers = ['', 'Заголовок', 'Категория', 'Подкатегория', 'Редактировать']
    const [products, setProducts] = React.useState([])
    const [forceUpdate, setForceUpdate] = React.useState(false)
    
    React.useEffect(() => {
        const fetchData = async () => {
            await appAPI.getProducts()
                .then(response => setProducts(response.data))
                .catch(error => console.error(error))
        }
        fetchData();
    }, [forceUpdate])
    
    return(
        <div>
            <Table
                title = 'Продукты'
                tableItems = {products}
                headers = {headers}
                Component = {TableItemProduct}
                showSearch = {true}
                setState = {setProducts}
                setForceUpdate = {setForceUpdate}
                // setState = {setProducts}
                // setForceUpdate = {setForceUpdate}
            />
        </div>
    )   
}

export default Products;