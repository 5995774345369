import React from 'react'
import s from '../CustomFormRowRecepie.module.sass'
import styleM from '../../../Modals/Modals.module.sass'

const RowIngredients = ({img, setState, id, row, showDefault, setNewState, setValidationFlag}) => {

    const changeHandler = (e) => {
        const {name, value} = e.target;
        setState(prev => prev.map(ingredient => ingredient.id === id ? {...ingredient, [name]: value } : ingredient))
        setValidationFlag(true)
    }

    const deleteItem = (id) => {
        setState(prev => prev.filter(item => item.id !== id))
        setValidationFlag(true)
    }

    return(
        <div className = {`${s.customRow} ${s.customRowIngredients}`}>
            <input 
                type = "text"
                name = 'name'
                placeholder = 'Название ингридиента'
                className = {`${styleM.input} ${s.input}`}
                onChange = {changeHandler}
                defaultValue = {showDefault && row.name}
            />
            <input 
                type = "number"
                name = 'amount'
                placeholder = 'Количество'
                className = {`${styleM.input} ${s.input}`}
                onChange = {changeHandler}
                defaultValue = {showDefault && row.amount}
            />
            <input 
                type = "text"
                name = 'unit'
                placeholder = 'Юнит'
                className = {`${styleM.input} ${s.input}`}
                onChange = {changeHandler}
                defaultValue = {showDefault && row.unit}
            />
            {img === 'delete' 
                ? <img className = {s.img} src="/img/adminDel.svg" alt="img" onClick = { () => deleteItem(id)}/>
                : <img className = {s.img} src="/img/adminPlus.svg" alt="img" onClick = {() => setState(prev => [...prev, {amount: null, name: '', unit: '', id: Date.now()}])}/>
            }
        </div>
    )
};

export default RowIngredients;