import React, {useState} from 'react';
import {useDispatch} from 'react-redux'
import {loginUser} from '../../Actions/UserActions'
import './Form.sass'

const AuthForm = () => {

    const [userLogin, setUserLogin] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [showCustomSelest, setShowCustomSelest] = useState(false)
    const [userRole, setUserRole] = useState('Выберите роль')
    const dispatch = useDispatch();

    const submitHandler = async (e) => {
        e.preventDefault()
        if(userLogin && userPassword && userRole) {
            let userRoleForRoute = '';
            switch (userRole) {
                case 'Администратор':
                    userRoleForRoute = 'admin';
                break;

                case 'Администратор магазина':
                    userRoleForRoute = 'operator';
                break;

                case 'Продавец':
                    userRoleForRoute = 'seller';
                break;

                case 'Курьер':
                    userRoleForRoute = 'courier';
                    break;
                    
                default:
                    break;
            }
            dispatch(loginUser(userLogin, userPassword, userRoleForRoute));
        } else {
            alert('Данные введены некорректно')
        }
    }

    return(
        <div className = 'form-wrapper'>
            <h2 className='form__title'>Войти в личный кабинет</h2>
            <form className='form' onSubmit={submitHandler}>
                <div className='form-fields'>
                    <input
                            type='text'
                            name='login'
                            placeholder='Введите логин'
                            className='form__field form__field_input'
                            onChange = {(e) => setUserLogin(e.target.value)}
                        />
                    <input
                            type='password'
                            name='password'
                            placeholder='Введите пароль'
                            className='form__field form__field_input'
                            onChange = {(e) => setUserPassword(e.target.value)}
                        />
                    <div className = 'custom_select custom_select_auth'>
                        <p className={`form__field form__field_input custom_select__active_auth ${showCustomSelest ? 'active' : ''}`} onClick = {() => setShowCustomSelest(!showCustomSelest)}>
                            <span>{userRole}</span>
                            <img src="/img/arrowSelect.svg" alt="arrow"/>
                        </p>
                        {showCustomSelest &&
                            <ul>
                                <li className = 'custom-select__item' onClick = {() => {setUserRole('Администратор'); setShowCustomSelest(false)}}>Администратор</li>
                                <li className = 'custom-select__item' onClick = {() => {setUserRole('Администратор магазина'); setShowCustomSelest(false)}}>Администратор магазина</li>
                                <li className = 'custom-select__item' onClick = {() => {setUserRole('Продавец'); setShowCustomSelest(false)}}>Продавец</li>
                                <li className = 'custom-select__item' onClick = {() => {setUserRole('Курьер'); setShowCustomSelest(false)}}>Курьер</li>
                            </ul>
                        }
                    </div>
                </div>
                <button type='submit' className='form__field form__field_btn'>Вход</button>
            </form>
        </div>
    )
}

export default AuthForm;