import React, {useEffect, useState} from 'react'
import s from './Modals.module.sass'
import InputMask from 'react-input-mask';
import {appAPI} from '../../../API/API'
import CustomSelectCourier from '../Common/CustomSelectCourier/CustomSelectCourier';
import { useSelector } from 'react-redux';

const EditCourier = ({name, phone, login, password, address, email, id, setState, close, telegramName, setForceUpdate}) => {

    const [formData, setFormData] = useState({
        name: name,
        phone: phone,
        login: login,
        telegramName: telegramName,
        password: '',
        // address: address, 
    })

    const [formAddress, setFormAddress] = useState(address)
    const [fetchData, setFetchData] = useState({
        sellers: [],
        isFetching: true
    })
    const {token} = useSelector(state => state.User)

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    useEffect(() => {
        const fetchData = () => {
            appAPI.getSellers(token)
                .then(response => setFetchData({isFetching: false, sellers: response.data.filter(seller => seller.shop).map(seller => ({id: seller.id, shop: `${seller.shop.city} ${seller.shop.street && ', ' + seller.shop.street} ${seller.shop.building && ', ' + seller.shop.building}`}))}))
        }
        fetchData();
    }, [token])

    const submitHandler = async (e) => {
        e.preventDefault();
        if(name !== formData.name || phone !== formData.phone || login !== formData.login || formData.password || email !== formData.email || telegramName !== formData.telegramName || formAddress !== address) {
            if(!formData.password) delete formData.password
            
            const sellerId = fetchData.sellers.find(item => item.shop === formAddress).id

            await appAPI.editCourier({...formData, sellerId: sellerId}, id)
                .then(() => {
                    setForceUpdate(prev => prev + 1)
                    close();
                })
                .catch((err) => {alert(err.response.data.message)})

        } else alert('Все поля совпадают с предыдущим значением')
    }

    return(
        <div className={s.modalAdmin}>
            {fetchData.isFetching 
                ?   null
                :
            <form className={s.formAdmin} onSubmit={submitHandler}>
                <label className = {`${s.label}`}>
                    <p>ФИО</p>
                    <input 
                        type="text"
                        name = 'name'
                        defaultValue = {name}
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Телеграм</p>
                    <input 
                        type="text"
                        name = 'telegramName'
                        defaultValue = {telegramName}
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Телефон</p>
                    <InputMask
                        mask = '+7 999 999 99 99'
                        name = 'phone'
                        defaultValue = {phone.slice(1)}
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Логин</p>
                    <input 
                        type="text"
                        name = 'login'
                        defaultValue = {login}
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Пароль</p>
                    <input 
                        type="password"
                        name = 'password'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <div className = {`${s.customLabel} ${s.label}`}>
                    <p>ПВЗ</p>
                    <CustomSelectCourier 
                        placeholder = {formAddress}
                        items = {fetchData.sellers.map(seller => seller.shop)}
                        className = {s.address}
                        setState = {setFormAddress}
                    />
                </div>
                <button type = 'submit' className={`button ${name !== formData.name || phone !== formData.phone || login !== formData.login || formData.password || email !== formData.email || telegramName !== formData.telegramName || formAddress !== address ? 'btn_red' : ''}`}>Сохранить</button>
            </form>
            }
        </div>
    )
}

export default EditCourier;
