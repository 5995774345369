import React from 'react'
import s from '../Table.module.sass'
import {useDispatch} from 'react-redux'
import {openModalAction} from '../../../../../Actions/ModalActions'
import EditCourier from '../../../Modals/EditCourier';
import CustomRadioButton from '../../../Common/CustomRadioButton';
import {appAPI} from '../../../../../API/API'
import {getPhone} from '../../../Common/OtherFunctions/OtherFunctions'

const TableItemCourier = ({item, setState, setForceUpdate}) => {

    const {username, phone, login, courierType, password, id, telegramName, seller} = item;
    const address = seller && seller.shop ? `${seller.shop.city} ${seller.shop.street && ', ' + seller.shop.street} ${seller.shop.building && ', ' + seller.shop.building}` : '';
    const dispatch = useDispatch();

    const delCourierFunc = async (id) => {
        await appAPI.delCourier(id)
            .then(() => setState(prev => prev.filter(courier => courier.id !== id)))
            .catch(err => alert(err.response.data.message))
    }

    const changeCourierRole = async () => {
        await appAPI.editCourier({courierType: item.courierType === 'SUPER' ? 'ORDINARY' : 'SUPER'}, item.id)
            .then(respose => {
                setForceUpdate(prev => prev + 1)
            })
    }


    return(
        <tr className="table-item">
            <td>{username}</td>
            <td>{telegramName}</td>
            <td>{getPhone(phone)}</td>
            <td>
                {`${seller && seller.shop ? `${seller.shop.city} ${seller.shop.street && ', ' + seller.shop.street} ${seller.shop.building && ', д.' + seller.shop.building}` : '-'}`}
            </td>
            <td onClick={() => dispatch(openModalAction(EditCourier, {name: username, phone, login, password, address, id, setState, telegramName, setForceUpdate}))}>
                <img src="/img/adminRed.svg" alt="img" className = {s.imgScale}/>
            </td>
            <td><CustomRadioButton active = {courierType === 'SUPER'} func = {changeCourierRole}/></td>
            <td onClick = {() => delCourierFunc(id)}>
                <img src="/img/adminDel.svg" alt="img" className = {s.imgScale}/>
            </td>
        </tr>
    )
}

export default TableItemCourier;