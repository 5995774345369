import React, { useState } from 'react';
import s from './Delivery.module.sass'
import sModals from '../Modals/Modals.module.sass'
import {appAPI} from '../../../API/API'

const Delivery = () => {

    const [formData, setFormData] = useState()
    const [isFetching, setIsFetching] = useState(true)
    const [flagValidation, setFlagValidation] = useState(false)

    React.useEffect(() => {
        const fetchData = async () => {
            await appAPI.getDeliverySettings()
                .then(response => {
                    setFormData(response)
                    setIsFetching(false)
                })
                .catch(err => console.error(err))
        }
        fetchData();
    }, [])

    const changeHandler = (e) => {
        let {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
        if(!flagValidation) setFlagValidation(true)
    }

    const submitHandler = e => {
        e.preventDefault();
        if(flagValidation) {
            appAPI.editDeliverySettings({nearDeliveryPrice: parseInt(formData.nearDeliveryPrice), farDeliveryPrice: parseInt(formData.farDeliveryPrice), freeDeliveryCondition: parseInt(formData.freeDeliveryCondition), deliveryTimeCondition: '60'})
                .then(response => response.data)
                .catch(err => console.error(err))
        } else alert('Нет изменненых данных')
    }
    
    return(
        <div className = {s.delivery}>
            <h2 className = {s.header}>Доставка</h2>
            <div className = {s.content}>
                {
                    isFetching
                        ? null   
                        : <form className = {`${s.form} ${sModals.form}`} onSubmit={submitHandler}>

                            <h3 className = {s.title}>Стоимость доставки</h3>
                            <label className = {`${sModals.label}`}>
                                <p>В пределах радиуса 6км:</p>
                                
                                <input 
                                    type="number"
                                    name = 'nearDeliveryPrice'
                                    onChange={changeHandler}
                                    className = {`${s.input} ${sModals.input}`}
                                    defaultValue = {formData.nearDeliveryPrice}
                                />
                                &#160;
                                <span>руб.</span>
                            </label>

                            <label className = {`${sModals.label}`}>
                                <p>За пределами радиуса 6км:</p>
                                <input 
                                    type="number"
                                    name = 'farDeliveryPrice'
                                    onChange={changeHandler}
                                    className = {`${s.input} ${sModals.input}`}
                                    defaultValue = {formData.farDeliveryPrice}
                                />
                                &#160;
                                <span>руб.</span>
                            </label>

                            <h3 className = {s.title}>Условия бесплатной доставки</h3>
                            <label className = {`${sModals.label}`}>
                                <p>Необходимая сумма заказа для предоставления бесплатной доставки:</p>
                                <input 
                                    type="number"
                                    name = 'freeDeliveryCondition'
                                    onChange={changeHandler}
                                    className = {`${s.input} ${sModals.input}`}
                                    defaultValue = {formData.freeDeliveryCondition}
                                />
                                &#160;
                                <span>руб.</span>
                            </label>

                            <button className={`${s.button} ${flagValidation ? s.buttonActive : ''}`} type = 'submit'>Сохранить</button>
                        </form> 
                }
            </div>
        </div>
    )   
}

export default Delivery;