import React from 'react';
import Table from '../Users/Table/Table';
import {appAPI} from '../../../API/API'
import TableItemRecepies from '../Users/Table/TableItems/TableItemRecepies';
import CreateRecepie from '../Modals/CreateRecepie';

const Recepies = () => {

    const headers = ['', 'Заголовок', 'Редактировать', 'Удалить']
    const [recepies, setRecepies] = React.useState([])
    const [forceUpdate, setForceUpdate] = React.useState(0)
    
    React.useEffect(() => {
        const fetchData = async () => {
            await appAPI.getRecepies()
                .then(response => {setRecepies(response.data.paginated)})
                .catch(error => console.error(error))
        }
        fetchData();
    }, [forceUpdate])
    
    return(
        <div>
            <Table
                title = 'Рецепты'
                tableItems = {recepies}
                headers = {headers}
                Component = {TableItemRecepies}
                showPlus = {true}
                ComponentCreate = {CreateRecepie}
                setState = {setRecepies}
                setForceUpdate = {setForceUpdate}
            />
        </div>
    )   
}

export default Recepies;