import {TOGGLE_IS_FETCHING} from '../Actions/AdminActions';


let initialState = {
	isFetchingArray: []
}


const AdminReducer = (
  state = {
    isFetchingArray: []
  },
  action,
) => {
    switch (action.type) {
		case TOGGLE_IS_FETCHING: {
			let fetchingArray = initialState.isFetchingArray
			let elemIndex = fetchingArray.indexOf(action.instanceName)

			if (elemIndex === -1) {
				fetchingArray.push(action.instanceName)
			} else {
				fetchingArray.splice(elemIndex, 1)
			}

			return {
				...state,
				isFetchingArray: [...fetchingArray]
			}
		}
    
    default:
      return state;
  }
};

export { AdminReducer };
