import React from 'react';
import './Seller.sass'
import { apiURL } from '../../API/API';

const SellerProduct = ({product, activeModal, article, amount, scanCount, resetScanCount, scanReady}) => {
    amount = parseFloat(amount);
    const setStatus = () => {
        if (amount > scanCount) return  <p className="orange">Недорасход</p>
        else if (amount < scanCount) return  <p className="red">Перерасход</p>
        else return <p className="green">Ок</p>
    }

    return(
        <div className="product">
            <div className="product__left">
                <img src={`${apiURL}${product.images[0].url}`} alt="img" className="product__img"/>
                <div className="product__desc">
                    <p className="desc_bold">{product.name}</p>
                    <p className="desc_light">Артикул {product.identifier} | {product.unit === 'кг' ? `${product.weight} ${product.unit}` : `1 ${product.unit}`}</p>
                </div>
            </div>
            <div className="product__right product__right_scan">
                {
                    activeModal === 'scan'
                        ? (

                            <div className="product__desc">
                                <p className="desc_bold">{amount.toFixed(2)}</p>
                                <p className="desc_light">{scanCount.toFixed(2)}</p>
                            </div>

                        ) : (

                            <div className="product__desc">
                                <p className="desc_bold">{((product.promotionalPrice || product.ordinaryPrice) * amount).toFixed(2)} ₽</p>
                                <p className="desc_light">{(product.unit === 'кг' ? (product.promotionalPrice || product.ordinaryPrice) / product.weight : (product.promotionalPrice || product.ordinaryPrice)).toFixed(2)} ₽  х {(product.unit === 'кг' ? amount * product.weight : amount).toFixed(2)}</p>
                            </div>

                        )
                }

                {activeModal === 'scan'
                    ? (
                        <>
                            <div className="product__status product__status_scan">
                                {setStatus()}
                            </div>
                            <img alt="" src = '/img/formClose.svg' className = 'product-close' onClick={() => resetScanCount(article)}/>
                        </>
                    ) : (

                        <div className="product__status">
                            <p className="button">Есть на складе</p>
                            {!scanReady 
                                ? <p style = {{width: '110px'}}>Не пробит</p>
                                : <p className="button" style = {{width: '110px'}}>Пробит</p>
                            }
                            
                        </div>

                    )
                }

            </div>
        </div>
    )
}

export default React.memo(SellerProduct, (prevProps, nextProps) => {
    return nextProps.activeModal === 'scan' && prevProps.activeModal === 'scan' && prevProps.scanCount === nextProps.scanCount
});