import React, {useEffect, useState} from 'react';
import './Courier.sass'
import io from "socket.io-client"
import {openModalAction} from "../../Actions/ModalActions";
import {delOrderSeller, setOrders} from '../../Actions/OrderActions'
import {logoutUser} from '../../Actions/UserActions'
import {useSelector, useDispatch} from 'react-redux'
import NewOrder from './CourierModals/NewOrder'
import CourierOrder from './CourierOrder';
import Request from '../../Components/Modals/Request'
import {appAPI, apiURL} from '../../API/API'
import Cookies from 'js-cookie';

const Courier = () => {

    const {token} = useSelector((state) => state.User);
    const baseUrl = apiURL;
    const dispatch = useDispatch();
    const {orders} = useSelector((state) => state.Order);
    const [isActive, setIsActive] = useState(false)
    const [courierId, setCourierId] = useState('')
    const [login, setLogin] = useState('')
    let socket = undefined;

    //  = io.connect(baseUrl + '/couriers', {
    //     query: {token}
    // });

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        socket = io.connect(baseUrl + '/couriers', {
        query: {token}})
    }, [isActive])

    useEffect(() => {
        const fetchData = async () => {
            try{
                const response = await appAPI.courierActiveOrders(token)
                dispatch(setOrders(response.data))
            } catch (err) {
                err.response.data.statusCode === 404 && dispatch(logoutUser)
            }
        }
        fetchData();
    }, [dispatch, isActive, token])

    useEffect(() => {
        socket.on("ADD_ORDER", (order) => {
            dispatch(openModalAction(
                NewOrder,
                {order}
            ))
        })

        socket.on("ADD_DELEGATED_ORDER", (order) => {
            dispatch(openModalAction(
                NewOrder,
                {order, type: 'DELEGATED'}
            ))
        })

        socket.on("ORDER_REJECTED", (order) => {
            dispatch(delOrderSeller(order.id));
            dispatch(openModalAction(
                NewOrder,
                {order, type: 'REJECTED'}
            ))
        })
    }, [dispatch, socket])

    useEffect(() => {
        const fetchData = async () => {
            await appAPI.getProfile(token)
            .then(response => {
                setIsActive(response.isActive)
                setCourierId(response.id)
                setLogin(response.login)
            })
            .catch(err => alert(err))
        }
        fetchData();
    }, [token])

    const courierActive = async () => {
        await appAPI.courierActive(courierId, !isActive)
        setIsActive(!isActive)
    }
    
    return(
        
        <div className="page-courier">
            <h1>Личный кабинет курьера {login}</h1>
            {isActive &&
                <>
                    {orders.reverse().map(order => (order.deliveryStatus !== "DELIVERED" && order.deliveryStatus !== "RETURNED") && <CourierOrder order = {order} key = {order.id}/>)}
                    <button className="button btn_courier" onClick = { () => dispatch(openModalAction(Request))}>
                        <img src="/img/btnSupport.svg" alt="img"/>
                        <span>Поддержка</span>
                    </button>
                </>
            }
            <button className={`button btn_courier-active ${!isActive ? 'btn_success' : 'btn_red'}`} onClick = {courierActive}>{!isActive ? 'Начать работу' : 'Завершить работу'}</button>
            <button className={`button btn_courier-active btn_success`} onClick = {() => {Cookies.remove('tokenStaff'); localStorage.removeItem('userRole'); window.location.reload();}}>Выйти</button>
        </div>
    
    )
}

export default Courier;