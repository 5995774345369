import React, {useState} from 'react'
import s from './CustomRadioButton.module.sass'

const CustomRadioButton = ({active, func}) => {

    const [activeSpan, setActiveSpan] = useState(active)

    React.useEffect(() => setActiveSpan(active), [active])


    return(
        <div className = {`${s.customRadioButton} ${activeSpan ? `${s.active}` : ''}`} onClick = {() => {func(!activeSpan)}}>
            <span className = {`${!activeSpan ? `${s.active}` : ''}`}/>
            <span className = {`${activeSpan ? `${s.active}` : ''}`}/>
        </div>
    )
}

export default CustomRadioButton;