import React from 'react'
import s from '../CustomFormRowRecepie.module.sass'
import styleM from '../../../Modals/Modals.module.sass'
import { apiURL } from '../../../../../API/API';

const RowSteps = React.memo(({img, setState, id, row, showDefault, setDeletedState, setValidationFlag}) => {

    const changeHandlerFile = (e) => {
        const input = e.currentTarget;
        setState(prev => prev.map(item => item.id === id ? {...item, img: input.files[0]} : item));
        setValidationFlag(true)
    }

    const changeHandlerDesc = (e) => {
        const input = e.currentTarget;
        setState(prev => prev.map(item => item.id === id ? {...item, description : input.value} : item));
        setValidationFlag(true)
    }

    const deleteStep = id => {
        setDeletedState(prev => [...prev, id])
        setState(prev => prev.filter(item => item.id !== id))
        setValidationFlag(true)
    }

    return(
        <div className = {s.customRow}>
            <input 
                type = "text"
                placeholder = 'Описание рецепта'
                className = {`${styleM.input} ${s.input}`}
                onChange = {changeHandlerDesc}
                defaultValue = {showDefault && row.description}
            />

            <label className = {s.inputFile}>
                {!row.img && !row.image ?
                    <>
                        <p>
                            <img src="/img/screpka.svg" alt="img"/>
                            <span>Загрузите фото</span>
                        </p>
                        <input 
                            type = "file"
                            placeholder = 'Количество'
                            className = {`${styleM.input} ${s.input}`}
                            multiple accept="image/*" 
                            onChange = {changeHandlerFile}
                        />
                    </>
                    : row.img 
                        ? <img alt="" className = {s.imgStep} src = {URL.createObjectURL(row.img)}/>
                        : row.image ? <img alt="" className = {s.imgStep} src = {`${apiURL}${row.image}`}/> : null
                }
            </label>

            {img === 'delete' 
                ? <img className = {s.img} src="/img/adminDel.svg" alt="img" onClick = {() => deleteStep(id)}/>
                : <img className = {s.img} src="/img/adminPlus.svg" alt="img" onClick = {() => setState(prev => [...prev, {img: null, description: '', id: Date.now()}])}/>
            }
        </div>
    )
});

export default RowSteps;