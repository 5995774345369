import React, {useState} from 'react'
import s from './SellerStatistics.module.sass'
import AnimateHeight from 'react-animate-height';
import {useDispatch, useSelector} from 'react-redux';
import {acceptOrder} from '../../Actions/OrderActions';
import {appAPI} from '../../API/API';
import moment from 'moment';

const SellerStatistics = () => {

    const [height, setHeight] = useState(0)
    const {orders} = useSelector(state => state.Order)
    const {token} = useSelector(state => state.User)
    const [activeOrders, setActiveOrders] = useState('')
    const dispatch = useDispatch();
    let notAccepted = 0;
    let inProccesing = 0;
    let ready = 0;
    
    orders.forEach(order => {
        if(order.sellerStatus === 'PENDING') notAccepted += 1
        else if(order.sellerStatus === 'READY') ready += 1
        else if(order.sellerStatus === 'PROCESSING')inProccesing += 1
    })
    
    return(
        <div className = {s.statisticsWrapper}>
            {!activeOrders &&
                <ul>
                    <li onClick = { () => {setActiveOrders('PENDING'); setHeight('auto') }}>Не принятые: {notAccepted}</li>
                    <li onClick = { () => {setActiveOrders('PROCESSING'); setHeight('auto') }}>В обработке: {inProccesing}</li>
                    <li onClick = { () => {setActiveOrders('READY'); setHeight('auto') }}>Не отгружены: {ready}</li>
                </ul>
            }

            <AnimateHeight
                className={s.statisticsContent}
                duration={ 300 }
                height={ height }>
                
                {activeOrders &&
                    <table>
                        <thead>
                            <tr>
                                <th>Номер заказа</th>
                                <th>Дата заказа</th>
                                <th>Способ доставки</th>
                                <th>Действие</th>
                                <th onClick = { () => {setActiveOrders(''); setHeight(0) }}><img src="/img/closeRed.svg" alt="close"/></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.filter(order => order.sellerStatus === activeOrders).map(order => 
                                <tr key = {order.id}>
                                    <td>{order.id}</td>
                                    <td>{moment(order.orderDate).format('DD.MM.YYYY')}</td>
                                    <td>{order.orderReceiving === 'DELIVERY' ? 'Доставка' : 'Самовывоз'}</td>
                                    <td>
                                        {order.sellerStatus === 'PENDING' &&
                                            <button className="button btn_red" onClick = { async () => {
                                                await appAPI.sellerAccept(order.id, token)
                                                .then(() => dispatch(acceptOrder(order.id)))
                                            }}>Принять заказ</button>
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                }
                

            </AnimateHeight>
        </div>
    )
}

export default SellerStatistics;