import React from 'react'
import s from '../Table.module.sass'
import {useDispatch} from 'react-redux'
import {openModalAction} from '../../../../../Actions/ModalActions'
import EditVacancies from '../../../Modals/EditVacancies';
import {appAPI} from '../../../../../API/API'

const TableItemVacancies = ({item, setState}) => {
    
    const dispatch = useDispatch();

    const delVacancies= async (id) => {
        await appAPI.delVacancies(id)
            .then(() => setState(prev => prev.filter(vacancies => vacancies.id !== id)))
            .catch(err => alert(err.response.data.message))
    }

    return(
        <tr className="table-item">
            <td>{item.name}</td>
            <td>{item.phone}</td>
            <td>{item.address}</td>
            <td>{item.salaryFrom} - {item.salaryTo}</td>
            <td>
                <img className = {s.imgScale} onClick = {() => dispatch(openModalAction(EditVacancies, {item, setState}))} src="/img/adminRed.svg" alt="img"/>
            </td>
            <td onClick = { () => delVacancies(item.id)}>
                <img className = {s.imgScale} src="/img/adminDel.svg" alt="img"/>
            </td>
        </tr>
    )
}

export default TableItemVacancies;