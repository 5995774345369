import React, {useState} from 'react'
import s from './Group.module.sass'
import Category from '../Category/Category';
import {appAPI} from '../../../../API/API'

const Group = React.memo(({title, id}) => {

    const [categories, setCategories] = useState([])
    const [createCategory, setCreateCategory] = useState(0)
    const [isFetching, setIsFetching] = useState(true)

    React.useEffect(() => {
        const fetchData = async () => {
            await appAPI.getCategoryByGroup(id)
                .then(response => {
                    setCategories(response.data.categories)
                    setIsFetching(false)
                })
                .catch(error => console.error(error))
        }
        fetchData();
    }, [createCategory, id])


    const editCategories = (id, value, setState) => {
        setState(prev => prev.map(category => category.id === id ? {...category, title: value} : category))
    }


    const delCategories = (id, setState) => {
        setState(prev => prev.filter(category => category.id !== id))
    }
    
    return(
        <div className = {s.group}>
            {isFetching 
                ?   null
                :   <>
                        <div className = {s.groupTop}> 
                            <h3>{title}</h3>
                            <img src="img/adminPlus.svg" alt="img" onClick = {() => setCategories(prev => [...prev, {id: Date.now, title: '', active: true}])}/>
                        </div>
                        {
                            categories.map((category => 
                                <Category
                                    title = {category.title} 
                                    id = {category.id} 
                                    groupId = {id}
                                    localId = {category.localId}
                                    setCategories = {setCategories}  
                                    editCategories = {editCategories}
                                    delCategories = {delCategories} 
                                    setCreateCategory = {setCreateCategory}   
                                    active = {category.active}                 
                                    key = {category.id}
                                />))
                        }
                    </>
            }
        </div>
    )
})

export default Group;