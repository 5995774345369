export const getPhone = phone => {
    let newPhone = '';
    if(phone[0] === '+' && phone.length <= 12) {
        for(let i = 0; i < phone.length; i++) {
            if(i === 2 || i === 5 || i === 8 || i === 10) newPhone += ' ' + phone[i]
            else newPhone += phone[i]
        }
        return newPhone
    } else if(phone[0] === '8'){
        if(phone.length >= 12) phone = phone.replace(/\s/g, '')
        for(let i = 0; i < phone.length; i++) {
            if(i === 0) newPhone = '+7 '
            else if(i === 4 || i === 7 || i === 9) newPhone += ' ' + phone[i]
            else newPhone += phone[i]
        }
        return newPhone
    } else if(phone[0] === '7' && phone.length === 11){
        for(let i = 0; i < phone.length; i++) {
            if(i === 0) newPhone = '+7 '
            else if(i === 4 || i === 7 || i === 9) newPhone += ' ' + phone[i]
            else newPhone += phone[i]
        }
        return newPhone
    } else return phone
 
}

export const shallowEqualArr = (arr1, arr2) =>  {
    let flag = true;
    if(arr1.length === arr2.length) {
        for(let i = 0; i < arr1.length; i++) {
            if(!shallowEqualObj(arr1[i], arr2[i])) {
                flag = false;
                break;
            }
        }
        return flag
    } else return false
}

export const shallowEqualObj = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
}