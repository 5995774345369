import React, {useEffect, useState} from 'react'
import s from './Modals.module.sass'
import {appAPI} from '../../../API/API'
import CustomSelectCourier from '../Common/CustomSelectCourier/CustomSelectCourier';

const CreateSeller = ({item, setState, close, setForceUpdate}) => {

    const [formData, setFormData] = useState({
        login: '',
        password: '',
    })

    const [address, setAddress] = useState('')
    const [data, setData] = useState({
        shops: [],
        isFetching: true
    })

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    const submitHandler = e => {
        e.preventDefault();
        if(formData.password && formData.login && address) {
            if(formData.password.length < 5) alert('Длина пароля должна быть от 5 символов') 
            else {
                const shopItem = data.shops.find(shop => shop.address === address)
                appAPI.createSeller({...formData, shopId: shopItem.shopId})
                    .then(response => {
                        setForceUpdate(true)
                        close();
                    })
                    .catch(err => {
                        if(err.response.data.statusCode === 409) alert('Пользователь с таким логином существует')
                    })
            } 
        } else alert('Введены не все данные')
    }

    useEffect(() => {

        const fetchData = async () => {
            try {  
                const response = await appAPI.getShopsWithoutSellers()
                setData({shops: response.data.filter(shop => shop.city).map(shop => ({address: `${shop.city} ${shop.street && ', ' + shop.street} ${shop.building && ', ' + shop.building}`, shopId: shop.id})), isFetching: false})
            } catch(err) {alert.log(err)}
        }
        fetchData();
    }, [])

    return(
        <div className={s.modalAdmin}>
            {data.isFetching 
                ?   null
                :   
            <form className={s.formAdmin} onSubmit={submitHandler}>
                <label className = {`${s.label}`}>
                    <p>Логин</p>
                    <input 
                        type="text"
                        name = 'login'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Пароль</p>
                    <input 
                        type="password"
                        name = 'password'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                {data.shops.length > 0 
                    ?   <div className = {`${s.customLabel} ${s.label}`}>
                            <p>ПВЗ</p>
                            <CustomSelectCourier
                                placeholder = {data.shops[0].address}
                                items = {data.shops.map(shop => shop.address)}
                                className = {s.address}
                                setState = {setAddress}
                            />
                        </div>
                    :   null
                }
                <button type = 'submit' className={`button btn_red`}>Создать</button>
            </form>
            }
        </div>
    )
}

export default CreateSeller;
