import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {appAPI} from '../../../API/API'
import Table from './Table/Table';
import s from './Users.module.sass'
import TableItemCourier from './Table/TableItems/TableItemCourier'
import CreateCourier from '../Modals/CreateCourier'

const Couriers = () => {
	const {token} = useSelector((state) => state.User)
	const headers = ['ФИО', 'Телеграм', 'Телефон', 'ПВЗ', 'Редактировать', 'Супер - курьер', 'Удалить']
	const [couriers, setCouriers] = useState([])
	const [forceUpdate, setForceUpdate] = useState(0)

	
	useEffect(() => {
		const fetchData = () => {
			appAPI.getCouriers(token)
				.then(response => setCouriers(response.data.data))
		}
		fetchData();
	}, [forceUpdate, token])

	return (
		<div className= {s.users}>
			<Table 
				title = 'Курьеры' 
				tableItems = {couriers.map(el => ({username: el.name, phone: el.phone, id: el.id, courierType: el.courierType, login: el.login, password: el.password, telegramName: el.telegramName, seller: el.seller}))} 
				headers = {headers} 
				Component = {TableItemCourier} 
				showPlus = {true}
				ComponentCreate = {CreateCourier}
				setState = {setCouriers}
				setForceUpdate = {setForceUpdate}
			/>
		</div>
	)
}

export default Couriers;