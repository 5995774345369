import React from 'react'
import {useDispatch} from 'react-redux'
import {openModalAction} from '../../../../../Actions/ModalActions'
import {apiURL} from '../../../../../API/API'
import s from '../Table.module.sass'
import EditShop from '../../../Modals/EditShop';

const TableItemShop = ({item, setState, setForceUpdate}) => {

    const dispatch = useDispatch();

    return(
        <tr className="table-item">
            <td className = {s.imgTable}>
                {item.images && item.images.length > 0 &&
                // <LazyLoad>
                    <img alt="" src = {`${apiURL}${item.images[0].url}`}/>
                // </LazyLoad>
                }
            </td>
            <td style = {{maxWidth: '600px'}}>{item.name}</td>
            <td>{item.activeForDelivery && item.activeForPickup 
                    ? 'ПВЗ и самовывоз' 
                    : item.activeForDelivery 
                        ? 'Доступен как ПВЗ'
                        : item.activeForPickup 
                            ? 'Доступен на самовывоз'
                            : 'Не доступен'}</td>
            <td onClick={() => dispatch(openModalAction(EditShop, {item, setForceUpdate}))}>
                <img className = {s.imgScale} src="/img/adminRed.svg" alt="img"/>
            </td>
        </tr>
    )
}

export default TableItemShop;