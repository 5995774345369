import React, { useLayoutEffect } from 'react'
import './Modals.sass'
import {useDispatch, useSelector} from 'react-redux'
import {delOrder, setOrder} from '../../../Actions/OrderActions'
import {appAPI} from '../../../API/API'

const NewOrder = ({close, order, type = "DEFAULT"}) => {

    const dispatch = useDispatch()
    const {token} = useSelector((state) => state.User)
    
    useLayoutEffect(() => {
        if (type === "DELEGATED") {
            dispatch(setOrder(order));
        } else if (type === "REJECTED") {
            console.log('order', order )
            dispatch(delOrder(order.id));
        }
    }, [dispatch, order, type])
    

    const acceptOrder = async() => {
        try{
            await appAPI.courierOrderAccept(order.id, token)
            dispatch(setOrder(order))
            close();
        } catch(err) {}
    }

    return(
        <div className="container-modal">
            <img src="/img/closeRed.svg" alt="close" className="courier-modal-close" onClick={() => close()}/>
            {type === "DEFAULT" && <h2>Поступил новый заказ на доставку</h2>}
            {type === "DELEGATED" && <h2>Делегирован новый заказ на доставку</h2>}
            {type === "REJECTED" && <h2>Заказ отменен</h2>}
            <p>Заказ № {order.id}</p>
            {type === "DEFAULT" && 
            <>
                <button className = 'button btn_red' onClick = {acceptOrder}>Принять</button>
                <button className = 'button btn_grey' onClick = {() => close()}>Отказаться</button>
            </>
            }
            {(type === "DELEGATED" || type === "REJECTED")  && 
            <>
                <button className = 'button btn_red' onClick = {() => { close();}}>Принять</button>
            </>
            }
        </div>
    )
}

export default NewOrder;