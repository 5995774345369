import React from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {appAPI} from '../../API/API'
import {delOrderSeller} from '../../Actions/OrderActions'
import { getPhone } from '../../pages/Admin/Common/OtherFunctions/OtherFunctions';

const Utochnenie = ({phone, order, close, forceUpdate, setActiveOrder, scanCount}) => {

    const dispatch = useDispatch();
    const {token} = useSelector(state => state.User)

    const cancelOrder = async (id) => {
        try{    
            await appAPI.rejectedOrder(id)
            dispatch(delOrderSeller(id))
            close();
        } catch (err) {}
    }


    const changeDeliveryDate = async (id) => {
        await appAPI.postponementOrder(id, token)
        .then( () => {
            setActiveOrder(true)
            forceUpdate();
            close();
        })
        .catch(err => console.log(err))
    }


    return(
        <div className="modal_utochnenie">
            <img src="/img/closeRed.svg" className="form-close" alt="close" onClick = {() => close()}/>
            <h1>Уточнение у клиента</h1>
            <p className="phone">{getPhone(phone)}</p>
            <div className="btns">
                <button className = 'button btn_red btn_modal' onClick = {() => cancelOrder(order.id)}>Клиент отказался</button>
                
                <button className = 'button btn_red btn_modal' onClick = { () => changeDeliveryDate(order.id)}>Перенос доставки на следующий день</button>
            </div>

        </div>
    )


}

export default Utochnenie;