import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {appAPI} from '../../../API/API'
import Table from './Table/Table';
import s from './Users.module.sass'
import TableItemUser from './Table/TableItems/TableItemUser';


const Service = () => {
	const {token} = useSelector((state) => state.User)

	const headers = ['ФИО', 'Телефон', 'Email', 'Редактировать', 'Удалить']
	const [users, setUsers] = useState([])
	
	useEffect(() => {
		const fetchData = async () => {
			await appAPI.getUsers(token)
				.then(response => setUsers(response.data))
				.catch(err => {})
		}
		fetchData()
	}, [token])

	return (
		<div className= {s.users}>
			<Table 
				title = 'Пользователи' 
				tableItems = {users.map(el => ({username: el.username, phone: el.phone, email: el.email, id: el.id}))} 
				headers = {headers} 
				Component = {TableItemUser}
				setState = {setUsers}
			/>
		</div>
	)
}

export default Service