import React, {useEffect, useState} from 'react'
import s from './Modals.module.sass'
import {appAPI} from '../../../API/API'
import CustomSelectCourier from '../Common/CustomSelectCourier/CustomSelectCourier';
import CustomGallary from '../Common/CustomGallary/CustomGallary';

const EditShop = ({item, setState, close, setForceUpdate}) => {

    const [formData, setFormData] = useState({
        img: '',
        video: item.video,
        count: item.irreducibleBalanceByCount, 
        weight: item.irreducibleBalanceByWeight
    })
    const [pvzStatus, setPvzStatus] = useState(
        item.activeForDelivery && item.activeForPickup 
            ? 'ПВЗ и самовывоз' 
            : item.activeForDelivery 
                ? 'Доступен как ПВЗ'
                : item.activeForPickup 
                    ? 'Доступен на самовывоз'
                    : 'Не доступен'
    )
    const [gallaryImages, setGallaryImages] = useState(item.images)
    const [deletedImages, setDeletedImages] = useState([])

    const [objectDeliveryPickup, setObjectDeliveryPickup] = useState({
        activeForDelivery: item.activeForDelivery,
        activeForPickup: item.activeForPickup
    })

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    useEffect(() => {
        if (pvzStatus === 'ПВЗ и самовывоз') {
            setObjectDeliveryPickup({
                activeForDelivery: true,
                activeForPickup: true
            })
        } 
        else if (pvzStatus === 'Доступен как ПВЗ') {
            setObjectDeliveryPickup({
                activeForDelivery: true,
                activeForPickup: false
            })
        }
        else if (pvzStatus === 'Доступен на самовывоз') {
            setObjectDeliveryPickup({
                activeForDelivery: false,
                activeForPickup: true
            })
        } else setObjectDeliveryPickup({
                activeForDelivery: false,
                activeForPickup: false
                })
    }, [pvzStatus])

    const submitHandler = async (e) => {
        e.preventDefault();
        
        if(gallaryImages !== item.images || deletedImages.length > 0 || formData.video !== item.video || objectDeliveryPickup.activeForDelivery !== item.activeForDelivery || objectDeliveryPickup.activeForPickup !== item.activeForPickup || formData.count !== item.irreducibleBalanceByCount || formData.weight !== item.irreducibleBalanceByWeight) {

            await appAPI.editShop(item.id, {video: formData.video, activeForDelivery: objectDeliveryPickup.activeForDelivery, activeForPickup: objectDeliveryPickup.activeForPickup, irreducibleBalanceByCount: formData.count, irreducibleBalanceByWeight: formData.weight})
                .then(response => response.data)
                .catch(error => console.error(error))

            await Promise.all(deletedImages.map(id => new Promise((resolve, reject) => {
                appAPI.delShopPhoto(item.id, id)
                    .then(() => resolve('ok'))
            })))

            await Promise.all(gallaryImages.map(img => new Promise((resolve, reject) => {
                if(typeof img.url === 'object') {
                    const data = new FormData();
                    data.append('file', img.url)
                    appAPI.editShopPhoto(item.id, data)
                        .then(() => resolve('ok'))
                } else resolve('ok')
            })))

            setForceUpdate(prev => prev + 1);
            close();

        } else alert('Все поля совпадают с предыдущим значением')
    }

    return(
        <div className={`${s.modalAdmin} ${s.modalWide}`}>
            <form className={`${s.formAdmin} ${s.formWide}`} onSubmit={submitHandler}>
                    
                <CustomGallary
                    state = {gallaryImages}
                    setState = {setGallaryImages}
                    stateDelete = {deletedImages}
                    setStateDelete = {setDeletedImages}
                    title = 'Изображения'
                />

                <label className = {`${s.label}`}>
                    <p>Ссылка на видео</p>
                    <input 
                        type="text"
                        name = 'video'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.video}
                    />
                </label>

                <div className = {`${s.customLabel} ${s.label}`} style = {{maxWidth: '250px'}}>
                    <p>Статус ПВЗ</p>
                    <CustomSelectCourier 
                        placeholder = {pvzStatus}
                        items = {['Доступен как ПВЗ', 'Доступен на самовывоз', 'ПВЗ и самовывоз', 'Недоступен']}
                        className = {s.address}
                        setState = {setPvzStatus}
                        defaultValue = 'Доступен как ПВЗ'
                    />
                </div>

                <div className = {s.shopItems}>
                    <p style = {{fontSize: '18px', lineHeight: '22px'}}>Неснижаемые остатки</p>
                    <label className = {`${s.labelRow} ${s.label}`}>
                        <p style = {{fontSize: '16px', lineHeight: '24px'}}>Штучный товар</p>
                        <input 
                            type="number"
                            name = 'count'
                            onChange={changeHandler}
                            defaultValue = {formData.count}
                            className = {s.input}
                            style = {{maxWidth: '90px'}}
                        />
                    </label>

                    <label className = {`${s.labelRow} ${s.label}`}>
                        <p style = {{fontSize: '16px', lineHeight: '24px'}}>Весовой товар</p>
                        <input 
                            type="number"
                            name = 'weight'
                            onChange={changeHandler}
                            className = {s.input}
                            defaultValue = {formData.weight}
                            style = {{maxWidth: '90px'}}
                        />
                    </label>
                </div>
                
                {/* <div className = {`${s.customLabel} ${s.label}`} style = {{maxWidth: '250px'}}>
                    <p>Зона покрытия ПВЗ</p>
                    <CustomSelectCourier
                        placeholder = 'Радиус - 3км'
                        items = {['Радиус - 3км', 'Радиус - 6км', 'Радиус - 9км']}
                        className = {s.address}
                        setState = {setAddress}
                        defaultValue = {formData.address}
                    />
                </div> */}
                <button type = 'submit' className={`button ${gallaryImages !== item.images || deletedImages.length > 0 || formData.video !== item.video || objectDeliveryPickup.activeForDelivery !== item.activeForDelivery || objectDeliveryPickup.activeForPickup !== item.activeForPickup || formData.count !== item.irreducibleBalanceByCount || formData.weight !== item.irreducibleBalanceByWeight? s.btn_red : ''}`}>Сохранить</button>
            </form>
        </div>
    )
}

export default EditShop;
