export const listenerEsc = (deleteFunc, id, variable, setStateDelete, setInput) => {
    const handleEsc = (event) => {
        if (event.keyCode === 27) {
            variable
                ? deleteFunc(id, setStateDelete)
                : setInput(false)
        }
    }
    window.addEventListener('keydown', handleEsc)
    return () => window.removeEventListener('keydown', handleEsc)
}