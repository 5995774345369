export const CLOSE_MODAL = 'CLOSE_MODAL';

export const OPEN_MODAL = 'OPEN_MODAL';

export const closeModalAction = () => {
    return {
        type: CLOSE_MODAL
    }
}

export const openModalAction = (
    modalBody,
    parameters={},
    withBtn=false,
) => {
    return {
        type: OPEN_MODAL,
        body: modalBody,
        //title: title,
        parameters: parameters,
        withBtn: withBtn
    }
}