import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {appAPI} from '../../../API/API'
import Table from './Table/Table';
import s from './Users.module.sass'
import TableItemSeller from './Table/TableItems/TableItemSeller';
import CreateSeller from '../Modals/CreateSeller';

const Sellers = () => {
	const {token} = useSelector((state) => state.User)
	const headers = ['ПВЗ', 'Редактировать', 'Удалить']
	const [sellers, setSellers] = useState([])
	const [forceUpdate, setForceUpdate] = useState(0)
	
	useEffect(() => {
		const fetchData = async () => {
			await appAPI.getSellers(token)
				.then(response => setSellers(response.data))
				.catch(err => {})
			// await appAPI.getShops()
			// 	.then(response => console.log(response))
		}
		fetchData();
	}, [forceUpdate, token])

	return (
		<div className= {s.users}>
			<Table 
				title = 'Кассиры' 
				tableItems = {sellers} 
				headers = {headers} 
				Component = {TableItemSeller} 
				showPlus = {true}
				ComponentCreate = {CreateSeller}
				setState = {setSellers}
				setForceUpdate = {setForceUpdate}
			/>
		</div>
	)
}

export default Sellers;