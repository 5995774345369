import React from 'react'
import s from '../CustomFormRowRecepie.module.sass'
import CustomSelectCourier from '../../CustomSelectCourier/CustomSelectCourier';

const RowPageProduct = React.memo(({img, indexItem, setState, id, showDefault, item, stateForList, subcategory, state, setStateForList, subSubCategories}) => {
    
    const [select, setSelect] = React.useState(subcategory.title)

    React.useEffect(() => {
        setState(prev => prev.map((item, index) => index === indexItem ? {...item, title: select} : item))
        // console.log('indexItem',indexItem, select);
    }, [indexItem, select, setState])


    const addCategory = () => {
        setState(prev => [...prev, {id: Date.now(), title: select}])
    }

    return(
        <div className = {`${s.customRowProducts} ${s.customRowProduct} ${s.customRow}`}>
            {stateForList.length > 0 
                ?   <>
                        <div className = {`${s.customLabel} ${s.label}`} style = {{maxWidth: '250px'}}>
                        <p>Подкатегория товара</p>
                        <CustomSelectCourier
                            placeholder = {subcategory.parentTitle ?? subcategory.title}
                            subplaceholders = {subSubCategories.map(item => item.title)}
                            items = {stateForList.map(item => item.title)}
                            className = {s.address}
                            setState = {setSelect}
                            itemsWithSub = {stateForList}
                            // defaultValue = {item.address}
                        />
                        </div>
                        {img === 'delete' 
                            ? <img className = {s.img} src="/img/adminDel.svg" alt="img" onClick = {() => setState(prev => prev.filter(item => item.id !== id))}/>
                            : <img className = {`${s.img} ${s.plusImg}`} src="/img/adminPlusFull.svg" alt="img" onClick = {addCategory}/>
                        }
                    </>
                : null}
            
            
        </div>
    )
});
export default RowPageProduct;