import React, {useState} from 'react'
import s from './Modals.module.sass'
import {appAPI} from '../../../API/API'
import CustomGallary from '../Common/CustomGallary/CustomGallary';
import RowPageProduct from '../Common/CustomFormRowRecepie/Rows/RowPageProduct';
import { shallowEqualArr } from '../Common/OtherFunctions/OtherFunctions';

const EditProduct = ({item, setState, close, setForceUpdate}) => {

    
    const [formData, setFormData] = useState({
        video: '',
        description: item.description,
        subcategory: item.category,
    });

    const [gallaryImages, setGallaryImages] = useState(item.images)
    const [deletedImages, setDeletedImages] = useState([])
    // eslint-disable-next-line
    const [formCategories, setFormCategories] = useState(
        item.category.length > 0 ? item.category : [{
            id: Date.now(),
            name: ''
    }])
    const [categoriesIDs, setCategoriesIDs] = useState([]);
    // const [formSubCategories, setFormSubCategories] = useState(
    //     item.subcategories && item.subcategories.length > 0 ? item.subcategories : [{
    //         id: Date.now(),
    //         name: ''
    // }])
    const [categories, setCategories] = useState([])
    const [categoriesIsFetching, setCategoriesIsFetching] = useState(true) 
    
    React.useEffect(() => {
        if(item.groups.length > 0 ) {
            const fetchData = () => {
                appAPI.getCatalogByGroup(item.groups[0].id)
                    .then(response => {
                        setCategories(response.data.categories)
                        setCategoriesIsFetching(false)
                    })
                    .catch(err => console.error(err))
            }
            fetchData();
        }
    }, [item.groups])

    React.useEffect(() => {
        let ids = [];
        for (const category of formCategories) {
            if (category.subcategories && category.subcategories.length > 0) {
                for (const sub of category.subcategories) {
                    ids.push({id : sub.id, title : sub.name, isSub: true, parentTitle: category.title})
                }
            } else {
                ids.push({id : category.id, title : category.title, isSub: false})
            }
        }
        setCategoriesIDs(ids);
    }, [formCategories]);

    React.useEffect(() => {
        console.log('categoriesIDs', categoriesIDs);
    }, [categoriesIDs]);

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    const checkCategories = (categories, array) => {
        array = array.map(category => {
            for (let elem of categories) {
                if (elem.subcategories.length > 0) {
                    for (let sub of elem.subcategories) {
                        if (sub.name === category.title) {
                            return sub.id
                        }
                    }
                }
                if (elem.title === category.title) {
                    return elem.id
                }
            }
            return category;
        })
        
        return [...new Set(array)]
    }

    const submitHandler = async (e) => {
        e.preventDefault();

        if(gallaryImages !== item.images || formData.video || formData.description !== item.description || !shallowEqualArr(categoriesIDs, item.category)) {

            await Promise.all(deletedImages.map(id => new Promise((resolve, reject) => {
                appAPI.delProductPhoto(id)
                    .then(() => resolve('ok'))
            })))

            await Promise.all(gallaryImages.map(img => new Promise((resolve, reject) => {
                if(typeof img.url === 'object') {
                    const data = new FormData();
                    data.append('file', img.url)
                    appAPI.createProductPhotos(item.id, data)
                        .then(() => resolve('ok'))
                } else resolve('ok')
            })))

            try {
                await appAPI.editProduct(item.id, {description: formData.description})
                await appAPI.createCategories(item.id, checkCategories(categories, categoriesIDs))
            } catch (err) {
                console.log(err);
            }

            setForceUpdate(prev => prev + 1);
            close();

        } else alert('Все поля совпадают с предыдущим значением')
    }

    return(
        <>
        {!categoriesIsFetching        
        ?   <div className={`${s.modalAdmin} ${s.modalWide}`}>
                <form className={`${s.formAdmin} ${s.formWide}`} onSubmit={submitHandler}>

                    <h2 className = {s.title}>{item.name}</h2>

                    {/* <div className = {`${s.labelImg} ${s.label}`}>
                        <p>Главное изображение</p>
                        <label>
                            <p>
                                <img src="/img/screpka.svg" alt="img"/>
                                <span>Загрузите фото</span>
                            </p>
                            <input type="file" style = {{display: 'none'}} multiple accept="image/*" onChange = {changeHandlerFile}/>
                        </label>        
                        {formData.img 
                            ? <img src = {URL.createObjectURL(formData.img)} className = {s.imgWide}/> 
                            : <img src = {`${apiURL}${item.images[0].url}`} className = {s.imgWide}/>
                        }            
                        
                    </div> */}
                        
                    <CustomGallary
                        state = {gallaryImages}
                        setState = {setGallaryImages}
                        stateDelete = {deletedImages}
                        setStateDelete = {setDeletedImages}
                        title = 'Изображения'
                    />

                    {/* <label className = {`${s.label}`}>
                        <p>Ссылка на видео</p>
                        <input 
                            type="text"
                            name = 'video'
                            onChange={changeHandler}
                            className = {s.input}
                            defaultValue = {formData.video}
                        />
                    </label> */}

                    <label className = {`${s.label}`}>
                        <p>Описание товара</p>
                        <textarea 
                            name = 'description'
                            onChange={changeHandler}
                            className = {s.input}
                            defaultValue = {formData.description}
                        />
                    </label>
                    
                    <div>
                        {categoriesIDs.map((category, index) => 
                            <RowPageProduct 
                                setState = {setCategoriesIDs}
                                id = {category.id}
                                key = {category.id}
                                stateForList = {categories}
                                setStateForList = {setCategories}
                                subcategory = {category}
                                img = {categoriesIDs.length === index + 1  ? 'plus' : 'delete'}
                                state = {categoriesIDs}
                                indexItem = {index}
                                subSubCategories = {category.isSub ? [category] : []}
                            />
                        )}
                        {/* {formCategories.map((category, index) => {
                            
                            if(category.subcategories && category.subcategories.length > 0){
                                return category.subcategories.map((subcategory, subindex) => {
                                    commonIndex++;
                                    return <RowPageProduct 
                                        setState = {setFormCategories}
                                        id = {category.id}
                                        key = {category.id}
                                        stateForList = {categories}
                                        setStateForList = {setCategories}
                                        subcategory = {category}
                                        img = {formCategories.length === commonIndex - 1 ? 'plus' : 'delete'}
                                        state = {formCategories}
                                        indexItem = {commonIndex - 1}
                                        subSubCategories = {[subcategory]}
                                    />}
                                )
                            } else {
                                commonIndex++;
                                return <RowPageProduct 
                                    setState = {setFormCategories}
                                    id = {category.id}
                                    key = {category.id}
                                    stateForList = {categories}
                                    setStateForList = {setCategories}
                                    subcategory = {category}
                                    img = {formCategories.length === commonIndex - 1 ? 'plus' : 'delete'}
                                    state = {formCategories}
                                    indexItem = {commonIndex - 1}
                                    subSubCategories = {[]}
                                />
                            }
                        })} */}
                    </div>
                    
                    <button type = 'submit' className={`button ${gallaryImages !== item.images || formData.video || formData.description !== item.description || !shallowEqualArr(categoriesIDs, item.category) ? 'btn_red' : ''}`}>Сохранить</button>
                </form>
        
            </div>
        : null}
        </>
    )
};

export default React.memo(EditProduct)
