import React from 'react'
import s from './Menu.module.sass'
import { NavLink } from 'react-router-dom'

const Menu = () => {
	return (
		<div className={s.menu}>
			<img className = {s.logo} src="/img/adminLogo.svg" alt="logo"/>
			<div className={s.links}>
				<NavLink to="/slider" className={s.links__item} activeClassName={s.active}>
					<p>Слайдер</p>
				</NavLink>
				<NavLink to="/products-recommend" className={s.links__item} activeClassName={s.active}>
					<p>Рекомендации</p>
				</NavLink>
				<NavLink to="/products-new" className={s.links__item} activeClassName={s.active}>
					<p>Новинки</p>
				</NavLink>
				<NavLink to="/products" className={s.links__item} activeClassName={s.active}>
					<p>Товары</p>
				</NavLink>
				<NavLink to="/shops" className={s.links__item} activeClassName={s.active}>
					<p>Магазины</p>
				</NavLink>
				<NavLink to="/recepies" className={s.links__item} activeClassName={s.active}>
					<p>Рецепты</p>
				</NavLink>

				<NavLink to="/delivery" className={s.links__item} activeClassName={s.active}>
					<p>Доставка</p>
				</NavLink>

				<NavLink to="/vacancies" className={s.links__item} activeClassName={s.active}>
					<p>Вакансии</p>
				</NavLink>

				<NavLink to="/groups" className={s.links__item} activeClassName={s.active}>
					<p>Категории</p>
				</NavLink>

				<hr/>

				<NavLink to="/operators" className={s.links__item} activeClassName={s.active}>
					<p>Операторы</p>
				</NavLink>

				<NavLink to="/couries" className={s.links__item} activeClassName={s.active}>
					<p>Курьеры</p>
				</NavLink>

				<NavLink to="/sellers" className={s.links__item} activeClassName={s.active}>
					<p>Кассиры</p>
				</NavLink>

				<NavLink to="/service" className={s.links__item} activeClassName={s.active}>
					<p>Управление пользователями</p>
				</NavLink>

				<hr/>

				<NavLink to="/orders" className={s.links__item} activeClassName={s.active}>
					<p>Все заказы</p>
				</NavLink>
			</div>
		</div>
		

	)
}



export default Menu;
