import React, {useState} from 'react'
import s from './Modals.module.sass'
import {useSelector} from 'react-redux'
import {appAPI, apiURL} from '../../../API/API'
import CustomFormRowRecepie from '../Common/CustomFormRowRecepie/CustomFormRowRecepie';
import RowIngredients from '../Common/CustomFormRowRecepie/Rows/RowIngredients'
import RowSteps from '../Common/CustomFormRowRecepie/Rows/RowSteps';
import RowProducts from '../Common/CustomFormRowRecepie/Rows/RowProducts';

const EditRecepie = ({item, setState, close, setForceUpdate}) => {

    const {token} = useSelector(state => state.User)
    const formRef = React.useRef(null)
    
    const inputFile = React.useRef(null)

    const [formData, setFormData] = useState({
        img: '',
        cookingTime: item.cookingTime,
        portions: item.portions,
        video: item.video,
        name: item.name,
        category: item.category
    })

    const [ingredients, setIngredients] = useState(item.ingredients.length > 0 ? item.ingredients : [{
        amount: null,
        name: '',
        unit: '',
        id: Date.now()
    }])

    const [deletedSteps, setDeletedState] = useState([])
    const [validationFlag, setValidationFlag] = useState(false)
    const [steps, setSteps] = useState(item.steps.length > 0 ? item.steps : [{
        description: '',
        img: '',
        id: Date.now()
    }])

    const [products, setProducts] = useState(() => item.products.length > 0 ? item.products : [{amount: null, name: '', unit: '', id: Date.now()}])
    
    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
        console.log(formData)
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if(formData.img || formData.cookingTime !== item.cookingTime || formData.portions !== item.portions || formData.video !== item.video || formData.name !== item.name || formData.category !== item.category|| validationFlag) {
            // const recepie = await appAPI.editRecepie({name: formData.name, cookingTime: formData.cookingTime, portions: parseInt(formData.portions), video: formData.video}, item.id)
            if(typeof formData.img === 'object') {
                const data = new FormData();
                data.append('file', formData.img)
                await appAPI.createRecepiePhoto(data, item.id, token)
            }
            await appAPI.editRecepieIngredients(ingredients.filter(item => item.amount).map(ingredient => ({...ingredient, productId: ingredient.id})), item.id)

            await Promise.all(steps.map((step, index) => new Promise((resolve, reject) => {
                const data = new FormData();
                data.append('description', step.description)
                data.append('index', index)
                if(typeof step.img === 'object') {
                    data.append('file', step.img ?? step.image)
                }
                console.log(step)
                if(step.hasOwnProperty('localId')) {
                    appAPI.editRecepiesStep(data, step.id).then(() => resolve('ok'))
                } else if(step.description && step.img) {
                    console.log('here')
                    appAPI.createRecepiesStep(data, item.id).then(() => resolve('ok'))
                }
                    else resolve('ok')
            })))

            await Promise.all(deletedSteps.map(step => new Promise((resolve, reject) => {
                appAPI.delRecepiesStep(step)
                .then(() => resolve('ok'))
            })))
            await appAPI.createRecepiesProducts(products.map(product => product.productId ?? product.id), item.id)
            await appAPI.editRecepie({name:formData.name, cookingTime:formData.cookingTime, portions:formData.portions, video:formData.video, category:formData.category}, item.id)

            setForceUpdate(prev => prev + 1)
            close();

        } else alert('Все поля совпадают с предыдущим значением')
    }

    const changeHandlerFile = () => {
        if (inputFile.current.files && inputFile.current.files[0]) setFormData(prev => ({...prev, img: inputFile.current.files[0]}))
    }
    
    return(
        <div className={`${s.modalAdmin} ${s.modalWide}`}>
            <form className={`${s.formAdmin} ${s.formWide}`} onSubmit={submitHandler} ref = {formRef}>
                <label className = {`${s.label}`}>
                    <p>Название рецепта</p>
                    <input 
                        type="text"
                        name = 'name'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.name}
                    />
                </label>
                <label className={`${s.label}`}>
                    <p>Категория</p>
                    <select
                        className={s.input}
                        name='category'
                        onChange={changeHandler}
                        defaultValue={formData.category}
                    >
                        <option value={'Рыба'}>Рыба</option>
                        <option value={'Морепродукты'}>Морепродукты</option>
                        <option value={'Икра'}>Икра</option>
                    </select>
                </label>
                <div className = {`${s.labelImg} ${s.label}`}>
                    <p>Главное изображение</p>
                    <label>
                        <p>
                            <img src="/img/screpka.svg" alt="img"/>
                            <span>Загрузите фото</span>
                        </p>
                        <input type="file" ref = {inputFile} style = {{display: 'none'}} multiple accept="image/*" onChange = {changeHandlerFile}/>
                    </label>
                    {formData.img 
                        ? <img alt="" src = {URL.createObjectURL(formData.img)} className = {s.imgWide}/> 
                        : item.image ? <img alt="" src = {`${apiURL}${item.image}`} className = {s.imgWide}/> : null}
                </div>
                <div className = {s.formBlock}>
                    <label className = {`${s.label} ${s.labelSmall}`}>
                        <p>Время приготовления:</p>
                        <input 
                            type="text"
                            name = 'cookingTime'
                            onChange={changeHandler}
                            className = {s.input}
                            defaultValue = {formData.cookingTime}
                        />
                    </label>
                    <label className = {`${s.label} ${s.labelSmall}`}>
                        <p>Количество порций:</p>
                        <input 
                            type="number"
                            name = 'portions'
                            onChange={changeHandler}
                            className = {s.input}
                            defaultValue = {formData.portions}
                        />
                    </label>
                </div>
                <label className = {`${s.label}`}>
                    <p>Ссылка на видео</p>
                    <input 
                        type="text"
                        name = 'video'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.video}
                    />
                </label>
                <CustomFormRowRecepie 
                    title = 'Ингридиенты:' 
                    state = {ingredients}
                    setState = {setIngredients}
                    Component = {RowIngredients}
                    setDeletedState = {setDeletedState}
                    showDefault = {true}
                    setValidationFlag = {setValidationFlag}
                />
                <CustomFormRowRecepie 
                    title = 'Рецепт:' 
                    state = {steps}
                    setState = {setSteps}
                    setDeletedState = {setDeletedState}
                    Component = {RowSteps}
                    showDefault = {true}
                    setValidationFlag = {setValidationFlag}
                />
                <CustomFormRowRecepie
                    title = 'Продукты из этого рецепта:'
                    state = {products}
                    setState = {setProducts}
                    Component = {RowProducts}
                    showDefault = {true}
                    setValidationFlag = {setValidationFlag}
                />
                <button type = 'submit' className={`button ${formData.img || formData.cookingTime !== item.cookingTime || formData.portions !== item.portions || formData.video !== item.video || formData.name !== item.name || validationFlag || formData.category !== item.category ? 'btn_red' : ''}`}>Сохранить</button>
            </form>
        </div>
    )
}

export default React.memo(EditRecepie);
