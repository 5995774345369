import React from 'react'
import s from '../Table.module.sass'
import {useDispatch} from 'react-redux'
import {openModalAction} from '../../../../../Actions/ModalActions'
import {appAPI, apiURL} from '../../../../../API/API'
import EditSlider from '../../../Modals/EditSlider';

const TableItemSlider = ({setState, item, setForceUpdate}) => {

    const dispatch = useDispatch();
    const delCourierFunc = async (id) => {
        await appAPI.delSlide(id)
            .then(() => setState(prev => prev.filter(slide => slide.id !== id)))
            .catch(err => alert(err.response.data.message))
    }

    return(
        <tr className="table-item">
            <td className = {s.imgTable}><img src={`${apiURL}${item.image}`} alt=""/></td>
            <td>{item.slideText}</td>
            <td>{item.buttonText}</td>
            <td>{item.link}</td>
            <td>
                <img className = {s.imgScale} src="/img/adminRed.svg" alt="img" onClick = {() => dispatch(openModalAction(EditSlider, {item, setForceUpdate}))}/>
            </td>
            <td>
                <img className = {s.imgScale} src="/img/adminDel.svg" alt="img" onClick = {() => delCourierFunc(item.id)}/>
            </td>
        </tr>
    )
}

export default TableItemSlider;