import React from 'react'
import s from '../Table.module.sass'
import {openModalAction} from '../../../../../Actions/ModalActions'
import {apiURL} from '../../../../../API/API'
import {useDispatch} from 'react-redux'
import EditProduct from '../../../Modals/EditProduct';

const TableItemProduct = React.memo(({item, setState, setForceUpdate}) => {

    const dispatch = useDispatch();

    return(
        <tr className="table-item">
            <td className = {s.imgTable}><img alt="" src = {item.images[0] ? `${apiURL}${item.images[0].url}` : ''}/></td>
            <td>{item.name}</td>
            <td>{item.groups.length > 0 && item.groups[0].name}</td>
            <td>{item.category.length > 0 && item.category.map((category, index) => index !== item.category.length - 1 ? category.title + ', ' : category.title)}</td>
            <td>
                <img className = {s.imgScale} src="/img/adminRed.svg" alt="img" onClick = { () => dispatch(openModalAction(EditProduct, {setState, item, setForceUpdate}))}/>
            </td>
        </tr>
    )
});

export default TableItemProduct;