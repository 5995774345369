import React, {useState, useEffect, useRef} from "react";
import SellerProduct from "./SellerProduct";
import {openModalAction} from "../../Actions/ModalActions";
import {changeSellerStatus} from '../../Actions/OrderActions'
import Utochnenie from '../../Components/Modals/Utochnenie'
import {useDispatch} from 'react-redux'
import './Seller.sass'
import {useDebounce} from "../../API/useDebounce";
import {appAPI} from '../../API/API'

const SellerScan = ({order, close, forceUpdate, setActiveOrder, setScanReady}) => {
    const dispatch = useDispatch();
    const [currentArticle, setArticle] = useState('');
    const articleInput = useRef(null);
    const minArticleWidth = 7;

    const getWeightProductArticle = (article) => {
        const arr = article.split('');
        let oddSum = 0;
        let evenSum = 0;
        arr.forEach((num, index) => {
            if ((index + 1) % 2 === 0) evenSum += +num
            else oddSum += +num
        })
        let del = (evenSum * 3 + oddSum) > 99 ? 100 : 10;
        return article + (10 - (evenSum * 3 + oddSum) % del);
    }

    const [scanCount, increaseCount] = useState([...order.products.map(item => {
        let article = [];
        let identifier = [];
        console.log(item)
        if (!Array.isArray(item.product.identifier)) {
            identifier.push(item.product.identifier);
        } else {
            identifier = item.product.identifier;
        }
        if (item.product.unit === 'кг') {
            identifier.map((el) => {
                article.push(getWeightProductArticle(el + (item.product.weight/100).toFixed(5).toString().slice(2)));
                return el;
            })
        } else {
            article = identifier;
        }
        return {
            type: item.product.unit,
            weight: item.product.weight,
            article: article,
            amount: item.product.id === "17266d47-4b41-11eb-ab51-a4bf01485720" ? Number(item.amount) : 0,
            productId: item.id
        }
    })]);

    const debouncedSetArticle = useDebounce(currentArticle, 50);

    useEffect(() => {
        setArticle('');
    }, [scanCount])

    useEffect(() => {
        if (debouncedSetArticle) {
            const minArticle = debouncedSetArticle.slice(0, minArticleWidth);
            const indexes = [];

            let pos = -1;
            while ((pos = debouncedSetArticle.indexOf(minArticle, pos + 1)) !== -1) {
               indexes.push(pos);
            }

            const currentDebounceArticle = (indexes.length === 1 ? debouncedSetArticle : debouncedSetArticle.slice(0, indexes[1])).trim();
            increaseCount([...scanCount.map((product, i) => product.article.find(item => {
                console.log('scan', currentDebounceArticle, item, item.includes(currentDebounceArticle))
                return (
                product.type === 'кг' 
                ?
                item.includes(currentDebounceArticle.slice(2, 7))
                :
                item.includes(currentDebounceArticle)
            )})
                ? {...product, 
                    amount: product.amount + (
                        product.type === 'кг' 
                        ? 
                        product.amount + parseInt(currentDebounceArticle.slice(7,12)) / 1000 <= product.weight * order.products[i].amount ? parseInt(currentDebounceArticle.slice(7,12)) / 1000 : 0
                        : 
                        indexes.length + product.amount <= order.products[i].amount ? indexes.length : 0
                        )
                } 
                : product
            )]);
        }
        else return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSetArticle])

    useEffect(() => {
        articleInput.current.focus();
    }, [currentArticle])

    const clickReady = async () => {
        console.log('checkReady', checkReady());
        if (checkReady()) {
            let newArr = scanCount.map(product => ({amount: product.amount, productId: product.productId}))

            await appAPI.postScanned( order.id, newArr)
            .then(() => {
                dispatch(changeSellerStatus(order.id, 'READY'))
                close()
                setScanReady(true)
            })
            .catch(err => console.log(err))
        }
    }

    const checkReady = () => {
        return (!scanCount.find(product => product.amount === 0)
        &&
        !scanCount.find((product, i) => product.type !== "кг" ? product.amount < order.products[i].amount : product.amount < product.weight * order.products[i].amount))
    }

    const checkDecrement = () => {
        return !scanCount.find(product => product.amount !== 0)
    }
    
    const decrementProducts = id => {
        appAPI.patchRemoveNotScanned(id, scanCount.map(item => ({amount: item.amount, productId: item.productId})))
            .then(() => {forceUpdate(); close()})
            .catch( err => console.log(err))
    }

    return (
        <div className="seller-item seller-item_scan" onClick={() => articleInput.current.focus()}>
            <div className="close-modal__wrapper">
                <img src="/img/closeRed.svg" className="close-modal" alt="close" onClick={() => close()}/>
            </div>
            <input
                ref={articleInput}
                className="article_input"
                type="text"
                value={currentArticle}
                onChange={(e) => {
                    // console.log("e.target.value", e.target.value)
                    setArticle(e.target.value)
                }}
            />
            <h1>Сканирование товаров. Заказ № {order.id}</h1>
            <div className="seller-scan-header">
                <div>
                    <h2>Заказанно</h2>
                    <h2>Отсканированно</h2>
                </div>

                <h2 className='status'>Статус</h2>
            </div>
            <div className="seller-item-products">
                {order.products.map((item, i) =>
                    <SellerProduct
                        scanCount={scanCount[i].amount}
                        amount = {item.product.unit === 'кг' ? item.amount * item.product.weight : item.amount}
                        key = {item.id}
                        article = {scanCount[i].article}
                        product = {item.product}
                        resetScanCount = {(article) => increaseCount([...scanCount.map(item => item.article === article ? {...item, amount: 0} : item)])}
                        activeModal = 'scan'
                    />
                )}

            </div>
            <div className="seller-item-footer seller-item-footer_scan">
                <button 
                    className="button btn_blue"
                    onClick={() => {
                        dispatch(openModalAction(
                            Utochnenie,
                            {phone: order.phone, order, scanCount, forceUpdate, setActiveOrder }
                        ))
                    }}

                >Показать телефон получателя</button>
                <button className = {`button btn_red btn_modal${checkDecrement() ? ' disabled' : ''}`} disabled={checkDecrement()} onClick = { () => decrementProducts(order.id)}>Вычесть не пробитые товары</button>
                <button className={`button btn_red${!checkReady() ? ' disabled' : ''}`} disabled={!checkReady()} onClick={clickReady}>Готово</button>
            </div>
        </div>
    )
}

export default SellerScan;