import React, {useEffect, useState} from 'react'
import s from './Modals.module.sass'
import {appAPI} from '../../../API/API'
import CustomSelectCourier from '../Common/CustomSelectCourier/CustomSelectCourier';

const EditSeller = ({item, setState, close, setForceUpdate}) => {

    const [formData, setFormData] = useState({
        login: item.login,
        password: '',
    })
    const [data, setData] = useState({
        shops: [],
        isFetching: true
    })

    const strAddress = item.shop ? (item.shop.city + ',' + item.shop.street) : '';
    const [address, setAddress] = useState(strAddress)

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    useEffect(() => {

        const fetchData = async () => {
            try {  
                const response = await appAPI.getShopsWithoutSellers()
                setData({shops: response.data.filter(shop => shop.city), isFetching: false})
            } catch(err) {alert.log(err)}
        }
        fetchData();
        
    }, [])


    const submitHandler = async (e) => {
        e.preventDefault();
        if(formData.login !== item.login || address !== strAddress || formData.password) {
            await appAPI.editSeller({...formData, shopId: address !== strAddress ? data.shops.find(shop => `${shop.city} ${shop.street && ', ' + shop.street} ${shop.building && ', ' + shop.building}` === address).id : ''}, item.id)
                .then(response => {
                    setForceUpdate(prev => prev + 1)
                    close();
                })
                .catch(err => console.log(err))
          
        } else alert('Все поля совпадают с предыдущим значением')
    }

    return(
        <div className={s.modalAdmin} style = {{minHeight: '251px'}}>
            {data.isFetching 
                ? null
                :
            <form className={s.formAdmin} onSubmit={submitHandler}>
                <label className = {`${s.label}`}>
                    <p>Логин</p>
                    <input 
                        type="text"
                        name = 'login'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.login}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Пароль</p>
                    <input 
                        type="password"
                        name = 'password'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                {data.shops.length > 0 
                    ?   <div className = {`${s.customLabel} ${s.label}`}>
                            <p>ПВЗ</p>
                            <CustomSelectCourier
                                placeholder = {strAddress}
                                items = {data.shops.map(item => `${item.city} ${item.street && ', ' + item.street} ${item.building && ', ' + item.building}`)}
                                className = {s.address}
                                setState = {setAddress}
                                defaultValue = {formData.address}
                            />
                        </div>
                    :   null
                }
                <button type = 'submit' className={`button ${formData.login !== item.login || address !== strAddress || formData.password ? s.btn_red : ''}`}>Сохранить</button>
            </form>
        } 
        </div>
    )
}

export default EditSeller;
