import React from 'react';
import Table from '../Users/Table/Table';
import {appAPI} from '../../../API/API'
import TableItemNew from '../Users/Table/TableItems/TableItemNew';

const NewProducts = () => {

    const headers = ['', 'Заголовок', 'Редактировать']
    const [products, setProducts] = React.useState([])
    // eslint-disable-next-line
    const [forceUpdate, setForceUpdate] = React.useState(0)
    
    React.useEffect(() => {
        const fetchData = async () => {
            await appAPI.getNewProducts()
                .then(response => setProducts(response.data))
                .catch(error => console.error(error))
        }
        fetchData();
    }, [])
    
    return(
        <div >
            <Table
                title = 'Новинки'
                tableItems = {products}
                headers = {headers}
                Component = {TableItemNew}
                setForceUpdate = {setForceUpdate}
                setState = {setProducts}
            />
        </div>
    )   
}

export default NewProducts;