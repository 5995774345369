import React, {useState} from 'react';
import './Operator.sass'
import Orders from './Orders/Orders';
import Requests from './Requests/Requests';
import {useDispatch, useSelector} from 'react-redux'
import {logoutUser} from '../../Actions/UserActions'
import {resetOrders} from '../../Actions/OrderActions'
import { appAPI } from '../../API/API';
import { useEffect } from 'react';

const Operator = ({showInfo}) => {

    const dispatch = useDispatch();
    const {token} = useSelector(state => state.User)
    const [activeFolder, setActiveFolder] = useState(0) 
    const [user, setUser] = useState({})


    useEffect(() => {
        const fetchData = async () => {
            console.log('here')
            await appAPI.getProfile(token)
                .then((response) => setUser(response))
        }
        fetchData();
    }, [token])

    return(
        <div className="page page_operator">
            {showInfo ? <h1>Личный кабинет оператора: {user.username}</h1> : null}
            <ul className="operator-menu">
                <li 
                    onClick = { () => setActiveFolder(0) }
                    className = {`${activeFolder === 0 ? 'active' : ''}`}
                >
                    <img src="/img/cart.svg" alt="img"/>
                    <p>Заказы</p>
                </li>
                <li 
                    onClick = { () => setActiveFolder(1) }
                    className = {`${activeFolder === 1 ? 'active' : ''}`}
                >
                    <img src="/img/message.svg" alt="img"/>
                    <p>Обращения</p>
                </li>
            </ul>
            <div className= 'page_operator-content'>
                {activeFolder === 0 && <Orders/> }
                {activeFolder === 1 && <Requests/> }
            </div>
            {showInfo 
                ? <button className = 'button btn_logout' onClick = { () => { dispatch(resetOrders); dispatch(logoutUser);  }}>Выйти</button>
                : null
            }
        </div>  
    )

}

export default Operator;