import React, {useState} from 'react'
import s from './Modals.module.sass'
import {appAPI} from '../../../API/API'

const CreateSlider = ({setState, close, setForceUpdate, index}) => {

    const [formData, setFormData] = useState({
        slideText: '',
        buttonText: '',
        buttonLink: '',
        img: ''
    })

    const inputFile = React.useRef(null)

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if(formData.slideText && formData.buttonText && formData.buttonLink && formData.img) {
            const data = new FormData();
            data.append('data', JSON.stringify({link: formData.buttonLink, localId: index, buttonText: formData.buttonText, slideText: formData.slideText}))
            data.append('file', formData.img)
            await appAPI.createSlide(data)
                .then(() => {
                    setForceUpdate(prev => prev + 1)
                    close();
                })
                .catch(err => console.log(err))
        } else alert('Введены не все данные')
    }

    const changeHandlerFile = () => {
        if (inputFile.current.files && inputFile.current.files[0]) setFormData(prev => ({...prev, img: inputFile.current.files[0]}))
    }

    return(
        <div className={`${s.modalAdmin} ${s.modalWide}`}>
            <form className={`${s.formAdmin} ${s.formWide}`} onSubmit={submitHandler}>
                <div className = {`${s.labelImg} ${s.label}`}>
                    <p>Изображение</p>
                    <label>
                        <p>
                            <img src="/img/screpka.svg" alt="img"/>
                            <span>Загрузите фото</span>
                        </p>
                        <input type="file" ref = {inputFile} style = {{display: 'none'}} multiple accept="image/*" onChange = {changeHandlerFile}/>
                    </label>
                    {formData.img ? <img alt="" src = {URL.createObjectURL(formData.img)} className = {s.imgWide}/> : null}
                </div>
                <label className = {`${s.label}`}>
                    <p>Текст слайда</p>
                    <input 
                        type="text"
                        name = 'slideText'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Текст кнопки</p>
                    <input 
                        type="text"
                        name = 'buttonText'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Ссылка кнопки</p>
                    <input 
                        type="text"
                        name = 'buttonLink'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <button type = 'submit' className={`button btn_red`}>Создать</button>
            </form>
        </div>
    )
}

export default CreateSlider;
