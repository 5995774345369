import { appAPI, setTokenForAPI } from '../API/API';
import Cookies from 'js-cookie';

const LOGIN_USER = 'LOGIN_USER';
const LOGOUT_USER = 'LOGOUT_USER';
const FETCHED = 'USER/FETCHED';
const CHECK_AUTH = 'USER/CHECK_AUTH';
const SET_TOKEN = 'USER/SET_TOKEN';
const USER_DATA = 'USER_DATA';
const SET_MODAL = 'SET_MODAL'


const toggleFetch = (status, msg = null) => {
  return {
    type: FETCHED,
    fetched: status,
    error: msg,
  };
};
const checkAuthToggle = (status) => {
  return {
    type: CHECK_AUTH,
    status,
  };
};
const setSuccessToken = (token) => {
  return {
    type: SET_TOKEN,
    token,
  };
};

const setToken = (token) => async (dispatch) => {
  dispatch(setSuccessToken(token));
  setTokenForAPI(token);
};

const CheckAuth = () => (dispatch) => {
  const token = Cookies.get('tokenStaff');
  const username = Cookies.get('username');
  if (token && typeof token !== 'undefined' && token !== 'undefined') {
    dispatch(setToken(token));
    dispatch({
      type: LOGIN_USER,
      token,
      username,
      isAuth: true,
    });
  }
  dispatch(checkAuthToggle(true));
};

const loginUser = (username, password, userRole) => async (dispatch) => {
  dispatch(toggleFetch(true));
  try{
    let data = await appAPI.loginUser(username, password, userRole);
    let token = data.authorizationToken;
    localStorage.clear()
    if (token) {
      localStorage.setItem('userRole', userRole)
      Cookies.set('tokenStaff', token, {});
      await dispatch(CheckAuth());
    }
  } catch(err) {
    alert('Неверный логин или пароль')
  }

};

const logoutUser = async (dispatch) => {
  Cookies.remove('tokenStaff');
  localStorage.removeItem('userRole');
  window.location.reload();
  await dispatch({
    type: LOGOUT_USER,
  });
};

const setModal = (activeModal) => {
  return{
    type: SET_MODAL,
    activeModal
  }
}
const userData = (role, phone, email, birthday) => {
  return {
    type: USER_DATA,
    role,
    phone,
    email,
    birthday
  }
}

export { LOGIN_USER, LOGOUT_USER, FETCHED, CHECK_AUTH, USER_DATA, SET_MODAL };

export { loginUser, logoutUser, CheckAuth, userData, setModal };
