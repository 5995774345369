import React from 'react'
import s from './CustomFormRowRecepie.module.sass'
const CustomFormRowRecepie = ({title, placeholders, state, setState, setDeletedState, Component, showDefault, setNewState, setValidationFlag}) => {

    const sortState = state => {
        return state[0].localId ? state.sort((prev, next) => prev.localId < next.localId ? -1 : 1) : state
    }
    return(
        <div className = {s.wrapper}>
            <p>{title}</p>
            <div className = {s.rows}>
                {showDefault 
                    ?   sortState(state).map((row, index) => 
                        <Component 
                            img = {index !== state.length - 1 ? 'delete' : 'plus'}
                            state = {state}
                            setState = {setState}
                            setDeletedState = {setDeletedState}
                            setNewState = {setNewState}
                            placeholders = {placeholders}
                            setValidationFlag = {setValidationFlag}
                            showDefault = {showDefault}
                            key = {row.id}
                            id = {row.id}
                            row = {row}
                        />)

                    : state.map((row, index) => 
                    <Component 
                        img = {index !== state.length - 1 ? 'delete' : 'plus'}
                        state = {state}
                        setState = {setState}
                        setValidationFlag={setValidationFlag}
                        placeholders = {placeholders}
                        showDefault = {showDefault}
                        key = {row.id}
                        id = {row.id}
                        row = {row}
                    />)
                }
            </div>
        </div>
    )
}

export default React.memo(CustomFormRowRecepie);