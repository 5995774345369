import React, {useState} from 'react'
import s from './Modals.module.sass'
import {appAPI} from '../../../API/API'
import InputMask from 'react-input-mask';

const CreateVacancies = ({setState, close, setForceUpdate}) => {

    const [formData, setFormData] = useState({
        name: '',
        address: '',
        phone: '',
        salaryFrom: '',
        salaryTo: '',
    })

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if(formData.name && formData.address && formData.phone && formData.salaryFrom && formData.salaryTo) {
            await appAPI.createVacancies({...formData, salaryFrom: parseInt(formData.salaryFrom), salaryTo: parseInt(formData.salaryTo)})
                .then(response => {
                    setForceUpdate(prev => prev + 1)
                    close();
                })
                .catch(err => console.log(err))
        } else alert('Введены не все данные')
    }

    return(
        <div className={s.modalAdmin}>
            <form className={s.formAdmin} onSubmit={submitHandler}>
                <label className = {`${s.label}`}>
                    <p>Заголовок</p>
                    <input 
                        type="text"
                        name = 'name'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Адрес</p>
                    <input 
                        type="text"
                        name = 'address'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Телефон</p>
                    <InputMask
                        mask = '+7 999 999 99 99'
                        name = 'phone'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>ЗП от</p>
                    <input 
                        type="number"
                        name = 'salaryFrom'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>ЗП до</p>
                    <input 
                        type="number"
                        name = 'salaryTo'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <button type = 'submit' className={`button btn_red`}>Создать</button>
            </form>
        </div>
    )
}

export default CreateVacancies;
