import React, {useState, useRef, useEffect} from 'react'
import Order from './Order'
import './Orders.sass'
import {openModalAction} from '../../../Actions/ModalActions'
import ChangeOrder from '../../../Components/Modals/ChangeOrder'
import {useDispatch, useSelector} from 'react-redux'
import {appAPI} from '../../../API/API'
import {setOrders, delOrder} from '../../../Actions/OrderActions'


const Orders = () => {

    const [activeAccordion, setActiveAccordion] = useState(null)
    const [activeFilter, setActiveFilter] = useState('ACTIVE')
    const [openProducts, setOpenProducts] = useState(false)
    const {token} = useSelector((state) => state.User)
    const {orders, initialOrders} = useSelector((state) => state.Order)
    const [cancelFilter, setCancelFilter] = useState(false)

    const dispatch = useDispatch();
    const inputSearch = useRef(null)

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try{
                const response = await appAPI.getOrders(token);
                if(isMounted) dispatch(setOrders(response.data, response.data))
            } catch (err){
            }
        }
        fetchData()
        return () => isMounted = false;
    }, [dispatch, token])
    

    const deleteOrder = async (id) => {
        try {
            await appAPI.rejectedOrder(id)
            dispatch(delOrder(id))
        } catch (err) {
            console.error(err)
        }

    }

    const filtrarion = (arr, filter) => {
        if(filter === null) { return arr } else return arr.slice().filter(el => el.orderStatus === filter)
    }

    const onSearch = (value) => { 
        value && setCancelFilter(true)
        dispatch(setOrders(initialOrders.slice().filter(order => order.id.includes(value))))
    }

    

    return(
        <div className="operator-orders">
            <div className="operator-orders__header">
                <div className="operator-orders__header-left">
                    <button 
                        className={`button ${activeFilter === 'ACTIVE' ? 'btn_blue' : 'btn_grey'}`}
                        onClick = { () => setActiveFilter('ACTIVE')}
                    >Активные</button>
                    <button 
                        className={`button ${activeFilter === 'FINISHED' ? 'btn_blue' : 'btn_grey'}`}
                        onClick = { () => setActiveFilter('FINISHED')}
                    >Архивированные</button>
                </div>
                <div className="operator-orders__header-right">
                    <div>
                        <input 
                            ref = {inputSearch}
                            type="text"
                            placeholder="Поиск заказа..."
                            onKeyUp = { e => e.keyCode === 13 && onSearch(e.currentTarget.value)}
                        />
                        {cancelFilter 
                            ? <img 
                                src = '/img/closeRed.svg' 
                                alt ="img" 
                                className='img-search img-search_close'
                                onClick = { () => {
                                    dispatch(setOrders(initialOrders));
                                    inputSearch.current.value = ''
                                    setCancelFilter(false);
                                }}/>
                            : <img 
                                src="/img/search.svg" 
                                className = 'img-search' 
                                alt="img" 
                                onClick = { () => onSearch(inputSearch.current.value)}
                            />
                        }
                       
                    </div>
                    <img src="/img/sort.svg" alt="sort" onClick = {
                        () => dispatch(openModalAction(ChangeOrder))
                    }/>
                </div>
            </div>
            <ul className="operator-orders__accordion">
                {filtrarion(orders, activeFilter).map(order => <Order
                    order = {order}
                    activeAccordion = {activeAccordion}
                    setActiveAccordion = {setActiveAccordion}
                    openProducts = {openProducts}
                    setOpenProducts = {setOpenProducts}
                    id = {order.id}
                    key = {order.id}
                    deleteOrder = {deleteOrder}
                />)}
            </ul>
        </div>
    )

}

export default Orders;