import React, {useState} from 'react';
import s from './CustomSelectCourier.module.sass'
import sm from '../../Modals/Modals.module.sass'
import AnimateHeight from 'react-animate-height';

const CustomSelectCourier = React.memo(({placeholder, subplaceholders, items, className, state, setState, itemsWithSub}) => {

    const [activeItem, setActiveItem] = useState( placeholder)
    const [subActiveItem, setSubActiveItem] = useState(subplaceholders && subplaceholders[0] ? subplaceholders[0] : null)
    const [height, setHeight] = useState(0)

    console.log('activeItem', activeItem, subActiveItem);

    React.useEffect(() => {setState(activeItem);}, [activeItem, setState]);
    React.useEffect(() => {if (subActiveItem) setState(subActiveItem);}, [setState, subActiveItem]);


    return(
        <div className = {`${s.customSelect} ${height !== 0 ? 'active' : ''}`}>
            <p className = {`${s.activeItem} ${sm.input}`} onClick = {() => {height === 0 ? setHeight('auto') : setHeight(0)}}>
                <span>{subActiveItem ? subActiveItem : activeItem}</span>
                <img style={height !== 0 ? {transform: 'rotate(180deg)'} : {}} src="/img/adminArrow.svg" alt="img"/>
            </p>
            <AnimateHeight
                height = {height}
                duration = {200}
                className = {className}
            >
                <ul className = {s.list}>
                    {itemsWithSub 
                        ? 
                        <>
                            {itemsWithSub.map((item, index) => 
                                <>
                                <label key = {index} onClick = {() => {setActiveItem(item.title); setSubActiveItem(''); setHeight(0); console.log(item)}}>
                                    <input checked = {activeItem === item.title} type="checkbox" style = {{display: 'none'}} readOnly />
                                    <p>
                                        <img style = {{marginRight: '10px'}}src = {activeItem === item.title ? '/img/adminChecked.svg' : '/img/adminNoChecked.svg'} alt="img"/>
                                        <span>{item.title}</span>
                                    </p>
                                </label>
                                {item.subcategories && item.subcategories.map((sub, subindex) => 
                                    <label key = {subindex} onClick = {() => {setSubActiveItem(sub.name); setActiveItem(item.title); setHeight(0);}} style={{marginLeft: '18px'}}>
                                        <input checked = {subActiveItem === sub.name} type="checkbox" style = {{display: 'none'}} readOnly />
                                        <p>
                                            <img style = {{marginRight: '10px'}} src = {subActiveItem === sub.name ? '/img/adminChecked.svg' : '/img/adminNoChecked.svg'} alt="img"/>
                                            <span>{sub.name}</span>
                                        </p>
                                    </label>
                                )}
                                </>
                            )}
                        </> 
                        :
                        <>
                        {items.map((item, index) => 
                            <label key = {index} onClick = {() => {setActiveItem(item); setHeight(0); }}>
                                <input checked = {activeItem === item} type="checkbox" style = {{display: 'none'}} readOnly />
                                <p>
                                    <img style = {{marginRight: '10px'}}src = {activeItem === item ? '/img/adminChecked.svg' : '/img/adminNoChecked.svg'} alt="img"/>
                                    <span>{item}</span>
                                </p>
                            </label>
                        )}
                        </>
                    }
                </ul>
            </AnimateHeight>
        </div>
    )
});

export default CustomSelectCourier;