import React, {useState} from 'react'
import s from '../Table.module.sass'
import sModals from '../../../Modals/Modals.module.sass'
import sCustomSearch from '../../../Common/CustomSearch/CustomSearch.module.sass'
import {appAPI, apiURL} from '../../../../../API/API'

const TableItemRecommend = React.memo(({item, setState, setForceUpdate}) => {

    const [showEdit, setShowEdit] = useState(false)
    const [products, showProducts] = useState([])
    const [activeItem, setActiveItem] = useState(null)

    const searchProducts = async (value) => {
        value.length > 0 
            ? await appAPI.searchNotReccomend(value)
                .then(response => showProducts(response.data))
                .catch(err => console.log(err))
            : showProducts([])
    }

    const editItem = async (id) => {
        if(activeItem) {
            await appAPI.putProductReccomend(item.id, false)
            await appAPI.putProductReccomend(activeItem, true)
                .then(response => {
                    setForceUpdate(prev => prev + 1)
                    setShowEdit(false)
                })
                .catch(err => console.error(err))
        } else setShowEdit(false)
    }

    // const delProduct = async () => {
    //     await appAPI.putProductReccomend(item.id, false)
    //         .then(response => setState(prev => prev.filter(product => product.id !== item.id)))
    //         .catch(err => console.log(err))
    // }

    console.log(item)

    return(
        <tr className="table-item">
            <td className = {s.imgTable}>
                <img alt="" src = {`${apiURL}${item.images[0].url}`}/>
            </td>
            <td>
                {
                    showEdit
                        ?   <div>
                                <input 
                                    autoFocus = {true}
                                    className = {sModals.input} 
                                    onChange = {(e) => searchProducts(e.currentTarget.value)}
                                    style = {{maxWidth: '270px', width: '100%'}}
                                />
                                <div  style = {{width: '100%'}} className = {`${s.customSearchRecommend} ${sCustomSearch.resultBlock}`}>
                                    <ul>
                                        {products.map(product =>
                                            <li 
                                                onClick = {() => setActiveItem(product.id)}
                                                className = {`${activeItem === product.id ? s.active : ''}`} 
                                                key = {product.id}
                                            >
                                                <img src={`${apiURL}${product.images[0].url}`} alt="img"/>
                                                <p>{product.composition}</p>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        : item.name
                }
            </td>
            <td>
                {
                    showEdit
                        ? <img className = {s.imgScale} src = '/img/adminSuccess.svg' alt = 'img' onClick = {() => editItem(item.id)}/>
                        : <img className = {s.imgScale} src="/img/adminRed.svg" alt="img" onClick = {() => {setShowEdit(true)}}/>
                }
                
            </td>
        </tr>
    )
});

export default TableItemRecommend;