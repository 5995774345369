import React, {useState} from 'react'
import s from './Modals.module.sass'
import {appAPI, apiURL} from '../../../API/API'

const EditSlider = ({item, setState, close, setForceUpdate}) => {
    
    const [formData, setFormData] = useState({
        slideText: item.slideText,
        buttonText: item.buttonText,
        buttonLink: item.link,
        img: ''
    })

    const inputFile = React.useRef(null)

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if(formData.slideText !== item.slideText || formData.buttonText !== item.buttonText || formData.buttonLink !== item.link || formData.img) {
            const data = new FormData();
            data.append('data', JSON.stringify({link: formData.buttonLink, localId: item.localId, buttonText: formData.buttonText, slideText: formData.slideText}))
            if(formData.img) data.append('file', formData.img)
            await appAPI.editSlide(data, item.id)
                .then(() => {
                    setForceUpdate(prev => prev + 1)
                    close();
                })
                .catch(err => console.log(err))
        } else alert('Все поля совпадают с предыдущим значением')
    }

    const changeHandlerFile = () => {
        if (inputFile.current.files && inputFile.current.files[0]) setFormData(prev => ({...prev, img: inputFile.current.files[0]}))
    }

    return(
        <div className={`${s.modalAdmin} ${s.modalWide}`}>
            <form className={`${s.formAdmin} ${s.formWide}`} onSubmit={submitHandler}>
                <div className = {`${s.labelImg} ${s.label}`}>
                    <p>Изображение</p>
                    <label>
                        <p>
                            <img src="/img/screpka.svg" alt="img"/>
                            <span>Загрузите фото</span>
                        </p>
                        <input type="file" ref = {inputFile} style = {{display: 'none'}} multiple accept="image/*" onChange = {changeHandlerFile}/>
                    </label>
                    {formData.img ? <img alt="" src = {URL.createObjectURL(formData.img)} className = {s.imgWide}/> : <img alt="" src = {`${apiURL}${item.image}`} className = {s.imgWide}/>}
                </div>
                <label className = {`${s.label}`}>
                    <p>Текст слайда</p>
                    <input 
                        type="text"
                        name = 'slideText'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.slideText}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Текст кнопки</p>
                    <input 
                        type="text"
                        name = 'buttonText'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.buttonText}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Ссылка кнопки</p>
                    <input 
                        type="text"
                        name = 'buttonLink'
                        onChange={changeHandler}
                        className = {s.input}
                        defaultValue = {formData.buttonLink}
                    />
                </label>
                <button type = 'submit' className={`button ${formData.slideText !== item.slideText || formData.buttonText !== item.buttonText || formData.buttonLink !== item.link || formData.img ? s.btn_red : ''}`}>Сохранить</button>
            </form>
        </div>
    )
}

export default EditSlider;
