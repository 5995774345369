import {OPEN_MODAL, CLOSE_MODAL} from "../Actions/ModalActions";

const initialState = {
    isShow: false,
    modalBody: null,
    parameters: {},
    haveCloseBtn: false,
};

export default function ModalReducer(state=initialState, action) {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                isShow: true,
                modalBody: action.body,
                haveCloseBtn: action.withBtn,
                parameters: action.parameters,
            }
        case CLOSE_MODAL:
            return {
                ...state,
                isShow: false,
                modalBody: null,
                title: null,
                parameters: {},
                haveCloseBtn: false,
                centered: true
            }
        default:
            return state;
    }
}