import React, {useState, useEffect} from 'react'
import AnimateHeight from 'react-animate-height';
import OrderProduct from './OrderProduct';
import moment from "moment";
import {delProduct, returnProducts, delReturnedProducts} from '../../../Actions/OrderActions'
import {useDispatch, useSelector} from 'react-redux'
import {appAPI} from '../../../API/API'
import OrderStatuses from './OrderStatuses';
import { getPhone } from '../../Admin/Common/OtherFunctions/OtherFunctions';

const Order = ({order, activeAccordion, setActiveAccordion, openProducts, setOpenProducts, id, deleteOrder}) => {

    const dispatch = useDispatch()
    const [height, setHeight] = useState(0)
    const [changeOrder, setChangeOrder] = useState(false)
    const [delProducts, setDelProducts] = useState([])
    // eslint-disable-next-line
    const [putProducts, setPutProducts] = useState(order.products)
    const [productsRequest, setProductsRequest] = useState([])
    const [productFlag, setProductFlag] = useState(false)
    const {token} = useSelector((state) => state.User)
    const {deletedProducts} = useSelector((state) => state.Order)
    
    
    const deleteProduct = async (id) => {
        Promise.all(delProducts.map(product => new Promise((resolve, reject) => appAPI.delProduct(product.orderId, product.productId, token ).then(()=>resolve('ok')))))
        .then(() => {
            setDelProducts([])
            dispatch(delReturnedProducts())
        })
        
    }

    const putProduct = async (id) => {
        
        // Promise.all(productsRequest.map(product => new Promise((resolve, reject) => appAPI.putProduct(product.orderId, product.productId, product.amount, token ).then(()=>resolve('ok')))))
        // .then(() => {
        //     putProducts.length && dispatch(changeOrderProducts(order.id, putProducts))
        //     setPutProducts(order.products)
        //     setProductsRequest([])
        // })
    }

    const addDelProduct = (id, product) => {
        dispatch(delProduct(order.id, id, product))
        setDelProducts(delProducts => ([
            ...delProducts,
            {
                orderId: order.id,
                productId: id
            }
        ]))
    }

    const addPutProduct = (id, amount) => {
        setPutProducts( (prev) => prev.map(product => product.id === id ? {...product, amount: parseInt(amount)} : product) )
        if(productsRequest.find(product => product.productId === id)) {
            setProductsRequest( (prev) => prev.map(product => product.id === id ? {...product, amount} : product) )
        } else setProductsRequest( (prev) => ([...prev, {productId: id, orderId: order.id, amount}]))
        
    }

    const showStatus = (status) => {
        switch(status) {
            case 'RECEIVING' :
                return 'Получение заказа'
            case 'DELIVERING' :
                return 'Доставка заказа'
            case 'DELIVERED' :
                return 'Доставлен'
            case 'RETURNING' :
                return 'Возврат'
            case 'RETURNED' :
                return 'Возвращен'
            default: 
                break;
        }
    }

    useEffect(() => activeAccordion === id ? setHeight('auto') : setHeight(0), [activeAccordion, id] )

    return(
        <li 
            className = {` operator-orders__accordion__item ${activeAccordion === id ? 'active': ''}`}>
            <div className="operator-orders__accordion-header"
                onClick = { () => {
                    activeAccordion === id ? setActiveAccordion(null) : setActiveAccordion(id)
                    setHeight( (height) => (
                        height === 0 ? 'auto' : 0
                ))}}>
                <span><b>{moment(order.orderDate).format('DD.MM.YYYY')}</b></span>
                <h2>Заказ №{order.id}</h2>
                <h2>{order.orderReceiving === 'PICKUP' ? 'Самовывоз' : 'Доставка'}</h2>
                <h2>Статус: {showStatus(order.deliveryStatus)}</h2>
            </div>
        <AnimateHeight
          id='content-hidden'
          duration={ 200 }
          height={ height }
        >
            <div className="operator-orders__accordion-content">
            {order.orderReceiving === 'DELIVERY' 
                ? (
                    <div className="operator-orders__accordion-content__top">
                        <ul className="left">
                            <li className="left__item">
                                <span>Адрес ПВЗ</span>
                                <span>{order.seller.shop?.city}</span>
                            </li>
                            <li className="left__item">
                                <span>Курьер</span>
                                <span>{order.deliveryType === 'YANDEX' && 'Яндекс'}</span>
                            </li>
                            <li className="left__item">
                                <span>Телефон курьера</span>
                                <span>+7 777 777 77 77</span>
                            </li>
                            <li className="left__item">
                                <span>Адрес получателя</span>
                                <span>{`г. ${order.city}, ул. ${order.initialOrder.street}, д. ${order.initialOrder.home}`}</span>
                            </li>
                            <li className="left__item">
                                <span>Телефон получателя</span>
                                <span>{getPhone(order.phone)}</span>
                            </li>
                            <li className="left__item">
                                <span>Комментарий</span>
                                <span>{order.comment}</span>
                            </li>
                            <li>
                                <button className = 'button btn_red' onClick = { () => setOpenProducts(!openProducts)}>
                                    <span>{openProducts ? 'Скрыть' : 'Подробнее'}</span>
                                    <img className = {`${!openProducts ? 'active' : ''}`} src="/img/arrowUp.svg" alt="img"/>
                                </button>
                            </li>
                        </ul>
                        <ul className="right">
                            <OrderStatuses status = {order.deliveryStatus}/>
                        </ul>
                    </div>
                
                ) : (
                    <div className="operator-orders__accordion-content__top">
                        <ul className="left">
                            <li className="left__item">
                                <span>Адрес ПВЗ</span>
                                <span>Парголово, ул. Михаила Дудина, д. 6, ТК «ПАРНАС СИТИ»</span>
                            </li>
                            <li className="left__item">
                                <span>Телефон магазина</span>
                                <span>+7 777 777 77 77</span>
                            </li>
                            <li className="left__item">
                                <span>Имя получателя</span>
                                <span>{order.username}</span>
                            </li>
                            <li className="left__item">
                                <span>Телефон получателя</span>
                                <span>{getPhone(order.phone)}</span>
                            </li>
                            <li>
                                <button className = 'button btn_red' onClick = { () => setOpenProducts(!openProducts)}>
                                    <span>{openProducts ? 'Скрыть' : 'Подробнее'}</span>
                                    <img className = {`${!openProducts ? 'active' : ''}`} src="/img/arrowUp.svg" alt="img"/>
                                </button>
                            </li>
                        </ul>
                    </div>
                ) }
            { openProducts && 
                <div className="operator-orders__accordion-content__bottom">
                    <table>
                    <thead>
                        <tr>
                            <th>Перечень товаров</th>
                            <th>Заказанное кол-во</th>
                            <th>Отсканированное кол-во</th>
                            <th>Статус</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order.products.map((product, i) => <OrderProduct 
                            product = {product}
                            changeOrder = {changeOrder} 
                            addDelProduct = {addDelProduct}
                            addPutProduct = {addPutProduct}
                            productFlag = {productFlag}
                            amountOrdered = {order.initialOrder.orderProducts.find(item => item.productId === product.product.id).amount}
                            id = {product.id}
                            key = {product.id}
                            />)}
                    </tbody>
                </table>
                    <p className = 'desc_bold desc_bold_itog'>Итого: {order.orderPrice}  ₽</p>
                    <div className="buttons">
                    {order.orderStatus !== 'FINISHED' ?
                        changeOrder 
                            ? 
                                <>
                                    <button className = 'button btn_red' onClick = {() => {
                                        dispatch(returnProducts(deletedProducts, order.id));
                                        setDelProducts([]);
                                        setChangeOrder(false);
                                        setProductFlag(true);
                                        setPutProducts([])
                                        setProductsRequest([])
                                    }}>Отменить изменения</button>
                                    <button className = 'button btn_red' onClick={ () => { setChangeOrder(false); deleteProduct(); putProduct()}}>Сохранить заказ</button>
                                </>
                            :
                                <>
                                    <button className = 'button btn_grey' onClick = {() => deleteOrder(id)}>Отменить заказ</button>
                                    <button className = 'button btn_red' onClick={ () => {setChangeOrder(true); setProductFlag(false);}}>Изменить заказ</button>   
                                </>
                        : null
                    }
                    </div>
                </div>
            }

        </div>
        </AnimateHeight>
    </li>

    )
}

export default React.memo(Order  );