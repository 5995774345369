import React from 'react';
import s from './ProductsSearch.module.sass'
import {appAPI} from '../../../../API/API'
import Loader from '../../Common/Loaders/Loader';

const ProductsSearch = React.memo(({state, setState}) => {

    const inputRef = React.useRef(null)
    const [isFetching, setIsFetching] = React.useState(false)
    const [searchBy, setSearchBy] = React.useState('')

    const searchProducts = async (value) => {
        setSearchBy(value);
        setIsFetching(true)
        await appAPI.productsSearch(value)
            .then(response => {
                setState(response.data)
                setIsFetching(false)
            })
            .catch(err => console.error(err))
    }

    return(
        <div className = {s.customSearch}>
            {
                isFetching 
                    ?   <div className = {s.loader}>
                            <Loader type = 'bubbles' color = '#000' height = {38} width = {38}/>
                        </div>
                    :
                        <>
                            <input 
                                type="text" 
                                placeholder = 'Поиск...'
                                onKeyUp = {e => {
                                    e.keyCode === 13 && searchProducts(e.currentTarget.value)
                                }}
                                ref = {inputRef}
                                defaultValue = {searchBy}
                            />
                            {searchBy 
                                ? <img  
                                        onClick = {() => searchProducts('')}
                                        src = '/img/formClose.svg' 
                                        alt = 'close'
                                    />
                                :   <img 
                                    src="/img/adminSearch.svg" 
                                    alt="img"
                                    onClick = {() => searchProducts(inputRef.current.value)}
                                    />
                            }
                            
                        </>
            }
           
        </div>
    )

});

export default ProductsSearch;