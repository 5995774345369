import React from 'react'
import sCategory from '../Category/Category.module.sass'
import s from './Subcategory.module.sass'
import { listenerEsc } from '../Category/CategoryFunctions';
import { appAPI } from '../../../../API/API';

const Subcategory = React.memo(({title, id, editCategories, categoryId, delCategories, setSubcategories, active, setCreateSubcategory}) => {

    const [activeInput, setActiveInput] = React.useState(active)
    const inputRef = React.useRef(null)


    const listenerSuccessCategory = async (id) => {
        if(inputRef.current.value) {
            if(active) {
                await appAPI.createSubcategory({name: inputRef.current.value, categoryId, localId: Math.floor(Math.random() * 1000)})
                    .then(response => {
                        setCreateSubcategory(prev => prev + 1)
                        setActiveInput(false);
                    })
                    .catch(err => console.error(err))
            } else if (!title) {
                await appAPI.editSubcategory({name: inputRef.current.value, categoryId}, id)
                    .then(response => {
                        setCreateSubcategory(prev => prev + 1)
                        setActiveInput(false);
                    })
                    .catch(err => console.error(err))
            } else setActiveInput(false);
        }
        else {
            delCategories(id, setSubcategories)
            setActiveInput(false);
        }
    }

    const deleteCategory = async (id) => {
        await appAPI.delSubcategory(id)
            .then(response => delCategories(id, setSubcategories))
            .catch(err => console.error(err))
    }

    React.useEffect(() => listenerEsc(delCategories, id, active, setSubcategories, setActiveInput), [active, delCategories, id, setSubcategories]);

    return(
        <div className = {`${sCategory.category} ${s.subcategory}`}>
            {
                activeInput
                    ?   <input 
                            type = 'text' 
                            ref = {inputRef} 
                            className = {sCategory.input}
                            onKeyDown = {(e) => e.keyCode === 13 ? listenerSuccessCategory(id) : null}
                            autoFocus = {true}
                            defaultValue = {title && title}
                            />
                    :   <p>{title}</p>
            }

            <div>
                {
                    activeInput
                        ? <img src = '/img/adminSuccess.svg' alt = 'img' onClick = {() => listenerSuccessCategory(id)}/>
                        : <img src="/img/adminRed.svg" alt="img" onClick = {() => setActiveInput(true)}/>
                }
                <img src="/img/adminDel.svg" alt="img" onClick = {() => deleteCategory(id)}/>
            </div>
        </div>
    )
});

export default Subcategory;