import React, {useState} from 'react'
import './Modals.sass'
import AnimateHeight from 'react-animate-height';
import {appAPI} from '../../../API/API'
import {useSelector, useDispatch} from 'react-redux'
import ConfirmationVozvrat from './ConfirmationVozvrat'
import {openModalAction} from '../../../Actions/ModalActions'

const Vozvrat = ({close, orderId}) => {

    const [heightSelect, setHeightSelect] = useState(0)
    const [heightSelectList, setHeightSelectList] = useState(0)
    const dispatch = useDispatch()
    const {token} = useSelector((state) => state.User)

    const [activeSelect, setActiveSelect] = useState('Выберите')
    const purposes = ['Причина 1', 'Причина 2', 'Причина 3']

    const cancelOrder = async () => {
        try{
            await appAPI.userToCourier(orderId, token)
            dispatch(openModalAction(ConfirmationVozvrat, {orderId, type: 'user'}))
        } catch (err) {}
    }

    return(
        <div className="container-modal container-modal_vozvrat">
            <img src="/img/closeRed.svg" alt="close" className="courier-modal-close" onClick={() => close()}/>
            <h2>Подтверждение организации возврата</h2>
            <p>Заказ № {orderId}</p>
            <button className="button btn_red" onClick = {cancelOrder}>Клиент отказался</button>
            <button className="button btn_red" onClick={() => {
                    setHeightSelect((height) => height === 0 ? 'auto' : 0)
                }}
                >Другая причина</button>

            <AnimateHeight
                id='content-hidden'
                duration={ 200 }
                height={ heightSelect }
            >
                <div className="custom_select">
                    <p className="custom_select__active" onClick = { () => {
                        setHeightSelectList((height) => height === 0 ? 'auto' : 0)
                    }}>{activeSelect}</p>
                    <AnimateHeight
                        id='content-hidden'
                        duration={ 200 }
                        height={ heightSelectList }
                    >
                        <ul>
                            {purposes.map((item, i) => <li className='custom-select__item' key = {i} onClick={() => {
                                setActiveSelect(item);
                                setHeightSelectList(0)
                            }}>{item}</li>)}
                        </ul>
                    </AnimateHeight>
                </div>
            </AnimateHeight>
            <button className="button btn_blue">Готово</button>
          
        </div>
    )
    
}

export default Vozvrat;