import React, {useEffect, useState} from 'react'
import s from './Modals.module.sass'
import InputMask from 'react-input-mask';
import {appAPI} from '../../../API/API'
import CustomSelectCourier from '../Common/CustomSelectCourier/CustomSelectCourier';
import { useSelector } from 'react-redux';

const CreateCourier = ({setState, close}) => {

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        login: '',
        password: '', 
        telegramName: ''
    })

    const [courierType, setCourierType] = useState('')
    const [address, setAddress] = useState('')
    const {token} = useSelector(state => state.User)
    const [isFetching, setIsFetching] = useState(true)
    const [pvz, setPvz] = useState([{
        sellerId: '',
        shop: ''
    }])

    useEffect(() => {
        const fetchData = async () => {
            await appAPI.getSellers(token)
                .then(response => {
                    console.log(response)
                    setPvz(response.data.filter(seller => seller.shop).map(seller => ({sellerId: seller.id, shop: `${seller.shop.city} ${seller.shop.street && ', ' + seller.shop.street} ${seller.shop.building && ', ' + seller.shop.building}`})))
                    setIsFetching(false)
                })
        }
        fetchData();
    }, [token])

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        // eslint-disable-next-line
        if(courierType, formData.name && formData.phone && formData.login && formData.password && formData.telegramName) {
            const type = courierType === 'Супер-курьер' ? 'SUPER' : 'ORDINARY'
            await appAPI.createCourier({...formData, courierType: type, sellerId: pvz.find(item => item.shop === address).sellerId})
                .then(response => {
                    setState(prev => [...prev, response.data])
                    close();
                })
                .catch(err => alert(err.response.data.message))
        } else alert('Введены не все данные')
    }

    return(
        <div className={s.modalAdmin}>
            {isFetching 
                ?   null
                :   <form className={s.formAdmin} onSubmit={submitHandler}>
                <div className = {`${s.customLabel} ${s.label}`}>
                    <p>Тип</p>
                    <CustomSelectCourier 
                        placeholder = 'Обычный курьер'
                        items = {['Обычный курьер', 'Супер - курьер']}
                        setState = {setCourierType}
                    />
                </div>
                <label className = {`${s.label}`}>
                    <p>Имя</p>
                    <input 
                        type="text"
                        name = 'name'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Телеграм</p>
                    <input 
                        type="text"
                        name = 'telegramName'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Телефон</p>
                    <InputMask
                        mask = '+7 999 999 99 99'
                        name = 'phone'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Логин</p>
                    <input 
                        type="text"
                        name = 'login'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Пароль</p>
                    <input 
                        type="password"
                        name = 'password'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <div className = {`${s.customLabel} ${s.label}`}>
                    <p>ПВЗ</p>
                    <CustomSelectCourier 
                        placeholder = {pvz[0].shop}
                        items = {pvz.map(item => item.shop)}
                        className = {s.address}
                        setState = {setAddress}
                    />
                </div>
                <button type = 'submit' className={`button btn_red`}>Создать</button>
            </form>
            }
            
        </div>
    )
}

export default CreateCourier;
