import React from 'react';
import './Table.module.sass'
import {openModalAction} from '../../../../Actions/ModalActions'
import s from './Table.module.sass'
import { useDispatch } from 'react-redux';
import ProductsSearch from '../../Products/ProductsSearch/ProductsSearch';
import InfiniteScroll from 'react-infinite-scroll-component';

const Table = ({headers, tableItems, title, Component, showPlus, ComponentCreate, setState, setForceUpdate, showSearch, fetchData, hasMore}) => {

    const dispatch = useDispatch();
    // console.log(tableItems)
    
    return(
        <div className={s.table}>
            {
                showSearch 
                    ?   <div className = {s.tableTop}>
                            <div className={s.tableTitle}>
                                <h2>{title}</h2>
                            </div>
                            <ProductsSearch setState = {setState}/>
                        </div>

                    :   <div className={s.tableTitle}>
                            <h2>{title}</h2>
                        </div>
            }
            <div className={s.tableContent} id='scrollable-table'>
                {tableItems.length > 0 ?
                    <InfiniteScroll
                        dataLength={tableItems.length} //This is important field to render the next data
                        // next={fetchData}
                        // hasMore={hasMore}
                        hasMore={false}
                        // loader={<h4>Loading...</h4>}
                        scrollableTarget='scrollable-table'
                    >
                        <table>
                            <thead>
                                <tr>{headers.map((header, i) => <th key = {i}>{header}</th>)}</tr>
                            </thead>
                            <tbody>
                                
                                    {tableItems.sort((prev, next) => prev.localId < next.localId ? -1 : 1).map((item, index) => <Component item = {item} key = {item.id ?? index} setState = {setState} setForceUpdate = {setForceUpdate}/>)}
                                
                            </tbody>
                        </table>
                    </InfiniteScroll>
                : null}
                {showPlus 
                    ? <img 
                        alt=""
                        className = {s.tablePlus} 
                        src = '/img/adminPlus.svg'
                        onClick = {() => dispatch(openModalAction(ComponentCreate, {setState, setForceUpdate, index: tableItems.length}))}
                        /> 
                    : null}
            </div>
        </div>
    )
}

export default Table;