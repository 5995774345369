import React from 'react';
import './Admin.sass';
import { Route, Switch, Redirect } from "react-router-dom"
import Service from './Users/Service'
import Couriers from './Users/Couriers'
import Operators from './Users/Operators'
import Operator from '../../pages/Operator/Operator'
import Menu from './Menu/Menu';
import Slider from './Slider/Slider';
import Vacancies from './Vacancies/Vacancies';
import Recepies from './Recepies/Recepies';
import NewProducts from './NewProducts/NewProducts';
import RecommendationProducts from './RecommendationProducts/RecommendationProducts';
import Sellers from './Users/Sellers';
import Shops from './Shops/Shops';
import Delivery from './Delivery/Delivery';
import Products from './Products/Products';
import Groups from './Groups/Groups';
import Cookies from 'js-cookie';

const Admin = () => {

  return(
    <>
      <div className='header'>
        <button className = 'button' onClick = {() => {
           Cookies.remove('tokenStaff');
           localStorage.removeItem('userRole')
           window.location.reload();
        }}>Выйти</button>
      </div>

      <div className="app-content">
        <Menu/>
        <div className="content">
          <Switch>
            <Route path="/operators" component = {Operators}/>
            <Route path="/service" component = {Service} />
            <Route path="/couries" component = {Couriers} />
            <Route path="/sellers" component = {Sellers} />
            <Route path="/orders"><Operator showInfo = {false}/></Route>
            <Route path="/slider" component = {Slider} />
            <Route path="/vacancies" component = {Vacancies} />
            <Route path="/shops" component = {Shops} />
            <Route path="/recepies" component = {Recepies} />
            <Route path="/delivery" component = {Delivery} />
            <Route path="/products" component = {Products} />
            <Route path="/groups" component = {Groups} />
            <Route path="/products-new" component = {NewProducts}/>
            <Route path="/products-recommend" component = {RecommendationProducts}/>
            <Route path = '*'><Redirect to = '/'/></Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default Admin;
