import React, {useState} from 'react';
import ClickAwayListener from 'react-click-away-listener';
import {appAPI} from '../../API/API'
import {useSelector} from 'react-redux'


const Confirmation = ({close}) => {

    const [message, setMessage] = useState('')
    const [menuContactsOpen, setMenuContactsOpen] = useState(false)
    const [activeType, setActiveType] = useState('Тип обращения')
    const arrType = ['Доставка', 'Самовывоз', 'Наличие товаров', 'Техническая поддержка', 'Обмен и возврат']
    const {token} = useSelector((state) => state.User)

    const onClickItem = (item) => {
        setActiveType(item)
        setMenuContactsOpen(false)
    }
    
    const submitHandler = async (e) => {
      e.preventDefault()
      if(message)
        try {
          let nameSeller = '';
          if(localStorage.getItem('userRole') === 'seller') {
            const response = await appAPI.getProfile(token)
            console.log(response)
            if(response.shop) {
              const shop = response.shop;
              nameSeller = `${shop.city ?? ''} ${shop.street ?? ''} ${shop.building ?? ''} ${shop.office ?? ''}`
            }
          } 
          await appAPI.postRequest(activeType, nameSeller.length > 0 ? nameSeller : 'seller', message, token)
          close()
        } catch (err){}
    }

    return(
        <form className="modal_request" onSubmit={submitHandler}>
            <h1>Обращение в тех. поддержку</h1>
            <img src="/img/closeRed.svg" alt="img" className="close-modal" onClick= {() => close()} />
            <textarea 
                type = 'text'
                onChange={ (e) => setMessage(e.target.value) }
                placeholder = 'Введите сообщение'
                />

                <div className='form-field form-field_input form-field_type' >      
                  <ClickAwayListener onClickAway={() => setMenuContactsOpen(false)}>
                    <p onClick={() => setMenuContactsOpen(!menuContactsOpen)}>
                      {activeType}
                      <svg className = {`${menuContactsOpen ? 'active' : '' }`} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.39754 12.7732C9.34529 12.8256 9.28322 12.8672 9.21488 12.8955C9.14654 12.9239 9.07328 12.9385 8.99929 12.9385C8.9253 12.9385 8.85204 12.9239 8.7837 12.8955C8.71536 12.8672 8.65329 12.8256 8.60104 12.7732L1.85104 6.02322C1.74542 5.9176 1.68608 5.77435 1.68608 5.62497C1.68608 5.4756 1.74542 5.33235 1.85104 5.22672C1.95666 5.1211 2.09992 5.06176 2.24929 5.06176C2.39866 5.06176 2.54192 5.1211 2.64754 5.22672L8.99929 11.5796L15.351 5.22672C15.4567 5.1211 15.5999 5.06176 15.7493 5.06176C15.8987 5.06176 16.0419 5.1211 16.1475 5.22672C16.2532 5.33235 16.3125 5.4756 16.3125 5.62497C16.3125 5.77435 16.2532 5.9176 16.1475 6.02322L9.39754 12.7732Z" fill="black"/>
                      </svg>
                    </p>
                      <ul className={`menu menu_catalog menu_contactsType ${menuContactsOpen ? 'active' : ''}`}>
                        {arrType.map((item, i) => <li className = 'menu__item' key = {i} onClick = {() => onClickItem(item)}><p>{item}</p></li>)}
                      </ul>
                  </ClickAwayListener>
                </div>
            <button className = {`button ${message ? 'btn_red' : ''} btn_modal`} type = 'submit'>Отправить</button>
        </form>
    )

}

export default Confirmation;