import React from 'react';
import Table from '../Users/Table/Table';
import {appAPI} from '../../../API/API'
import TableItemRecommend from '../Users/Table/TableItems/TableItemRecommend';

const RecommendationProducts = () => {

    const headers = ['', 'Заголовок', 'Редактировать']
    const [products, setProducts] = React.useState([])
    const [forceUpdate, setForceUpdate] = React.useState(0)
    
    React.useEffect(() => {
        const fetchData = async () => {
            await appAPI.getReccomendationProducts()
                .then(response => setProducts(response.data))
                .catch(error => console.error(error))
        }
        fetchData();
    }, [forceUpdate])
    
    return(
        <div >
            <Table
                title = 'Рекомендации'
                tableItems = {products}
                headers = {headers}
                Component = {TableItemRecommend}
                setState = {setProducts}
                setForceUpdate = {setForceUpdate}
            />
        </div>
    )   
}

export default RecommendationProducts;