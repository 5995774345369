import {
    ORDER_STATUS, 
    SET_ORDER, 
    SET_ORDERS, 
    DEL_ORDER, 
    DEL_PRODUCT, 
    RETURN_PRODUCTS, 
    DEL_RETURNED_PRODUCTS, 
    SET_REQUESTS, 
    ARCH_REQUEST,
    DEL_ORDER_SELLER,
    SET_ORDER_FETCHED,
    CHANGE_ORDER_STATUS,
    CHANGE_SELLER_STATUS,
    CHANGE_ORDER_PRODUCTS,
    RESET_ORDERS,
    ACCEPT_ORDER
} from '../Actions/OrderActions'

const OrderReducer = (
    state = {
        orders: [],
        initialOrders: [],
        deletedProducts: [],
        requests: [],
        initialRequests: [],
        orderFetched: false,
        ordersAccept: [],
        ordersProcessing: [],
        ordersReady: [],
    },
    action,
  ) => {
    switch (action.type) {
        case ORDER_STATUS:
            return {
                ...state,
                orders: [...state.orders.map((order) => order.id === action.orderID ? {...order, status: action.status} : order)]
            }
        
        case SET_ORDER:
            return {
                ...state,
                orders: [...state.orders, action.order]
            }

        case SET_ORDERS:
            return {
                ...state,
                orders: action.orders,
                initialOrders: action.initialOrders ? action.initialOrders : state.initialOrders
            }

        case DEL_ORDER:
            return {
                ...state,
                orders: state.orders.map( order => order.id === action.id ? {...order, orderStatus: 'FINISHED'} : order),
                initialOrders: state.initialOrders.map( order => order.id === action.id ? {...order, orderStatus: 'FINISHED'} : order)
            }

        case DEL_PRODUCT: 
            return {
                ...state,
                orders: state.orders.map(order => 
                    order.id !== action.orderId 
                        ? order : {...order, products: [...order.products.filter(product => product.id !== action.productId)]}
                ),
                initialOrders: state.initialOrders.map(order => 
                    order.id !== action.orderId 
                        ? order : {...order, products: [...order.products.filter(product => product.id !== action.productId)]}
                ),
                deletedProducts:  [...state.deletedProducts, action.product]
            }

        case RETURN_PRODUCTS: 
            return {
                ...state,
                orders: state.orders.map(order => 
                    order.id !== action.orderId
                        ? order
                        : {...order, products: [...order.products, ...action.products]}
                ),
                initialOrders: state.initialOrders.map(order => 
                    order.id !== action.orderId
                        ? order
                        : {...order, products: [...order.products, ...action.products]}
                ),
                deletedProducts: []
            }

        case DEL_RETURNED_PRODUCTS:
            return {
                ...state,
                deletedProducts: []
            }

        case CHANGE_ORDER_PRODUCTS: 
            return {
                ...state,
                orders: state.orders.map(order =>
                    order.id !== action.orderId
                    ? order
                    : {...order, products: action.products}   
                ),
                initialOrders: state.initialOrders.map(order =>
                    order.id !== action.orderId
                    ? order
                    : {...order, products: action.products}   
                )
            }
            
        case SET_REQUESTS:
            return {
                ...state,
                requests: action.requests,
                initialRequests: action.initialRequests ? action.initialRequests : state.initialRequests
            }

        case ARCH_REQUEST:
            return {
                ...state,
                requests: state.requests.map(request => request.id !== action.id ? request : {...request, active: false}),
                initialRequests: state.initialRequests.map(request => request.id !== action.id ? request : {...request, active: false})
            }

        case DEL_ORDER_SELLER:
            return {
                ...state,
                orders: state.orders.filter(order => order.id !== action.id)
            }

        case SET_ORDER_FETCHED:
            return {
                ...state,
                orderFetched: true
            }

        case CHANGE_ORDER_STATUS:
            return {
                ...state,
                orders: state.orders.map(order => 
                    order.id === action.orderId 
                        ? {...order, deliveryStatus: action.status}
                        : order
                )
            }

        case CHANGE_SELLER_STATUS:
            return {
                ...state,
                orders: state.orders.map(order =>
                    order.id === action.orderId
                        ? {...order, sellerStatus: action.status}
                        : order    
                )
            }

        case RESET_ORDERS:
            return {
                ...state,
                orders: [],
                initialOrders: [],
                deletedProducts: [],
                requests: [],
                initialRequests: [],
                orderFetched: false
            }

        case ACCEPT_ORDER: 
            return {
                ...state,
                orders: state.orders.map(order => order.id === action.id ? {...order, sellerStatus: 'PROCESSING'} : order)
            }

      default:
        return state;
    }
  };
  
  export { OrderReducer };
  