import React from 'react'
import s from '../Table.module.sass'
import {useDispatch} from 'react-redux'
import {openModalAction} from '../../../../../Actions/ModalActions'
import EditSeller from '../../../Modals/EditSeller';
import {appAPI} from '../../../../../API/API'

const TableItemSeller = ({item, setState, setForceUpdate}) => {

    const dispatch = useDispatch();

    const delSellerFunc = async (id) => {
        await appAPI.delSeller(id)
            .then(() => setState(prev => prev.filter(seller => seller.id !== id)))
            .catch(err => alert(err.response.data.message))
    }

    return(
        <tr className="table-item">
            <td style = {{whiteSpace: 'pre'}}>{item.shop ? `${item.shop.city},   ${item.shop.street}, д. ${item.shop.building} кв. ${item.shop.office}`: ''}</td>
            <td onClick={() => dispatch(openModalAction(EditSeller, {item, setForceUpdate}))}>
                <img className = {s.imgScale} src="/img/adminRed.svg" alt="img"/>
            </td>
            <td onClick = {() => delSellerFunc(item.id)}>
                <img className = {s.imgScale} src="/img/adminDel.svg" alt="img"/>
            </td>
        </tr>
    )
}

export default TableItemSeller;