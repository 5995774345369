import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ThunkMiddleware from 'redux-thunk';
import RootReducer from './Reducers/RootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import "moment/locale/ru"
import './Styles/font/stylesheet.css'

const store = createStore(RootReducer, composeWithDevTools(applyMiddleware(ThunkMiddleware)));

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
  );
  

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
