import React, {useState} from 'react'
import s from './Modals.module.sass'
import {appAPI} from '../../../API/API'

const CreateOperator = ({setState, close}) => {

    const [formData, setFormData] = useState({
        username: '',
        login: '',
        password: '', 
    })

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if(formData.username && formData.login && formData.password) {
            await appAPI.createOperator({...formData, email: 'yaroslav@gmail.com'})
                .then(response => {
                    setState(prev => [...prev, response.data])
                    close();
                })
                .catch(err => console.log(err))
        } else alert('Введены не все данные')
    }

    return(
        <div className={s.modalAdmin}>
            <form className={s.formAdmin} onSubmit={submitHandler}>
                <label className = {`${s.label}`}>
                    <p>ФИО</p>
                    <input 
                        type="text"
                        name = 'username'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Логин</p>
                    <input 
                        type="text"
                        name = 'login'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Пароль</p>
                    <input 
                        type="password"
                        name = 'password'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <button type = 'submit' className={`button btn_red`}>Создать</button>
            </form>
        </div>
    )
}

export default CreateOperator;
