import React, {useState} from 'react';
import {appAPI} from '../../API/API'
import InputMask from 'react-input-mask';
import {useDispatch, useSelector} from 'react-redux'
import {changeSellerStatus, delOrder} from '../../Actions/OrderActions'

const Confirmation = ({orderId, type, close, shippingCode}) => {

    const [code, setCode] = useState('')
    const {token} = useSelector((state) => state.User)
    const dispatch = useDispatch()

    const submitHandler = async (e) => {
        e.preventDefault()
        if(type !== 'delivery' && type !== 'seller') {
            try{
                await appAPI.sellerToUserConfirmation(orderId, token, code.split(' ').join(''))
                dispatch(delOrder(orderId));
                close()
            } catch (err) {}
        } else if(type === 'seller'){
            close()
        } else {
            dispatch(changeSellerStatus(orderId, 'SHIPPED'))
            close()
        }
    }


    return(
        <form className="modal_confirmation" onSubmit={submitHandler}>
            <img src="/img/closeRed.svg" className="conf-close" onClick = {() => close()} alt="img"/>
            {type === 'seller' ? <h1>Подтверждение возврата</h1> : <h1>Подтверждение отрузки</h1>}
            <p className="order">Заказ № {orderId}</p>
            {type === 'delivery' || type === 'seller'
                ?     
                    <InputMask 
                        className="order-mask"
                        type = 'text'
                        name = 'code' 
                        alwaysShowMask = 'true'
                        mask = {shippingCode.length === 4 ? "* * * *" : "* * * * * *"}
                        maskChar = '_'
                        value = {shippingCode}
                        readOnly
                    />
                :   <InputMask 
                        className="order-mask"
                        type = {'tel'}
                        name = 'code' 
                        alwaysShowMask = 'true'
                        mask = "* * * *"
                        maskChar = '_'
                        onChange={(e) => setCode(e.target.value) }
                     />
            } 
            <button className = 'button btn_red btn_modal' type = 'submit'>Готово</button>
        </form>
    )

}

export default Confirmation;