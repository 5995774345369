import axios from 'axios';
import Cookies from 'js-cookie';

export const apiURL = 'https://api.ikra.market';

const instance = axios.create({
  baseUrl: apiURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

instance.interceptors.response.use(
  response => response
  // res => {
  //   Cookies.remove('tokenStaff');
  //   localStorage.removeItem('userRole')
  //   window.location.reload();
  // }
  ,
  async error => {
    if (error.response.status === 401 || error.response.status === 403) {
      Cookies.remove('tokenStaff');
      localStorage.removeItem('userRole')
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export const setTokenForAPI = (token) => {
    instance.defaults.headers[`Authorization`] = 'Bearer ' + token;
};

export const appAPI = {
    loginUser(login, password, role) {
      return instance.post(apiURL + `/auth/login/${role}`, {login, password }).then(response => response.data.data);
    },
    getProfile(token) {
      return instance.get(apiURL + `/auth/profile`, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    getOrders(token) {
      return instance.get(apiURL + `/orders`, {params: {token}}).then(response => response.data)
    },
    rejectedOrder(id){
      return instance.post(apiURL + `/orders/${id}/order-rejected`).then(response => response.data)
    },
    filterOrders(dateFrom, dateTo, deliveryStatus) {
      return instance.post(apiURL + `/orders/filter`, {dateFrom, dateTo, deliveryStatus}).then(response => response.data)
    },
    scanOrders(arr, id) {
      return instance.post(apiURL + `/orders/${id}/scanned`, {...arr}).then(response => response.data)
    },
    postponementOrder(id, token) {
      return instance.post(apiURL + `/orders/${id}/postponement`, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    getRequests(token) {
      return instance.get(apiURL + `/appeals`, {params: {token}}).then(response => response.data)
    },
    patchRequest(id, active) {
      return instance.patch(apiURL + `/appeals/${id}`, {active}).then(response => response.data);
    },
    postRequest(category, author, text, token){
      return axios.post(apiURL + '/appeals', {category, author, text}, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    putProduct(orderId, productId, amount, token) {
      return instance.put(apiURL + `/orders/${orderId}/product/${productId}`, {amount}, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    delProduct(orderId, productId, token) {
      return instance.delete(apiURL + `/orders/${orderId}/product/${productId}`, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    courierOrderAccept(id, token) {
      return instance.post(apiURL + `/orders/${id}/courier-accept`,  {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    sellerToCourierConfirmartion(id, token, shippingCode) {
      return instance.post(apiURL + `/orders/${id}/shipping/seller-to-courier/confirmation`, {shippingCode}, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    sellerToCourier(id, token) {
      return instance.post(apiURL + `/orders/${id}/shipping/seller-to-courier` , {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    sellerAccept(id, token) {
      return instance.post(apiURL + `/orders/${id}/seller-accept`, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    sellerToUser(id, token) {
      return instance.post(apiURL + `/orders/${id}/shipping/seller-to-user`, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    sellerToUserConfirmation(id, token, shippingCode) {
      return instance.post(apiURL + `/orders/${id}/shipping/seller-to-user/confirmation`, {shippingCode}, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    courierActiveOrders(token) {
      return instance.get(apiURL + `/orders/courier-active`, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    courierPersonalOrders(token) {
      return instance.get(apiURL + `/orders/courier-pool`, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    userToCourier(id, token) {
      return instance.post(apiURL + `/orders/${id}/shipping/user-to-courier`, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    userToCourierConfirmation(id, token, shippingCode) {
      return instance.post(apiURL + `/orders/${id}/shipping/user-to-courier/confirmation`, {shippingCode} ,{headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    courierToSeller(id, token) {
      return instance.post(apiURL + `/orders/${id}/shipping/courier-to-seller`, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    courierToSellerConfirmation(id, token, shippingCode) {
      return instance.post(apiURL + `/orders/${id}/shipping/courier-to-seller/confirmation`, {shippingCode}, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    courierToUser(id, token) {
      return instance.post(apiURL + `/orders/${id}/shipping/courier-to-user`, {headers: {'Authorization': 'Bearer ' + token}}).then(response => response.data)
    },
    courierToUserConfirmation(id, token, shippingCode) {
      return instance.post(apiURL + `/orders/${id}/shipping/courier-to-user/confirmation`, {shippingCode}, {headers: {'Authorization': 'Bearer ' + token}})
      .then(response => response.data)
      
    },
    postRemoveNotScanned(id) {
      return instance.post(apiURL + `/orders/${id}/remove-not-scanned`).then(response => response.data)
    },
    patchRemoveNotScanned(id, arr) {
      return instance.patch(apiURL + `/orders/${id}/remove-not-scanned`, [...arr]).then(response => response.data)
    },
    postScanned(id, arr) {
      return instance.post(apiURL + `/orders/${id}/scanned`, {arr}).then(response => response.data)
    },
    adminCreateOperator(login, password) {
      return instance.post(apiURL + `/admin/create-operator`, {login, password}).then(response => response.data)
    },
    adminCreateSeller(login, password) {
      return instance.post(apiURL + `/admin/create-seller`, {login, password}).then(response => response.data)
    },
    adminCreateCourier(formData) {
      return instance.post(apiURL + `/admin/create-courier`, {...formData}).then(response => response.data)
    },
    setProductDay(productId) {
      return instance.post(apiURL + `/admin/product-day`, {productId}).then(response => response.data)
    },
    getOrdersHistory() {
      return instance.get(apiURL + `/admin/orders/history`).then(response => response.data)
    },
    getCouriers(token) {
      return instance.get(apiURL + `/couriers`, {headers: {'Authorization': 'Bearer ' + token}})
      
    },
    courierActive(id, isActive) {
      return instance.put(apiURL + `/couriers/${id}`, {isActive}).then(response => response.data)
    },
    createCourier(object) {
      return instance.post(apiURL + `/admin/create-courier`, {...object}).then(response => response.data)
    },
    editCourier(object, id) {
      return instance.put(apiURL + `/couriers/${id}`, {...object}).then(response => response.data).catch(err => console.log(err));
    },
    delCourier(id) {
      return instance.delete(apiURL + `/couriers/${id}`).then(response => response.data)
    },
    getOperators(token) {
      return instance.get(apiURL + `/operators`, {headers: {'Authorization': 'Bearer ' + token}})
      .then(response => response.data)
      
    },
    createOperator(object) {
      return instance.post(apiURL + `/admin/create-operator`, {...object}).then(response => response.data)
    },
    editOperators(object, id) {
      return instance.put(apiURL + `/operators/${id}`, {...object}).then(response => response.data)
    },
    delOperators(id) { 
      return instance.delete(apiURL + `/operators/${id}`).then(response => response.data)
    }, 
    getUsers(token) {
      return instance.get(apiURL + `/users`, {headers: {'Authorization': 'Bearer ' + token}})
      .then(response => response.data)
      
    },
    editUsers(object, id) {
      return instance.put(apiURL + `/users/${id}`, {...object}).then(response => response.data)
    },
    delUsers(id) {
      return instance.delete(apiURL + `/users/${id}`).then(response => response.data)
    },
    delSeller(id) {
      return instance.delete(apiURL + `/admin/seller/${id}`).then(response => response.data)
    },
    getVacancies() {
      return instance.get(apiURL + `/vacancies`).then(response => response.data)
    },
    createVacancies(object) {
      return instance.post(apiURL + `/vacancies`, {...object}).then(response => response.data)
    },
    editVacancies(object, id) {
      return instance.put(apiURL + `/vacancies/${id}`, {...object}).then(response => response.data)
    },
    delVacancies(id){
      return instance.delete(apiURL + `/vacancies/${id}`).then(response => response.data)
    },
    getRecepies() {
      return instance.get(apiURL + `/recipes?limit=100&offset=0`).then(response => response.data)
    },
    createRecepies(object, token) {
      return instance.post(apiURL + `/recipes`, {...object}, {headers: {'Authorization': 'Bearer ' + token}})
      .then(response => response.data)
      
    },
    async editRecepie(data, id){
      const response = await instance.put(apiURL + `/recipes/${id}`, { ...data });
      return response.data;
    },
    createRecepiePhoto(data, id, token) {
      return instance.post(apiURL + `/recipes/${id}/photo`, data, {headers: {'Authorization': 'Bearer ' + token}})
      .then(response => response.data)
      
    },
    delRecepies(id){
      return instance.delete(apiURL + `/recipes/${id}`).then(response => response.data)
    },
    createRecepiesStep(data, id) {
      return instance.post(apiURL + `/recipes/${id}/step`, data).then(response => response.data)
    },
    editRecepiesStep(data, id) {
      return instance.put(apiURL + `/recipes/step/${id}`, data).then(response => response.data)
    },
    delRecepiesStep(id) {
      return instance.delete(apiURL + `/recipes/step/${id}`)
    },
    createRecepiesIngredients(array, id) {
      return instance.post(apiURL + `/recipes/${id}/ingredients`, array).then(response => response.data)
    },
    editRecepiesIngredient(data, id) {
      return instance.put(apiURL + `/recipes/ingredients/${id}`, [...data]).then(response => response.data)
    },
    delRecepiesIngredient(id) {
      return instance.delete(apiURL + `/recipes/ingredient/${id}`)
    },
    editRecepieIngredients(arr, id) {
      return instance.put(apiURL + `/recipes/${id}/ingredients`, [...arr])
    },
    createRecepiesProducts(array, id) {
      return instance.post(apiURL + `/recipes/${id}/products`, {productsIds: array}).then(response => response.data)
    },
    productsSearch(searchBy) {
      return instance.post(apiURL + `/products/searchBy`, {searchBy}).then(response => response.data)
    },
    getNewProducts() {
      return instance.get(apiURL + `/products/new`).then(response => response.data)
    },
    getReccomendationProducts() {
      return instance.get(apiURL + `/products/recommend`).then(response => response.data)
    },
    getSellers(token) {
      return instance.get(apiURL + `/seller`, {headers: {'Authorization': 'Bearer ' + token}})
      .then(response => response.data)
      
    },
    createSeller(object) {
      return instance.post(apiURL + `/admin/create-seller`, {...object}).then(response => response => response)
    },
    editSeller(object, id) {
      return instance.put(apiURL + `/seller/${id}`, {...object}).then(response => response.data)
    },
    getShops(postsPerPage, page) {
      return instance.get(apiURL + `/shops`, { params: { postsPerPage: postsPerPage, page: page } }).then(response => response.data)
    },
    getSlides() {
      return instance.get(apiURL + `/slider`).then(response => response.data)
    },
    createSlide(data) {
      return instance.post(apiURL + `/slider`, data).then(response => response.data)
    },
    editSlide(data, id) {
      return instance.put(apiURL + `/slider/${id}`, data).then(response => response.data)
    },
    delSlide(id) {
      return instance.delete(apiURL + `/slider/${id}`).then(response => response.data)
    },
    getProducts() {
      return instance.get(apiURL + `/products`).then(response => response.data)
    },
    getProductGroups() {
      return instance.get(apiURL + `/product-groups`).then(response => response.data)
    },
    getCategoryByGroup(id) {
      return instance.get(apiURL + `/product-groups/${id}/categories`).then(response => response.data)
    },
    getCatalogByGroup(id) {
      return instance.get(apiURL + `/product-groups/${id}/catalog`).then(response => response.data)
    },
    getGroupsWithCategories() {
      return instance.get(apiURL + `/product-groups/simple-categories`).then(response => response.data)
    },
    createCategory(object) {
      return instance.post(apiURL + `/categories`, {...object}).then(response => response.data)
    },
    editCategory(object, id) {
      return instance.put(apiURL + `/categories/${id}`, {...object}).then(response => response.data)
    },
    delCategory(id) {
      return instance.delete(apiURL + `/categories/${id}`).then(response => response.data)
    },
    getSubcategoriesByCategories(id){
      return instance.get(apiURL + `/categories/${id}`).then(response => response.data)
    },
    createSubcategory(object) {
      return instance.post(apiURL + `/subcategories`, {...object}).then(response => response.data)
    },
    editSubcategory(object, id) {
      return instance.put(apiURL + `/subcategories/${id}`, {...object}).then(response => response.data)
    },
    delSubcategory(id) {
      return instance.delete(apiURL + `/subcategories/${id}`).then(response => response.data)
    },
    getDeliverySettings() {
      return instance.get(apiURL + `/settings`).then(response => response.data)
    },
    editDeliverySettings(object) {
      return instance.post(apiURL + `/settings`, {...object}).then(response => response.data)
    },
    searchNotReccomend(name) {
      return instance.post(apiURL + `/products/searchBy/not-recommend`, {name}).then(response => response.data)
    }, 
    searchNotNew(name) {
      return instance.post(apiURL + `/products/searchBy/not-new`, {name}).then(response => response.data)
    },
    putProductNew(id, active) {
      return instance.put(apiURL + `/products/${id}`, {isNew: active}).then(response => response.data)
    },
    putProductReccomend(id, active) {
      return instance.put(apiURL + `/products/${id}`, {isRecommended: active}).then(response => response.data)
    },
    delProductPhoto(id) {
      return instance.delete(apiURL + `/products/photo/${id}`).then(response => response.data)
    },
    createProductPhotos(id, data) {
      return instance.post(apiURL + `/products/${id}/photo`, data).then(response => response.data)
    },
    editProduct(id, data) {
      return instance.put(apiURL + `/products/${id}`, {...data}).then(response => response.data)
    }, 
    createCategories(id, arr) {
      return instance.post(apiURL + `/products/${id}/categories`, {categoriesIds: arr}).then(response => response.data)
    },
    editShopPhoto(id, file) {
      return instance.post(apiURL + `/shops/${id}/photo`, file).then(response => response.data)
    },
    editShop(id, data) {
      return instance.put(apiURL + `/shops/${id}`, {...data}).then(response => response.data)
    },
    delShopPhoto(shopId, photoId) {
      return instance.delete(apiURL + `/shops/${shopId}/photo/${photoId}`).then(response => response.data)
    },
    getShopsWithoutSellers() {
      return instance.get(apiURL + `/shops/no-seller`).then(response => response.data)
    },
    getMe(token) {
      return instance.get(apiURL + `/users/me`, {headers: {'Authorization': 'Bearer ' + token}})
    }
}