import React from 'react';
import Table from '../Users/Table/Table';
import {appAPI} from '../../../API/API'
import TableItemVacancies from '../Users/Table/TableItems/TableItemVacancies';
import CreateVacancies from '../Modals/CreateVacancies';

const Vacancies = () => {

    const headers = ['Заголовок', 'Телефон', 'Адрес', 'ЗП', 'Редактировать', 'Удалить']
    const [vacancies, setVacancies] = React.useState([])
    const [forceUpdate, setForceUpdate] = React.useState(0)
    
    React.useEffect(() => {
        const fetchData = async () => {
            await appAPI.getVacancies()
                .then(response => setVacancies(response.data))
                .catch(error => console.error(error))
        }
        fetchData();
    }, [forceUpdate])
    
    return(
        <div >
            <Table
                title = 'Вакансии'
                tableItems = {vacancies}
                headers = {headers}
                Component = {TableItemVacancies}
                showPlus = {true}
                ComponentCreate = {CreateVacancies}
                setState = {setVacancies}
                setForceUpdate = {setForceUpdate}
            />
        </div>
    )   
}

export default Vacancies;