import React from 'react'
import s from './CustomSearch.module.sass'
import styleM from '../../Modals/Modals.module.sass'
import {appAPI, apiURL} from '../../../../API/API'
import AnimateHeight from 'react-animate-height';

const CustomSearch = ({setState, id, showDefault, row, setValidationFlag}) => {

    const [products, setProducts] = React.useState([])
    const [activeProduct, setActiveProduct] = React.useState(row.name ? row : null)
    const [height, setHeight] = React.useState(0)

    const searchBy = async (name) => {
        if(name.length > 0) {
            await appAPI.productsSearch(name)
            .then(response => {
                setProducts(response.data.filter(product => product.groups && product.groups.length > 0))
                setHeight('auto')
            })
            .catch(err => console.log(err))
        } else setProducts([])
    }

    React.useEffect(() => {
        products.length > 0 ? setHeight('auto') : setHeight(0)
    }, [products])

    const clickItem = (product) => {
        setActiveProduct({composition: product.composition, img: product.images[0].url, identifier: product.identifier, weight: product.weight, unit: product.unit})
        setState(prev => prev.map(item => item.id === id ? {...item, productId: product.id, composition: product.composition} : item))
        setValidationFlag(true)
    }
    
    return(
        <div className = {s.customSearch}>
            {!activeProduct 
            ?
                <>
                    <input 
                        type = 'text' 
                        className = {styleM.input}
                        onChange = {(e) => searchBy(e.currentTarget.value)}
                        style = {{width: '100%'}}
                    />

                    <AnimateHeight
                        duration={ 300 }
                        height={ height }
                    >
                        <div className = {s.resultBlock}>
                            <ul>
                                {products.map(product =>
                                    <li onClick = {() => clickItem(product)} key = {product.id}>
                                        <img src={`${apiURL}${product.images[0].url}`} alt="img"/>
                                        <p>{product.composition}</p>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </AnimateHeight>
                </>
            :
                <div className = {`${styleM.input} ${s.input}`}>
                    <img src={row.name ? `${apiURL}${activeProduct.images[0].url}` : `${apiURL}${activeProduct.img}`} alt="img"/>
                    <p>
                        <span>{activeProduct.composition}</span>
                        <span>Артикул {activeProduct.identifier} {activeProduct.unit === 'кг' ? `|| ${activeProduct.weight} ${activeProduct.unit}`: ''}</span>
                    </p>
                </div>
            }
        </div>
    )   
}

export default CustomSearch;