import React, {useState} from 'react';
import './Modal.sass'
import InputMask from 'react-input-mask';
import {setOrders} from '../../Actions/OrderActions'
import {appAPI} from '../../API/API'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'

const ChangeOrder = ({close}) => {

    const dispatch = useDispatch()
    const {initialOrders} = useSelector(state => state.Order)

    const [formData, setFormData] = useState({
        numOrderFrom: '',
        numOrderTo: '',
        dateOrderFrom: '',
        dateOrderTo: '',
        deliveryWay: 'DELIVERY',
        status: []
    })

    const submitHandler = async (e) => {
        e.preventDefault()
        if(formData.dateOrderFrom || formData.dateOrderTo || formData.status.length) {

            await appAPI.filterOrders(
                moment(formData.dateOrderFrom, "DD-MM-YYYY").toISOString(), 
                moment(formData.dateOrderTo, "DD-MM-YYYY").toISOString(),
                formData.status)

            .then((response) => {
                dispatch(setOrders(response.data))
                close()
            })            
            .catch(err => console.log(err))
        }

    }

    const changeHandler = e => {
        const {value, name} = e.currentTarget;
        setFormData( formData => ({
            ...formData,
            [name]: value
        }))
    }

    const changeHandlerStatus = e => {
        const {name} = e.currentTarget;
        setFormData( formData => ({
            ...formData,
            status: [...formData.status, `${name.toUpperCase()}`]
        }))
    }

    const clickHandlerDelivery = (value) => {
        setFormData( formData => ({
            ...formData,
            deliveryWay: value
        }))
    }


    return(
        <form className="modal_changeOrder" onSubmit={submitHandler}>
            <img src="/img/closeRed.svg" className="modal-close" alt="close" onClick = {() => close()}/>
            <div className="modal-block">
                <h3>Номер заказа</h3>
                <div className="modal-block__fields">
                    <input 
                        type="text"
                        name = 'numOrderFrom'
                        placeholder="Номер заказа от"
                        onChange={changeHandler}
                        className = 'form-field'
                    />
                    <input 
                        type="text" 
                        name = 'numOrderTo'
                        placeholder="Номер заказа до"
                        onChange={changeHandler}
                        className = 'form-field'
                    />
                </div>
            </div>

            <div className="modal-block">
                <h3>Дата</h3>
                <div className="modal-block__fields">
                    <InputMask                        
                        mask='99.99.9999'
                        name = 'dateOrderFrom'
                        onChange={changeHandler}
                        className = 'form-field'
                        placeholder='00.00.0000'
                    />
                    <InputMask
                        mask='99.99.9999'
                        name = 'dateOrderTo'
                        onChange={changeHandler}
                        className = 'form-field'
                        placeholder='00.00.0000'
                    />
                </div>
            </div>

            <div className="modal-block">
                <h3>Способ доставки</h3>
                <div className="modal-block__delivery">
                    <p 
                        className={`${formData.deliveryWay === 'DELIVERY' ? 'active' : ''}`} 
                        onClick = { () => clickHandlerDelivery('DELIVERY')}>Доставка</p>
                    <p 
                        className={`${formData.deliveryWay === 'PICKUP' ? 'active' : ''}`} 
                        onClick = { () => clickHandlerDelivery('PICKUP')}>Самовывоз</p>
                </div>
            </div>
            <div className="modal-block">
                <h3>Статус</h3>
                <div className="modal-block__status">
                    <label>
                        <input 
                            type="checkbox"
                            name = 'receiving'
                            onChange={changeHandlerStatus}
                        />
                        <span>Получение</span>
                    </label>
                    <label>
                        <input 
                            type="checkbox"
                            name = 'returning'
                            onChange={changeHandlerStatus}
                        />
                        <span>Возврат</span>
                    </label>
                    <label>
                        <input 
                            type="checkbox"
                            name = 'delivering'
                            onChange={changeHandlerStatus}
                        />
                        <span>Доставка заказа</span>
                    </label>
                </div>
            </div>
            <div className="buttons">
                <button type = 'submit' className="button btn_red">Применить</button>
                <button className = 'button btn_grey' onClick = {() => {
                    dispatch(setOrders(initialOrders)); 
                    close();
                }}>Сбросить</button>
            </div>
        </form>
    )
}

export default ChangeOrder;