import React from 'react'
import s from './Groups.module.sass'
import sDelivery from '../Delivery/Delivery.module.sass'
import Group from './Group/Group';
import {appAPI} from '../../../API/API'

const Groups = () => {

    const [groups, setGroups] = React.useState([])

    React.useEffect(() => {
        const fetchData = async () => {
            await appAPI.getProductGroups()
                .then(response => setGroups(response.data))
                .catch(err => console.error(err))
        }
        fetchData();
    }, [])


    return(
        <div className = {s.categories}>
            <h2 className = {sDelivery.header}>Категории</h2>
            <div className = {sDelivery.content}>
                {
                    groups
                        .sort((prev, next) => prev.localId < next.localId ? -1 : 1)
                        .map(group => <Group title = {group.name} id = {group.id} key = {group.id}/>)
                }
                
            </div>
        </div>
    )
}

export default Groups;