import React, {useState, useEffect} from 'react';
import './Requests.sass'
import AnimateHeight from 'react-animate-height';

const Request = ({request, id, arhRequest, activeAccordion, setActiveAccordion}) => {

    const [height, setHeight] = useState(0)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => activeAccordion === id ? setHeight('auto') : setHeight(0), [activeAccordion] )

    return(
        <div className="request">
            <div className="request__title" onClick = { () => {
                setHeight((height) => height === 0 ? 'auto' : 0);
                activeAccordion === id ? setActiveAccordion(null) : setActiveAccordion(id)
                }}>
                <p>Обращение № {id + 1}</p>
                <p>{request.category}</p>
            </div>
            <AnimateHeight
                id='content-hidden'
                duration={ 200 }
                height={ height } // see props documentation below
            >
                <div className="request__content">
                    <ul>
                        <li>
                            <span>Имя получателя</span>
                            <span>{request.author}</span>
                        </li>
                        <li>
                            <span>Обращение</span>
                        </li>
                    </ul>
                    <p>{request.text}</p>
                    {request.active && <button className="button btn_red" onClick={ () => arhRequest(id)}>Решено</button>}
                </div>
            </AnimateHeight>
        </div>
    )
}

export default Request;
