import React, {useCallback, useState} from 'react'
import TableItemShop from '../Users/Table/TableItems/TableItemShop';
import { appAPI } from '../../../API/API';
import Table from '../Users/Table/Table'

const Shops = () => {

    const headers = ['', 'Адрес', 'Статус ПВЗ', 'Редактировать']
    const [shops, setShops] = React.useState([])
    const [forceUpdate, setForceUpdate] = React.useState(false)
    const [page, setPage] = useState(1)
    const [stoped, SetStoped] = useState(false)

    const perPage = 100;
    
    // eslint-disable-next-line
    const fetchData = useCallback(async () => {
        if (!stoped) {
            await appAPI.getShops(perPage, page)
                .then(response => {
                    setShops(prev => prev.concat(response.data))
                    console.log(page, response.meta.totalPage)
                    if (page < response.meta.totalPage)
                        setPage(page + 1)
                    else 
                        SetStoped(true);
                })
                .catch(error => console.error(error))
        }
    })

    React.useEffect(() => {
        
        fetchData();
        SetStoped(true);
    }, [fetchData, forceUpdate])

    // const fetchData = async () => {
    //     await appAPI.getShops(perPage, page)
    //         .then(response => {
    //             setShops(prev => prev.concat(response.data))
    //         })
    //         .catch(error => console.error(error))
    // }

    // React.useEffect(() => {
        // const fetchData = async () => {
        //     await appAPI.getShops(perPage, page)
        //         .then(response => {
        //             setShops(prev => prev.concat(response.data))
        //             console.log(page + 1, response.meta.totalPage);
        //             if (response.meta.totalPage <= (page + 1)) {
        //                 SetStoped(true)
        //             }

        //         })
        //         .catch(error => console.error(error))
        // }
    //     fetchData();
    // }, [page])

    // const scrollRef = useRef(null);

    // const handleScroll = (e) => {
    //     console.log(scrollRef.current.scrollTop);
    //     if (!stoped && scrollRef.current.scrollTop > page * 50) {
    //         setPage(page + 1);
    //     }
    // }
    
    return(
        <div>
            <Table
                fetchData={fetchData}
                title = 'Магазины'
                tableItems = {shops}
                headers = {headers}
                Component = {TableItemShop}
                showPlus = {false}
                setState = {setShops}
                setForceUpdate = {setForceUpdate}
                hasMore={!stoped}
            />
        </div>
    )   
}

export default Shops;