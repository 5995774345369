import { combineReducers } from 'redux';
import { UserReducer } from './UserReducer';
import {OrderReducer} from './OrderReducer';
import {AdminReducer} from './AdminReducer'
import ModalReducer from "./ModalReducer";

export default combineReducers({
  User: UserReducer,
  Order: OrderReducer,
  Modal: ModalReducer,
  Admin: AdminReducer
});
