import React from 'react'
import s from '../Table.module.sass'
import {useDispatch} from 'react-redux'
import {openModalAction} from '../../../../../Actions/ModalActions'
import EditOperator from '../../../Modals/EditOperator';
import CustomRadioButton from '../../../Common/CustomRadioButton';
import {appAPI} from '../../../../../API/API'

const TableItemOperator = ({item, setState, setForceUpdate}) => {

    const dispatch = useDispatch();


    const delOperator = async (id) => {
        await appAPI.delOperators(id)
            .then(() => setState(prev => prev.filter(operator => operator.id !== id)))
            .catch(err => alert(err.response.data.message))
    }

    const changeOperatorPermissions = async () => {
        await appAPI.editOperators({changeOrderPermission: !item.changeOrderPermission}, item.id)
            .then(respose => {
                setForceUpdate(prev => prev + 1)
            })
    }

    return(
        <tr className="table-item">
            <td>{item.username}</td>
            <td><CustomRadioButton active = {item.changeOrderPermission} func = {changeOperatorPermissions}/></td>
            <td>
                <img className = {s.imgScale} onClick = {() => dispatch(openModalAction(EditOperator, {username: item.username, login: item.login, password: item.password, id: item.id, setState: setState}))} src="/img/adminRed.svg" alt="img"/>
            </td>
            <td onClick = { () => delOperator(item.id)}>
                <img className = {s.imgScale} src="/img/adminDel.svg" alt="img"/>
            </td>
        </tr>
    )
}

export default TableItemOperator;