import React, {useState} from 'react'
import s from './Modals.module.sass'
import InputMask from 'react-input-mask';
import {appAPI} from '../../../API/API'

const EditUser = ({username, email, phone, id, close, setState}) => {

    const [formData, setFormData] = useState({
        username: username,
        email: email,
        phone: phone
    })

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if(username !== formData.username || email !== formData.email || phone !== formData.phone) {
            await appAPI.editUsers(formData, id)
                .then(() => {
                    setState(prev => prev.map(user => user.id === id ? {...user, username: formData.username, phone: formData.phone, email: formData.email} : user))
                    close();
                })
                .catch(err => alert(err.response.data.message))
        } else alert('Все поля совпадают с предыдущим значением')
    }

    return(
        <div className={s.modalAdmin}>
            <form className={s.formAdmin} onSubmit={submitHandler}>
                <label className = {`${s.label}`}>
                    <p>ФИО</p>
                    <input 
                        type="text"
                        name = 'username'
                        defaultValue = {username}
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label  className = {`${s.label}`}>
                    <p>Email</p>
                    <input 
                        type="email"
                        name = 'email'
                        defaultValue = {email}
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Телефон</p>
                    <InputMask
                        mask = '+7 999 999 99 99'
                        name = 'phone'
                        defaultValue = {phone.slice(1)}
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <button type = 'submit' className={`button ${username !== formData.username || email !== formData.email || phone !== formData.phone ? s.btn_red : ''}`}>Сохранить</button>
            </form>
        </div>
    )
}

export default EditUser;
