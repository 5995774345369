import React, {useState} from 'react';
import './Seller.sass'
import SellerProduct from './SellerProduct';
import {useDispatch, useSelector} from 'react-redux'
import {openModalAction} from "../../Actions/ModalActions";
import SellerScan from "./SellerScan";
import Confirmation from '../Modals/Confirmation'
import {appAPI} from '../../API/API'
import moment from 'moment'

const SellerItem = ({order, forceUpdate, finished}) => {

    const dispatch = useDispatch();
    const {token} = useSelector((state) => state.User)
    const [activeOrder, setActiveOrder] = useState((order.courier && order.courier.courierType === 'SUPER') ? true : false)
    const [scanReady, setScanReady] = useState(order.sellerStatus !== 'PENDING' && order.sellerStatus !== 'PROCESSING')
    
    const setStatus = () => {
        switch (order.sellerStatus) {
            case 'PENDING':
            case 'PROCESSING':
                return 'В обработке'
            case 'POSTPONED':
                return 'Отложен'
            case 'READY':
                return 'Готов к получению'
            case 'SHIPPED':
                return 'Отгружен'
            default:
                return ''
        }
    }

    const clickDelivery = async () => {
        try{
            const response = await appAPI.sellerToCourier(order.id, token)
            dispatch(openModalAction(Confirmation, {orderId: order.id, type: 'delivery', shippingCode: response.data.shippingCode}))
        } catch(err) {}
        
    }

    const clickVivoz = async () => {
        try{
            await appAPI.sellerToUser(order.id, token)
            dispatch(openModalAction(Confirmation, {orderId: order.id, type: 'vivoz'}))
        } catch(err) {}
    }

    const clickOrderConfirmation = async () => {
        try{
            const response = await appAPI.courierToSeller(order.id, token)
            dispatch(openModalAction(Confirmation, {orderId: order.id, type: 'seller', shippingCode: response.data.shippingCode}))
        } catch(err) {}
    }

    return(
        <>
            <div className={`seller-item ${activeOrder ? 'active' : ''} ${finished ? 'finished' : ''}`}>
                {/* {finished &&
                    <div className={`seller-item__blur`}></div>
                } */}
                <h4 style = {{marginBottom: '10px'}}>{moment(order.orderDate).format('DD.MM.YYYY')}</h4>
                <div className="seller-item-header">
                    <h2>Заказ № {order.id}</h2>
                    <h2>{order.orderReceiving === 'PICKUP' ? 'Самовывоз' : 'Доставка'}</h2>
                    <h2>Статус: {setStatus()}</h2>
                </div>
                <div className="seller-item-products">
                    {order.products.map((product, i) =>
                        <SellerProduct 
                            amount = {product.amount} 
                            key = {product.id} 
                            product = {product.product}
                            scanReady = {scanReady}
                        />
                    )}
    
                </div>
                <div className="seller-item-footer">
                    <span/>
                    <p className="seller-item__itogo">Итого: {order.orderPrice.toFixed(2)} ₽</p>
                    {!finished &&
                    <>
                    {(order.sellerStatus === 'PENDING' || order.sellerStatus === 'PROCESSING')
                        ?  
                            <button 
                                className="button btn_red"
                                onClick={() => dispatch(openModalAction(SellerScan, {order: order, forceUpdate, setActiveOrder, setScanReady}))}
                            >Пробить товар </button>
                        :
                        order.sellerStatus === 'READY' 
                                ? (
                                    order.orderReceiving === 'DELIVERY' ? <button className="button btn_red" onClick = {clickDelivery}>Передать в доставку</button>
                                    : <button className="button btn_red" onClick = {clickVivoz}>Передать получателю</button>
                                )
                                : order.sellerStatus === 'SHIPPED' && <button className="button btn_red" onClick = {clickOrderConfirmation}>Подтвердить возврат</button>
                    }
                    </>
                    }
                </div>
            </div>
        </>
    )
}

export default SellerItem;