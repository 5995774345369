import React from 'react'
import './Courier.sass'
import {useDispatch, useSelector} from 'react-redux'
import {openModalAction} from '../../Actions/ModalActions'
import ConfirmationGet from './CourierModals/ConfirmationGet';
import ConfirmationGive from './CourierModals/ConfirmationGive';
import ConfirmationVozvrat from './CourierModals/ConfirmationVozvrat';
import Vozvrat from './CourierModals/Vozvrat'
import {appAPI, apiURL} from '../../API/API'
import { getPhone } from '../Admin/Common/OtherFunctions/OtherFunctions';

const CourierOrder = (props) => {

    const dispatch = useDispatch()
    const {order} = props; 
    const {token} = useSelector((state) => state.User)

    const switchStatus = () => {
        switch (order.deliveryStatus) {
            case 'RECEIVING':
                return 'Получение заказа'
            case 'DELIVERING':
                return 'Доставка заказа'
            case 'DELIVERED':
                return 'Доставлено'
            case 'RETURNING':
                return 'Возврат'
            case 'RETURNED':
                return 'Возвращено'
            default:
                break;
        }
    }

    const clickConfirmationUser = async () => {
        try{
            await appAPI.courierToUser(order.id, token)
            dispatch(openModalAction(ConfirmationGive, {orderId: order.id}))
        } catch (err) {}
    }


    const redirectYandexPVZ = (e) => {
        navigator.geolocation.getCurrentPosition(position => { 
            const lat =  position.coords.latitude;
            const long = position.coords.longitude;
            window.open(`https://yandex.ru/maps/?rtext=${lat}%2C${long}~${order.seller.shop.latitude}%2C${order.seller.shop.longitude}&rtt=auto&ruri=~&z=11`)
        }, error => {
            console.log(error)
        })        
    }

    const redirectYandexPersonal= (e) => {
        navigator.geolocation.getCurrentPosition(position => { 
            const lat =  position.coords.latitude;
            const long = position.coords.longitude;
            window.open(`https://yandex.ru/maps/?rtext=${lat}%2C${long}~${order.latitude}%2C${order.longitude}&rtt=auto&ruri=~&z=11`)
        }, error => {
            console.log(error)
        })        
    }

        

    return(
        <div className="order">

            <div className="order__titles">
                <h2>Заказ №{order.id}</h2>
                <h2>Статус: {switchStatus()}</h2>
            </div>

            <div className="order__block">
                <h3>Адрес ПВЗ</h3>
                <p>{order.seller.shop.city}</p>
            </div>

            <div className="order__block">
                <h3>Телефон ПВЗ</h3>
                <p>{getPhone(order.seller?.shop.shopPhone)}</p>
            </div>

            <button className="button btn_blue" onClick = {redirectYandexPVZ}>Маршрут до ПВЗ</button>

            <div className="order__block">
                <h3>Адрес получателя</h3>
                <p>{`г. ${order.initialOrder.city}, ${order.initialOrder.street}, ${order.initialOrder.building || order.initialOrder.home}`}</p>
            </div>

            <div className="order__block">
                <h3>Телефон получателя</h3>
                <p>{getPhone(order.phone)}</p>
            </div>

            <div className="order__block">
                <h3>Комментарий</h3>
                <p>{order.comment}</p>
            </div>

            <button className="button btn_blue" onClick = {redirectYandexPersonal}>Маршрут до получателя</button>

            <div className="cart">
                {order.products.map(product => 
                    <div className="cart-item" key = {product.id}>
                        <img className="cart-item__img" src = {`${apiURL}${product.product.images[0].url}`} alt = 'img'/>
                        <div className="cart-item__info">
                            <p className="cart-item__title">{product.product.name}</p>
                            <p className="cart-item__articul">Артикул {product.product.identifier} | {product.product.weight} кг</p>
                            <p className="cart-item__count">{product.product.unit === "кг" ? (product.amount * product.product.weight) + " кг" : product.amount + " шт."}</p>
                        </div>
                    </div>
                )}
                
                {order.deliveryStatus === 'RECEIVING' 
                    ? <button className="button btn_red" onClick = {() => dispatch(openModalAction(ConfirmationGet, {orderId: order.id}))}>Подтвердить получение</button>
                    : order.deliveryStatus === 'DELIVERING' ? <button className="button btn_red" onClick = {clickConfirmationUser}>Подтвердить отгрузку</button> : null
                }
                {order.deliveryStatus !== 'RETURNING' && order.deliveryStatus !== 'RECEIVING'
                    ? <button className="button btn_red" onClick = {() => dispatch(openModalAction(Vozvrat, {orderId: order.id}))}>Возврат</button>
                    : <button className="button btn_red" onClick = {() => dispatch(openModalAction(ConfirmationVozvrat, {orderId: order.id, type: 'seller'}))}>Подтверждение возврата</button>
                }
                
            </div>
        </div>

    )
}

export default CourierOrder;