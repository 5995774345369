import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CheckAuth } from './Actions/UserActions';
import "./App.sass";
import './Reset.sass'
import AuthForm from "./Components/Forms/AuthForm";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Seller from "./pages/Seller/Seller";
import BaseModal from "./Components/Modals/BaseModal";
import Operator from './pages/Operator/Operator';
import Courier from './pages/Courier/Courier';
import Admin from './pages/Admin/Admin';



function App() {

  const dispatch = useDispatch();
  const {isAuth, checkAuth} = useSelector((state) => state.User);
  const role = localStorage.getItem('userRole');


  const switchUserRole = () => { 
    switch (role) {
      case 'seller':
        return (
            <Seller/>
        )
      case 'courier': return <Courier/>
      case 'admin': return <Admin/>
      case 'operator':
        return <Operator showInfo = {true}/>
      default:
        break;

    }
  }
  
  useEffect(() => {
    dispatch(CheckAuth())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !checkAuth ? null : (
    <div className={`container ${role === 'admin' ? 'container_admin' : ''}`}>
      <Router>
        <Route path = '/'>
        { isAuth
          ? (
            switchUserRole()
          ) : <AuthForm/> }
        </Route>
      </Router>
      <BaseModal />
    </div>
  )
}

export default App;
