import React, {useState, useEffect, useRef} from 'react';
import './Requests.sass'
import Request from './Request'
import {appAPI} from '../../../API/API'
import {useSelector, useDispatch} from 'react-redux'
import {setRequests, archRequest} from '../../../Actions/OrderActions'


const Requests = () => {

    const dispatch = useDispatch();
    const arrFilter = [null, true, false]
    const {token} = useSelector(state => state.User)
    const [filterActive, setFilterActive] = useState(0)
    const [activeAccordion, setActiveAccordion] = useState(null)
    const inputSearch = useRef(null)
    const [cancelFilter, setCancelFilter] = useState(false)
    const {requests, initialRequests} = useSelector((state) => state.Order)

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            try{
                const response = await appAPI.getRequests(token)
                if(isMounted) dispatch(setRequests(response.data, response.data))
            } catch (err) {
            }
        }
        fetchData()
        return () => isMounted = false;
    }, [dispatch, token])

    
    const filtrarion = (arr, filter) => {
        if(filter === null) { return arr } else return arr.slice().filter(el => el.active === filter)
    }

    const arhRequest = async (id) => {
        try{
            await appAPI.patchRequest(id, false)
            dispatch(archRequest(id))
        } catch (err) {}
        
    }

    const onSearch = (value) => { 
        value && setCancelFilter(true)
        dispatch(setRequests(initialRequests.slice().filter(order => order.id.includes(value))))
    }

    return(
        <div className="operator-requestes">
            <div className="operator-requestes__header">
                <div className="operator-requestes__header-search">
                    <input 
                            ref = {inputSearch}
                            type="text"
                            placeholder="Поиск обращения..."
                            onKeyUp = { e => e.keyCode === 13 && onSearch(e.currentTarget.value)}
                        />
                    {cancelFilter 
                        ? <img 
                            src = '/img/closeRed.svg' 
                            alt ="img" 
                            className='img-search img-search_close'
                            onClick = { () => {
                                dispatch(setRequests(initialRequests));
                                inputSearch.current.value = ''
                                setCancelFilter(false);
                            }}/>
                        : <img 
                            src="/img/search.svg" 
                            className = 'img-search' 
                            alt="img" 
                            onClick = { () => onSearch(inputSearch.current.value)}
                        />
                        }
                </div>
                <ul className="operator-requestes__header-filter">
                    {arrFilter.map((filter, i) => 
                        <li 
                            className = {` button ${filterActive === i ? 'btn_blue' : 'btn_grey'}`} 
                            onClick = { () => setFilterActive(i)} 
                            key = {i}>
                            {i === 0 
                                ? 'Все'
                                : i === 1 
                                    ? 'Активные'
                                    : 'Архивированные'
                            }
                        </li>
                    )}
                </ul>
            </div>
            <div className="operator-requests__requests">
                {filtrarion(requests, arrFilter[filterActive]).sort((prev, next) => prev.createdAt < next.createdAt ? 1 : -1).map(request =>
                        <Request 
                        request={request}
                        id = {request.id} 
                        arhRequest = {arhRequest}
                        activeAccordion = {activeAccordion}
                        setActiveAccordion = {setActiveAccordion}
                        key = {request.id} 
                /> )}
            </div>
        </div>
    )
}

export default Requests;