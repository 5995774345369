// const ORDER_ID = 'ORDER_ID';
const ORDER_STATUS = 'ORDER_STATUS';
const SET_ORDER = 'SET_ORDER';
const SET_ORDERS = 'SET_ORDERS'
const DEL_ORDER = 'DEL_ORDER'
const DEL_PRODUCT = 'DEL_PRODUCT'
const RETURN_PRODUCTS = 'RETURN_PRODUCTS'
const DEL_RETURNED_PRODUCTS = 'DEL_RETURNED_PRODUCTS'
const CHANGE_ORDER_PRODUCTS = 'CHANGE_ORDER_PRODUCTS'
const SET_REQUESTS = 'SET_REQUESTS'
const ARCH_REQUEST = 'ARCH_REQUEST'
const DEL_ORDER_SELLER = 'DEL_ORDER_SELLER'
const SET_ORDER_FETCHED = 'SET_ORDER_FETCHED'
const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS'
const CHANGE_SELLER_STATUS = 'CHANGE_SELLER_STATUS'
const RESET_ORDERS = 'RESET_ORDERS'
const ACCEPT_ORDER = 'ACCEPT_ORDER'

const setOrderStatus = (orderStatus, orderID) => {
    return {
        type: ORDER_STATUS,
        orderStatus,
        orderID
    }
}

const acceptOrder = (id) => {
    return {
        type: ACCEPT_ORDER,
        id
    }
}

const setOrder = (order) => {
    return {
        type: SET_ORDER,
        order
    }
}

const setOrders = (orders, initialOrders) => {
    return {
        type: SET_ORDERS,
        orders,
        initialOrders
    }
}

const delOrder = (id) => {
    return {
        type: DEL_ORDER,
        id
    }
}

const delProduct = (orderId, productId, product) => {
    return {
        type: DEL_PRODUCT,
        orderId, 
        productId,
        product
    }
}

const returnProducts = (products, orderId) => {
    return {
        type: RETURN_PRODUCTS,
        products,
        orderId
    }
}

const delReturnedProducts = () => {
    return {
        type: DEL_RETURNED_PRODUCTS
    }
}

const setRequests = (requests, initialRequests) => {
    return {
        type: SET_REQUESTS,
        requests,
        initialRequests
    }
}

const archRequest = (id) => {
    return {
        type: ARCH_REQUEST,
        id,
    }
}

const delOrderSeller = (id) => {
    return {
        type: DEL_ORDER_SELLER,
        id
    }
}

const setOrderFetched = () => {
    return {
        type: SET_ORDER_FETCHED
    }
}

const changeOrderStatus = (orderId, status) => {
    return {
        type: CHANGE_ORDER_STATUS,
        orderId,
        status
    }
}

const changeSellerStatus = (orderId, status) => {
    
    return {
        type: CHANGE_SELLER_STATUS,
        orderId,
        status
    }
}

const changeOrderProducts = (orderId, products) => {
    return {
        type: CHANGE_ORDER_PRODUCTS,
        orderId,
        products
    }
}

const resetOrders = async (dispatch) => {
    await dispatch({
      type: RESET_ORDERS,
    });
  };


export 
{
    ORDER_STATUS, 
    SET_ORDER, 
    SET_ORDERS, 
    DEL_ORDER, 
    DEL_PRODUCT, 
    RETURN_PRODUCTS, 
    DEL_RETURNED_PRODUCTS, 
    SET_REQUESTS, 
    ARCH_REQUEST, 
    DEL_ORDER_SELLER, 
    SET_ORDER_FETCHED,
    CHANGE_ORDER_STATUS,
    CHANGE_SELLER_STATUS,
    CHANGE_ORDER_PRODUCTS,
    RESET_ORDERS,
    ACCEPT_ORDER
}
export 
{
    setOrderStatus, 
    setOrder, 
    setOrders, 
    delOrder, 
    delProduct, 
    returnProducts,
    delReturnedProducts, 
    setRequests, 
    archRequest, 
    delOrderSeller, 
    setOrderFetched,
    changeOrderStatus,
    changeSellerStatus,
    changeOrderProducts,
    resetOrders,
    acceptOrder
}