import React, {useState} from 'react'
import s from './Modals.module.sass'
import {useSelector} from 'react-redux'
import {appAPI} from '../../../API/API'
import CustomFormRowRecepie from '../Common/CustomFormRowRecepie/CustomFormRowRecepie';
import RowIngredients from '../Common/CustomFormRowRecepie/Rows/RowIngredients'
import RowSteps from '../Common/CustomFormRowRecepie/Rows/RowSteps';
import RowProducts from '../Common/CustomFormRowRecepie/Rows/RowProducts';

const CreateRecepie = ({setState, close, setForceUpdate}) => {

    const {token} = useSelector(state => state.User)
    const formRef = React.useRef(null)
    
    const inputFile = React.useRef(null)

    const [validationFlag, setValidationFlag] = useState(false)

    const [formData, setFormData] = useState({
        img: null,
        cookingTime: '',
        portions: undefined,
        video: '',
        name: '',
        category: 'fish'
    })

    const [ingredients, setIngredients] = useState([{
        amount: null,
        name: '',
        unit: '',
        id: Date.now()
    }])

    const [steps, setSteps] = useState([{
        description: '',
        img: '',
        id: Date.now()
    }])

    const [products, setProducts] = useState([{
        composition: '',
        id: '',
        productId: ''
    }])
    
    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
        console.log(formData)
    }

    const submitHandler = async (e) => {
        e.preventDefault();

        if(formData.img && formData.cookingTime && formData.portions && formData.video && formData.name && steps[0].description && ingredients[0].name && products[0].composition) {
            const recepie = await appAPI.createRecepies({name: formData.name, cookingTime: formData.cookingTime, portions: parseInt(formData.portions), video: formData.video}, token)
            const data = new FormData();
            data.append('file', formData.img)
            await appAPI.createRecepiePhoto(data, recepie.data.id, token)
            await appAPI.createRecepiesIngredients(ingredients.map(ingredient => ({amount: ingredient.amount, name: ingredient.name, unit: ingredient.unit})), recepie.data.id)
            await Promise.all(steps.map((step, index) => new Promise((resolve, reject) => {
                const data = new FormData();
                data.append('description', step.description)
                data.append('file', step.img)
                data.append('localId', index)
                appAPI.createRecepiesStep(data, recepie.data.id)
                .then(() => resolve('ok'))
            })))
            await appAPI.createRecepiesProducts(products.map(product => product.productId), recepie.data.id)
            console.log(formData)
            setForceUpdate(prev => prev + 1)
            close();

        } else alert('Присутствуют пустые поля')
    }

    const changeHandlerFile = () => {
        if (inputFile.current.files && inputFile.current.files[0]) setFormData(prev => ({...prev, img: inputFile.current.files[0]}))
    }

    return(
        <div className={`${s.modalAdmin} ${s.modalWide}`}>
            <form className={`${s.formAdmin} ${s.formWide}`} onSubmit={submitHandler} ref = {formRef}>
                <label className = {`${s.label}`}>
                    <p>Название рецепта</p>
                    <input 
                        type="text"
                        name = 'name'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className={`${s.label}`}>
                    <p>Категория</p>
                    <select
                        className={s.input}
                        name='category'
                        onChange={changeHandler}
                    >
                        <option value={'fish'}>Рыба</option>
                        <option value={'seafood'}>Морепродукты</option>
                        <option value={'caviar'}>Икра</option>
                    </select>
                </label>
                <div className = {`${s.labelImg} ${s.label}`}>
                    <p>Главное изображение</p>
                    <label>
                        <p>
                            <img src="/img/screpka.svg" alt="img"/>
                            <span>Загрузите фото</span>
                        </p>
                        <input type="file" ref = {inputFile} style = {{display: 'none'}} multiple accept="image/*" onChange = {changeHandlerFile}/>
                    </label>
                    {formData.img 
                        ? <img alt="" src = {URL.createObjectURL(formData.img)} className = {s.imgWide}/> 
                        : null
                    }
                </div>
                <div className = {s.formBlock}>
                    <label className = {`${s.label} ${s.labelSmall}`}>
                        <p>Время приготовления:</p>
                        <input 
                            type="text"
                            name = 'cookingTime'
                            onChange={changeHandler}
                            className = {s.input}
                        />
                    </label>
                    <label className = {`${s.label} ${s.labelSmall}`}>
                        <p>Количество порций:</p>
                        <input 
                            type="number"
                            name = 'portions'
                            onChange={changeHandler}
                            className = {s.input}
                        />
                    </label>
                </div>
                <label className = {`${s.label}`}>
                    <p>Ссылка на видео</p>
                    <input 
                        type="text"
                        name = 'video'
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <CustomFormRowRecepie 
                    title = 'Ингридиенты:' 
                    state = {ingredients}
                    setState = {setIngredients}
                    setValidationFlag={setValidationFlag}
                    Component = {RowIngredients}
                />
                <CustomFormRowRecepie 
                    title = 'Рецепт:' 
                    state = {steps}
                    setState = {setSteps}
                    setValidationFlag={setValidationFlag}
                    Component = {RowSteps}
                />
                <CustomFormRowRecepie
                    title = 'Продукты из этого рецепта:'
                    state = {products}
                    setState = {setProducts}
                    setValidationFlag={setValidationFlag}
                    Component = {RowProducts}
                />
                <button type = 'submit' className={`button btn_red`}>Создать</button>
            </form>
        </div>
    )
}

export default CreateRecepie;
