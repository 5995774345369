import React from 'react'
import s from '../Table.module.sass'
import {useDispatch} from 'react-redux'
import {openModalAction} from '../../../../../Actions/ModalActions'
import {appAPI, apiURL} from '../../../../../API/API'
import EditRecepie from '../../../Modals/EditRecepie'

const TableItemRecepies = ({item, setState, setForceUpdate}) => {
    
    const dispatch = useDispatch();

    const delRecepies= async (id) => {
        await appAPI.delRecepies(id)
            .then(() => setState(prev => prev.filter(vacancies => vacancies.id !== id)))
            .catch(err => alert(err.response.data.message))
    }

    return(
        <tr className="table-item">
            <td className = {s.imgTable}><img alt="" src = {`${apiURL}${item.image}`}/></td>
            <td>{item.name}</td>
            <td>
                <img className = {s.imgScale} src="/img/adminRed.svg" alt="img" onClick = { () => {dispatch(openModalAction(EditRecepie, {item, setForceUpdate}))}}/>
            </td>
            <td>
                <img className = {s.imgScale} src="/img/adminDel.svg" alt="img" onClick = {() => delRecepies(item.id)}/>
            </td>
        </tr>
    )
}

export default TableItemRecepies;