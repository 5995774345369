import React, {useCallback, useEffect, useState} from 'react';
import './Pages.sass'
import {useSelector, useDispatch} from 'react-redux'
import SellerItem from '../../Components/Seller/SellerItem';
import Request from '../../Components/Modals/Request'
import io from "socket.io-client"
import {openModalAction} from "../../Actions/ModalActions";
import {changeOrderStatus, delOrder, setOrder, setOrders} from '../../Actions/OrderActions'
import {appAPI, apiURL} from '../../API/API'
import {resetOrders} from '../../Actions/OrderActions'
import {logoutUser} from '../../Actions/UserActions'
import SellerStatistics from '../../Components/Seller/SellerStatistics';

const Seller = () => {

    const {token} = useSelector((state) => state.User);
    const baseUrl = apiURL;
    const [fakeState, updateState] = useState();
    const forceUpdate = useCallback(() => updateState(''), [])
    const dispatch = useDispatch();
    const {orders} = useSelector((state) => state.Order);
    const [showHint, setShowHint] = useState(false)
    let socket = io.connect(baseUrl + '/sellers', {
        query: {token}
    });
    socket.on("ADD_ORDER", (order) => { 
        
        if(orders && orders.length > 0 && !orders.find(item => item.id === order.id)){
            order.products.sort((prev, next) => 
                prev.product.groups[0].parentName === 'Заморозка' ? -1 : 1
            )
            setShowHint(true)
            setTimeout(() => {setShowHint(false)}, 7000)
            dispatch(setOrder(order))
        }
        // dispatch(openModalAction(
        //     NewOrder,
        //     {addOrder: order, onClickAddOrder: (order) => dispatch(setOrder(order))}
        // ))
    })

    socket.on("ORDER_DELIVERED", (order) => { 
        dispatch(delOrder(order.id));
    })
    socket.on("ORDER_REJECTED", (order) => { 
        dispatch(changeOrderStatus(order.id, order.deliveryStatus));
    })
    

    // socket.on("ORDER_REJECTED", (order) => {
    //     dispatch(delOrderSeller(order.id));
    // })

    useEffect(() => {
        return () => socket.disconnect();
    });
    

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await appAPI.getOrders(token)
                const orders = response.data
                
                orders.map(order => {
                    order.products.sort((prev, next) => 
                    prev.product.groups[0].parentName === 'Заморозка' ? -1 : 1
                    )
                    return order.products.map((item) => {
                        
                        if (item.id === "4d694294-ef99-4b61-9c3f-4ad7934c4328") {
                            console.log(item)
                            item.scanCount = item.amount
                        }
                        return item
                    })
                })
                
                dispatch(setOrders(orders))
            } catch (err) {
                dispatch(logoutUser)
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fakeState])


    // const NewOrder = ({addOrder, onClickAddOrder, close}) => (
    //     <div className="modal_newOrderFromShop">
    //         <h1>Поступил новый заказ из интернет-магазина</h1>
    //         <p>Заказ № {addOrder.id}</p>
    //         <button className = 'button btn_red btn_modal' onClick = {async () => {
    //             await appAPI.sellerAccept(addOrder.id, token)
    //             onClickAddOrder(addOrder);
    //             close();

    //         }}>Принять</button>
    //     </div>
    // )

    return(
        <div className='page'>
            <h1>Личный кабинет кассира №1343</h1>
            <div className="seller-items">
            
            { orders.sort(
                (prev, next) => 
                    prev.orderDate < next.orderDate ? 1 : -1
                ).sort(
                    (prev, next) => 
                    prev.sellerStatus === 'POSTPONED' ? -1 : 1
                ).sort(
                    (prev, next) => 
                    prev.deliveryStatus === 'RETURNED' || prev.orderStatus === 'FINISHED'  ? 1 : -1
                ).filter(
                    (item) => 
                    (item.deliveryStatus === 'RETURNED' || item.orderStatus === 'FINISHED') 
                    ? 
                    (new Date(item.acceptedDate).setHours(0,0,0,0) === new Date().setHours(0,0,0,0))
                    :
                    true
                ).map(
                    (order, i) => 
                    order.sellerStatus !== 'PENDING' // && order.orderStatus !== 'FINISHED'
                    &&
                    <>
                    {
                    order.deliveryStatus === 'RETURNED' || order.orderStatus === 'FINISHED' 
                    ?
                    <SellerItem key = {order.id} order = {order} forceUpdate = {forceUpdate} finished={true}/>
                    : 
                    <SellerItem key = {order.id} order = {order} forceUpdate = {forceUpdate} finished={false}/> 
                    }
                    </>
                )}
            </div>
            <button className="button btn_support" onClick = { () => 
                dispatch(openModalAction(
                Request,
                ))}
                >
                <img src="/img/btnSupport.svg" alt="support"/>
                <p>Поддержка</p>
            </button>
            <button className = 'button btn_logout' onClick = { () => { dispatch(resetOrders); dispatch(logoutUser);  }}>Выйти</button>
            <SellerStatistics />

            {showHint && 
                <>
                    <iframe title="12323" hidden src="/newOrder.mp3" allow="autoplay" type="audio/mpeg"/>
                    <p className = 'seller-hintOrder'>Поступил новый заказ из интернет - магазина!</p>
                </>
            }
        </div>
    )
}

export default Seller;