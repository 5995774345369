import React, {useState} from 'react'
import s from './Modals.module.sass'
import {appAPI} from '../../../API/API'

const EditOperator = ({username, login, password, id, setState, close}) => {

    const [formData, setFormData] = useState({
        username: username,
        login: login,
        password: ''
    })

    const changeHandler = (e) => {
        const {name, value} = e.currentTarget;
        setFormData(prev => ({...prev, [name]: value}));
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if(username !== formData.username || login !== formData.login || formData.password) {
            await appAPI.editOperators(formData, id)
                .then(() => {
                    setState(prev => prev.map(operator => operator.id === id ? {...operator, login: formData.login, username: formData.username} : operator));
                    close();
                })
                .catch(err => alert(err.response.data.message))
        } else alert('Все поля совпадают с предыдущим значением')
    }

    return(
        <div className={s.modalAdmin}>
            <form className={s.formAdmin} onSubmit={submitHandler}>
                <label className = {`${s.label}`}>
                    <p>ФИО</p>
                    <input 
                        type="text"
                        name = 'username'
                        defaultValue = {username}
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Логин</p>
                    <input 
                        type="text"
                        name = 'login'
                        defaultValue = {login}
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <label className = {`${s.label}`}>
                    <p>Пароль</p>
                    <input
                        type = 'password'
                        name = 'password'
                        defaultValue = {password}
                        onChange={changeHandler}
                        className = {s.input}
                    />
                </label>
                <button type = 'submit' className={`button ${username !== formData.username || login !== formData.login || formData.password ? 'btn_red' : ''}`}>Сохранить</button>
            </form>
        </div>
    )
}

export default EditOperator;
