import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {appAPI} from '../../../API/API'
import Table from './Table/Table';
import s from './Users.module.sass'
import TableItemOperator from './Table/TableItems/TableItemOperator'
import CreateOperator from '../Modals/CreateOperator';

const Operators = () => {

	const {token} = useSelector(state => state.User)
	const headers = ['ФИО', 'Возможность изменять заказы', 'Редактировать', 'Удалить']
	const [operators, setOperators] = useState([])
	const [forceUpdate, setForceUpdate] = useState(0)
	
	useEffect(() => {
		const fetchData = async () => {
			await appAPI.getOperators(token)
				.then(response => setOperators(response.data))
				.catch(err => {})
		}
		fetchData()
	}, [forceUpdate, token])

	return (
		<div className= {s.users}>
			<Table 
				title = 'Операторы' 
				tableItems = {operators.map(el => ({username: el.username, login: el.login, phone: el.phone, id: el.id, changeOrderPermission: el.changeOrderPermission}))} 
				headers = {headers} 
				showPlus = {true}
				ComponentCreate = {CreateOperator}
				Component = {TableItemOperator}
				setState = {setOperators}
				setForceUpdate = {setForceUpdate}
			/>
		</div>
	)
}

export default Operators;