import React, {useState} from 'react'
import s from './Category.module.sass'
import Subcategory from '../Subcategory/Subcategory';
import { appAPI } from '../../../../API/API';
import { listenerEsc } from './CategoryFunctions';

const Category = React.memo(({title, id, setCategories, editCategories, delCategories, active, setCreateCategory, groupId}) => {

    const [activeInput, setActiveInput] = useState(active)
    const [subcategories, setSubcategories] = useState([])
    const [createSubcategory, setCreateSubcategory] = useState(0)

    const inputRef = React.useRef(null)


    React.useEffect(() => {
        if(!active) {
            const fetchData = async () => {
                await appAPI.getSubcategoriesByCategories(id)
                    .then(response => setSubcategories(response.data.subcategories))
                    .catch(err => console.error(err))
            }
            fetchData();
        }
    }, [active, createSubcategory, id])

    const listenerSuccessCategory = async (id) => {
        if(inputRef.current.value) {
            if(active) {
                await appAPI.createCategory({title: inputRef.current.value, groupId, localId: Math.floor(Math.random() * 1000)})
                    .then(response => {
                        setCreateCategory(prev => prev + 1)
                        setActiveInput(false);
                    })
                    .catch(err => console.error(err))
            } else {
                await appAPI.editCategory({title: inputRef.current.value, groupId}, id)
                    .then(response => {
                        setCreateCategory(prev => prev + 1)
                        setActiveInput(false);
                    })
                    .catch(err => console.error(err))
            }
        } else {
            delCategories(id, setCategories)
            setActiveInput(false);
        }    
    }

    const deleteCategory = async (id) => {
        await appAPI.delCategory(id)
            .then(response => delCategories(id, setCategories))
            .catch(err => console.error(err))
    }

    React.useEffect(() => listenerEsc(delCategories, id, active, setCategories, setActiveInput), [active, delCategories, id, setCategories]);

    return(
        <div>
            <div className = {s.category} >
                {
                    activeInput
                        ?   <input 
                                type = 'text'  
                                ref = {inputRef} 
                                className = {s.input} 
                                autoFocus = {true}
                                onKeyDown = {(e) => e.keyCode === 13 ? listenerSuccessCategory(id) : null}
                                defaultValue = {title && title}
                                />
                        :   <p>{title}</p>
                }

                <div>
                    {
                        activeInput
                            ? <img src = '/img/adminSuccess.svg' alt = 'img' onClick = {() => listenerSuccessCategory(id)}/>
                            : <img src="/img/adminRed.svg" alt="img" onClick = {() => setActiveInput(true)}/>
                    }
                    <img src="/img/adminDel.svg" alt="img" onClick = {() => deleteCategory(id)}/>
                    <img src="/img/adminPlus.svg" alt="img" onClick = {() => setSubcategories(prev => [...prev, {id: Date.now, title: '', active: true}])}/>
                </div>
            </div>

            <div className = {s.subcategory}>
                {
                    subcategories.map(subcategory =>
                        <Subcategory 
                            title = {subcategory.name} 
                            id = {subcategory.id} 
                            key = {subcategory.id}
                            editCategories = {editCategories}
                            delCategories = {delCategories}
                            setSubcategories = {setSubcategories}
                            setCreateSubcategory = {setCreateSubcategory}
                            categoryId = {id}
                            active = {subcategory.active}
                        />)
                }
            </div>
        </div>
    )
});

export default Category;