import React from 'react'
import {useDispatch} from 'react-redux'
import {openModalAction} from '../../../../../Actions/ModalActions'
import s from '../Table.module.sass'
import EditUser from '../../../Modals/EditUser';
import {appAPI} from '../../../../../API/API'
import {getPhone} from '../../../Common/OtherFunctions/OtherFunctions'

const TableItemUser = ({item, setState}) => {

    const dispatch = useDispatch();

    const delUser = async (id) => {
        await appAPI.delUsers(id)
            .then(() => setState(prev => prev.filter(user => user.id !== id)))
            .catch(err => alert(err.response.data.message))
    }

    return(
        <tr className="table-item">
            <td>{item.username}</td>
            <td>{getPhone(item.phone)}</td>
            <td>{item.email}</td>
            <td onClick = { () => dispatch(openModalAction(EditUser, {username: item.username, phone: item.phone, email: item.email, id: item.id, setState: setState}))}>
                <img className = {s.imgScale} src="/img/adminRed.svg" alt="img"/>
            </td>
            <td onClick = { () => delUser(item.id)}>
                <img className = {s.imgScale} src="/img/adminDel.svg" alt="img"/>
            </td>
        </tr>
    )
}

export default TableItemUser;