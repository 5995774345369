import React from 'react';
import Table from '../Users/Table/Table';
import {appAPI} from '../../../API/API'
import TableItemSlider from '../Users/Table/TableItems/TableItemSlider';
import CreateSlider from '../Modals/CreateSlider';

const styles = {
    width: '100%',
}

const Slider = () => {

    const headers = ['', 'Текст', 'Текст кнопки', 'Ссылка кнопки', 'Редактировать', 'Удалить']
    const [slides, setSlides] = React.useState([])
    const [forceUpdate, setForceUpdate] = React.useState(0)

    React.useEffect(() => {
        const fetchData = async () => {
            await appAPI.getSlides()
                .then(response => setSlides(response.data))
                .catch(error => console.error(error))
        }
        fetchData();
    }, [forceUpdate])

    return(
        <div style={styles}>
            <Table
                title = 'Слайды'
                tableItems = {slides}
                headers = {headers}
                Component = {TableItemSlider}
                showPlus = {true}
                setState = {setSlides}
                ComponentCreate = {CreateSlider}
                setForceUpdate = {setForceUpdate}
            />
        </div>
    )   
}

export default Slider;