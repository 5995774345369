import React from 'react'
import s from '../CustomFormRowRecepie.module.sass'
import CustomSearch from '../../CustomSearch/CustomSearch';

const RowProducts = React.memo(({img, setState, id, showDefault, row, setValidationFlag}) => {

    // const changeHandler = (e) => {
    //     const {name, value} = e.target;
    //     setState(prev => prev.map(ingredient => ingredient.id === id ? {...ingredient, [name]: value } : ingredient))
    // }

    return(
        <div className = {`${s.customRowProducts} ${s.customRow}`}>
            <CustomSearch row = {row} setState = {setState} id = {id} showDefault = {showDefault} setValidationFlag = {setValidationFlag}/>
            {img === 'delete' 
                ? <img className = {s.img} src="/img/adminDel.svg" alt="img" onClick = { () => {setState(prev => prev.filter(item => item.id !== id)); setValidationFlag(true)}}/>
                : <img className = {s.img} src="/img/adminPlus.svg" alt="img" onClick = {() => {setState(prev => [...prev, {amount: null, name: '', unit: '', id: Date.now()}]); setValidationFlag(true)}}/>
            }
        </div>
    )
});

export default RowProducts;