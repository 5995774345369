import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {appAPI} from '../../../API/API'
import {changeOrderStatus, delOrderSeller} from '../../../Actions/OrderActions'
import InputMask from 'react-input-mask';
import AnimateHeight from 'react-animate-height'
import './Modals.sass'

const ConfirmationVozvrat = ({close, orderId, type}) => {

    const [code, setCode] = useState('')
    const [height, setHeight] = useState(0)
    const {token} = useSelector((state) => state.User)
    const dispatch = useDispatch()

    const submitHandler = async (e) => {
        e.preventDefault()
        if(!code.includes('_') && code.length) {
            if(type === 'user') {

                try{
                    await appAPI.userToCourierConfirmation(orderId, token, code.split(' ').join(''))
                    dispatch(changeOrderStatus(orderId, 'RETURNING'))
                    // dispatch(delOrderSeller(orderId));
                    close()
                } catch(err) {
                    setHeight('auto')
                }
            } else {
                
                try{
                    await appAPI.courierToSellerConfirmation(orderId, token, code.split(' ').join(''))
                    dispatch(changeOrderStatus(orderId, 'RETURNED'))
                    dispatch(delOrderSeller(orderId))
                    close()
                } catch(err) {
                    setHeight('auto')
                }
            }
        }
    }

    return(
        <form className="container-modal container-modal_confirmation-get" onSubmit={submitHandler}>
            <img src="/img/closeRed.svg" alt="close" className="courier-modal-close" onClick={() => close()}/>
            <h2>Подтверждение возврата</h2>
            <p>Заказ № {orderId}</p>
            <InputMask 
                className="order-mask"
                type = {'text'}
                name = 'code' 
                alwaysShowMask = 'true'
                mask = "* * * *"
                maskChar = '_'
                onChange={(e) => setCode(e.target.value) }
              />
            <AnimateHeight
                height = {height}
                duration = {200}
                id='content-hidden'
            >
                <p className = 'hint-warning'>Введен неправильный код подтверждения!</p>
            </AnimateHeight>
            
            <button type = 'submit' className = {`button ${!code.includes('_')  && code.length ? 'btn_red' : 'btn_grey'}`}>Готово</button>
        </form>
    )
}

export default ConfirmationVozvrat;