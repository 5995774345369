import React from 'react'
import s from './CustomGallary.module.sass'
import {apiURL} from '../../../../API/API'

const CustomGallary = React.memo(({state, setState, title, stateDelete, setStateDelete}) => {

    const changeHandler = (e) => {
        const input = e.currentTarget;
        setState(prev => [...prev, {url: input.files[0], id: Date.now()}])
    }

    const deleteImage = (id) => {
        setStateDelete(prev => [...prev, id])
        setState(prev => prev.filter(item => item.id !== id))
    }

    return(
        <div className = {s.customGallary}>
            <p className = {s.title}>{title}</p>

            <label className = {s.label}>
                <p>
                    <img src="/img/screpka.svg" alt="img"/>
                    <span>Загрузите фото</span>
                </p>
                <input type="file" style = {{display: 'none'}} onChange={changeHandler}/>
            </label>
            
            <ul className = {s.list}>
                {state.map(item => 
                    <li key = {item.id}>
                        <img alt="" className = {s.listImg} src = {typeof item.url !== 'object' ?  `${apiURL}${item.url}` : URL.createObjectURL(item.url)}/>
                        <img alt="" className = {s.closeImg} src="/img/closeImg.svg" onClick = {() => deleteImage(item.id)}/>
                    </li>
                )}
            </ul>
        </div>  
    )
});

export default CustomGallary;