import React from 'react';

const OrderStatuses = ({status}) => {

    const arr = [
        {
            id: 0,
            title: 'Получение заказа',
            state: '',
            name: 'RECEIVING'
        },
        {
            id: 1,
            title: 'Доставка заказа',
            state: '',
            name: 'DELIVERING',
        },
        {
            id: 2,
            title: 'Доставлено',
            state: '',
            name: 'DELIVERED'
        },
        {
            id: 3,
            title: 'Возврат',
            state: '',
            name: 'RETURNING'
        },
        {
            id: 4,
            title: 'Возвращено',
            state: '',
            name: 'RETURNED'
        }
    ]

    const showStatus = () => {
        let flag = false;    
        for(let item of arr) {
            item.state = 'Prev'
            if(item.name === status) {
                item.state = 'Current';
                flag = true;
                continue;
            }
            if(flag) item.state = 'Next';
        }
    }
    
    showStatus()

    return (
        <>
            {arr.map((status) => 
                <li className= {`right_${status.state}`} key= {status.id}>
                    <img src={`/img/status${status.state}.svg`} alt="img"/>
                    <p>{status.title}</p>
                </li>
            )}
        </>
    )
}

export default OrderStatuses;